import React, { useEffect, useReducer } from 'react';
import ReactMapboxGl, { MapContext, ZoomControl } from 'react-mapbox-gl';
import MapboxGL from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import csv2geojson from 'csv2geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import turfBbox from '@turf/bbox';
import { compileRichText } from '@utils';
import {
  MapWrapper, SheetMapWrapper, CaptionTitle, Caption, TabButton, TabWrapper,
} from './map.styles';

// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
MapboxGL.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const initialState = {
  locations: '',
  locationsGeo: {},
  isLoading: true,
  selectedTab: '',
  tabs: [],
};

function reducer(state, action) {
  return ({
    ...state,
    ...action,
  });
}

const SheetMap = (props) => {
  const {
    googleSheet, id, title, description, popupTitleField,
    popupDescriptionFields, textTheme, tabbableMapField,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    locations, locationsGeo, isLoading, selectedTab, tabs,
  } = state;
  const layerId = `${id}-csvData`;
  const accessToken = 'pk.eyJ1Ijoid3V0cmFpbiIsImEiOiJja3NnYjVxNXkxaTcxMm9wNHpoN3p5ZXd0In0.d-kkuJuGJedKT6zLEaXLZA';

  useEffect(() => {
    if (googleSheet) {
      if (locations.length === 0) {
        fetch(googleSheet)
          .then((res) => res.text())
          .then((d) => {
            dispatch({
              locations: d,
              isLoading: true,
            });
          })
          .catch((error) => {
            console.log(error);
            dispatch({
              errorState: true,
              isLoading: false,
            });
          });
      }
    }
  }, [googleSheet, locations]);

  useEffect(() => {
    if (locations.length > 0 && !locationsGeo.features) {
      csv2geojson.csv2geojson(locations, {
        latfield: 'Latitude',
        lonfield: 'Longitude',
        delimiter: ',',
      }, (err, data) => {
        let uniqueTabs = [];
        let selected = '';
        if (data.features && tabbableMapField) {
          uniqueTabs = [...new Set(data.features.map((item) => item.properties[tabbableMapField]))];
        }
        if (uniqueTabs[0]) {
          selected = uniqueTabs[0];
        }
        dispatch({
          locationsGeo: data,
          isLoading: false,
          tabs: uniqueTabs,
          selectedTab: selected,
        });
      });
    }
  }, [locations, locationsGeo, tabbableMapField]);

  if (!googleSheet || !accessToken) {
    return null;
  }

  const Map = ReactMapboxGl({
    accessToken,
  });

  return (
    <MapWrapper>
      {tabs && (
        <TabWrapper>
          {tabs.map((tab) => (
            <li key={tab}>
              <TabButton
                selected={tab === selectedTab}
                onClick={() => dispatch({ selectedTab: tab })}
              >
                {tab}
              </TabButton>
            </li>
          ))}
        </TabWrapper>
      )}
      <SheetMapWrapper isLoading={isLoading} textTheme="primary">
        {locationsGeo && locationsGeo.features && (
        <Map
        // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/light-v10"
          containerStyle={{
            height: '500px',
            width: '100%',
          }}
          center={[-71.057083, 42.361145]}
          zoom={[11]}
          className="wu-sheet-map"
        >
          <ZoomControl className="wu-zoom-control" />
          <MapContext.Consumer>
            {(map) => {
              map.addLayer({
                id: layerId,
                type: 'circle',
                source: {
                  type: 'geojson',
                  data: locationsGeo,
                },
                paint: {
                  'circle-radius': 8,
                  'circle-color': '#3E2C70',
                  'circle-stroke-color': '#EBE4FC',
                  'circle-stroke-width': 3,
                },
              });

              map.addControl(
                new MapboxGeocoder({
                  accessToken,
                  mapboxgl: MapboxGL,
                  zoom: 12,
                }),
                'top-left',
              );

              if (tabbableMapField && selectedTab) {
                map.setFilter(layerId, ['==', ['get', tabbableMapField], selectedTab]);
              }

              map.on('click', layerId, (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                const popupDescription = [];
                if (popupTitleField && e.features[0].properties[popupTitleField]) {
                  popupDescription.push(`<h3>${e.features[0].properties[popupTitleField]}</h3>`);
                }
                if (popupDescriptionFields) {
                  popupDescriptionFields.forEach((field) => {
                    if (e.features[0].properties[field]) {
                      if (e.features[0].properties[field].startsWith('http')) {
                        popupDescription.push(`<p><a href=${e.features[0].properties[field]}>${e.features[0].properties[field]}</a></p>`);
                      } else {
                        popupDescription.push(`<p>${e.features[0].properties[field]}</p>`);
                      }
                    }
                  });
                }

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                new MapboxGL.Popup({
                  closeOnClick: true, closeOnMove: false, focusAfterOpen: true, maxWidth: '310px',
                })
                  .setLngLat(coordinates)
                  .setHTML(popupDescription.join(''))
                  .addTo(map);
              });

              const bbox = turfBbox(locationsGeo);
              map.fitBounds(bbox, { padding: 50 });

              map.on('mouseenter', layerId, () => {
              // eslint-disable-next-line no-param-reassign
                map.getCanvas().style.cursor = 'pointer';
              });
              map.on('mouseleave', layerId, () => {
              // eslint-disable-next-line no-param-reassign
                map.getCanvas().style.cursor = '';
              });
            }}
          </MapContext.Consumer>
        </Map>
        )}
      </SheetMapWrapper>
      {(title || description) && (
        <Caption>
          {title && (
            <CaptionTitle textTheme={textTheme}>
              {title}
              {title && description && (
              <span> | </span>
              )}
            </CaptionTitle>
          )}
          {description && (
            compileRichText({
              textTheme,
              size: 'small',
            })(description)
          )}
        </Caption>
      )}
    </MapWrapper>
  );
};

export default SheetMap;
