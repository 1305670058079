import styled, { css } from 'styled-components';
import { slideIn, slideOut, createBreakpoint } from '@styles';

export const Bar = styled.nav`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.donateBar};
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  box-shadow: 0 0 10px 0 rgba(0,0,0,.4);
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  animation: ${({ closing }) => (closing ? slideOut : slideIn)};
  animation-duration: 0.45s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding: 16px 38px;
  `)}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.layout.extraWideMaxWidth}px;
  margin: auto;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: row;
  `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    line-height: 1.35;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    align-self: center;
    justify-self: center;
    width: 45%;
    margin-right: ${theme.layout.gutter}px;
  `)}
`;

export const DonateInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 55%;
  `)}
`;

export const DonateButtonsWrapper = styled.ul`
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2px auto 6px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    justify-content: space-between;
    margin: 0px;
    display: ${({ mobile }) => (!mobile ? 'flex' : 'none')};
  `)}
`;

export const DonateButton = styled.li`
  display: flex;
  margin-right: 6px;
  width: ${({ mobButtonCount }) => (mobButtonCount
    ? `calc(${100 / mobButtonCount}% - ${((mobButtonCount - 1) * 6) / mobButtonCount}px)`
    : 'calc(25% - 4.5px)')};
  max-width: ${({ mobButtonCount }) => (mobButtonCount
    ? `calc(${100 / mobButtonCount}% - ${((mobButtonCount - 1) * 6) / mobButtonCount}px)`
    : 'calc(25% - 4.5px)')};

  &:last-child {
    margin-right: 0px;
  }

  a {
    letter-spacing: 0.14em;
    font-size: 18px;
    &:hover {
      color: ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-right: 16px;
    width: calc(33% - 10px);
    max-width: calc(33% - 10px);

    a {
      letter-spacing: 0.18em;
      font-size: 16px;
    }

    &:first-child, &:nth-child(2), &:nth-child(3) {
      margin-bottom: 16px;
    }

    &:last-child, &:nth-child(3) {
      margin-right: 0px;
    }
  `)}
`;

export const Header = styled.h2`
  color: ${({ theme }) => theme.palette.white};
  margin: 0px 10px 10px 0px;
  font-size: 20px;
  line-height: 1.2;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 22px;
    line-height: 1.36;
  `)}
`;

export const Disclaimer = styled.p`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.palette.lighterPrimary};
  margin-bottom: 0px;
`;

export const CloseButton = styled.button`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 7px;
  right: 12px;
  cursor: pointer;
  padding: 0px;

  svg {
    width: 15px;
    height: 15px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    top: 10px;
    width: 20px;
    height: 20px;
  `)}
`;
