import { keyframes } from 'styled-components';

export const slideIn = keyframes`
  0% {
    transform: translate3d(0,100px,0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

export const slideOut = keyframes`
  0% {
    transform: translate3d(0,0,0);
  }
  100% {
    transform: translate3d(0,100px,0);
  }
`;
