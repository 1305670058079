import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';
import { lighten } from 'polished';

export const ECardTitle = styled.h3`
  font-size: 32px;
  line-height: 1;
  margin: 10px 0;
  color: inherit;
  transition: none;

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 40px;
  `)}
`;

export const ECardLink = styled.a`
  margin: 10px 0;
  display: block;
  font-size: 20px;

  &:before {
    content: "";
    position: absolute;
    inset: 0px;
  }
`;

export const ECardType = styled.span`
  color: ${({ theme }) => lighten(0.2, theme.palette.secondaryHighlight)};
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 18px;
  display: block;
  transition: none;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 20px;
  `)}
`;

export const ECardInfo = styled.span`
  color: ${({ theme }) => lighten(0.2, theme.palette.secondaryHighlight)};
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  border-right: 2px solid ${({ theme }) => lighten(0.2, theme.palette.secondaryHighlight)};
  margin-right: 5px;
  padding-right: 5px;
  padding-bottom: 1px;
  transition: none;

  &:last-of-type {
    border-right: 0px;
  }
`;

export const ECardDesc = styled.p`
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 400;
  transition: none;
`;

export const ECard = styled.li`
  white-space: normal;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.primary};
  font-weight: 700;
  border-top: 2px solid ${({ theme }) => theme.palette.secondaryHighlight};
  transition: background 0.3s ease-in-out;;
  line-height: 1;
  position: relative;
  padding: 12px 10px;
  margin-bottom: -2px;

  &:fist-of-type {
    border-top: 4px solid ${({ theme }) => theme.palette.secondaryHighlight};
  }

  &:last-of-type {
    border-bottom: 4px solid ${({ theme }) => theme.palette.secondaryHighlight};
  }

  svg {
    left: 7px;
    position: relative;
    transition: transform 0.3s ease-in-out;;
    polygon {
      transition: fill 0.3s;
    }
  }

  &:hover {
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, .2);
    color: ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => theme.palette.secondaryHighlight};

    svg {
      transform: translateX(40%);
      polygon {
        fill: ${({ theme }) => theme.palette.primary};
      }
    }

    ${ECardType} {
      color: ${({ theme }) => theme.palette.primary};
    }

    ${ECardInfo} {
      color: ${({ theme }) => theme.palette.primary};
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding: 20px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 30px;
  `)}
`;
