import React from 'react';
import styled, { css } from 'styled-components';
import { switchBasedOnScheme } from '@utils';
import { LIGHT, DARK, SECONDARY } from '@utils/vars';

const Container = styled.div`
  font-size: 1em;
  line-height: 1.65;
  color: #373F49;
  margin: 0;

  ${({ navTheme, theme }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
      color: ${theme.palette.white};
    `,
    [DARK]: css`
      color: ${theme.palette.primary};
    `,
    [SECONDARY]: css`
      color: ${theme.palette.secondary};
    `,
  })}
`;

export default ({ children, navTheme }) => (
  <Container navTheme={navTheme}>{children}</Container>
);
