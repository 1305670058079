import React, { useState, useEffect } from 'react';
import { Link } from '@components';
import { Close } from '@styles/icons';
import { compileRichText } from '@utils';
import { DISMISS_ALERT_BAR } from '@utils/vars';
import {
  Bar, Wrapper, CloseButton,
} from './alert-bar.styles';

const AlertBar = (props) => {
  const [closed, setClosed] = useState(false);
  const [closing, setClosing] = useState(false);
  const {
    alertBarText, alertBarLink, alertRef,
  } = props;

  const CloseAlert = () => {
    if (!closed) {
      setClosing(true);
      setTimeout(() => {
        setClosed(true);
        sessionStorage.setItem(DISMISS_ALERT_BAR, 'true');
      }, 400);
      setTimeout(() => {
        setClosing(false);
      }, 500);
    }
  };

  useEffect(() => {
    setClosed(!!sessionStorage.getItem(DISMISS_ALERT_BAR));
  }, []);

  return (
    <React.Fragment>
      {!closed ? (
        <Bar role="banner" ref={alertRef} closing={closing}>
          <Wrapper>
            {alertBarText && (
              compileRichText({ textTheme: 'primary', size: 'xsmall' })(alertBarText)
            )}
            <Link href={alertBarLink} />
            <CloseButton onClick={CloseAlert}>
              <Close width="20px" height="20px" strokeWidth="3" strokeColor="primary" />
            </CloseButton>
          </Wrapper>
        </Bar>
      ) : null}
    </React.Fragment>
  );
};

export default AlertBar;
