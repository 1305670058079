import React from 'react';
import { Link } from '@components';
import { DARK, SECONDARY, LIGHT } from '@utils/vars';
import { HomepageItem } from './navigation.styles';

const logoOptions = {
  zhCN: React.lazy(() => import('../../styles/icons/logo-chinese')),
  esMX: React.lazy(() => import('../../styles/icons/logo-spanish')),
  enUS: React.lazy(() => import('../../styles/icons/logo')),
  viVN: React.lazy(() => import('../../styles/icons/logo-vietnamese')),
  dummyContent: React.lazy(() => import('../../styles/icons/logo')),
  htHT: React.lazy(() => import('../../styles/icons/logo-haitian')),
};

const HomepageLink = ({
  homepage, isSticky, navTheme, locale, mobOpen, mobile,
}) => {
  const isSSR = typeof window === "undefined"
  const FinalLogo = logoOptions[locale] || logoOptions.enUS;

  return (
    <HomepageItem mobOpen={mobOpen} role="menuitem" isSticky={isSticky} mobile={mobile}>
      <Link title={homepage.title} href={homepage.path}>
        <span>{homepage.title}</span>
        {!isSSR && (
          <React.Suspense fallback={<span>...</span>}>
            <FinalLogo
              accentFill={((mobOpen || navTheme === DARK) && !isSticky) ? 'primaryHighlight' : (((mobOpen || navTheme === SECONDARY) && !isSticky) ? 'secondaryDarkHighlight' : (((mobOpen || navTheme === SECONDARY) && isSticky) ? 'secondaryHighlight' : 'medPrimary'))}
              textFill={((mobOpen || navTheme === DARK) && !isSticky) ? 'white' : (((mobOpen || navTheme === SECONDARY) && !isSticky) ? 'white' : 'primary')}
            />
          </React.Suspense>
        )}
      </Link>
    </HomepageItem>
  );
};

export default React.memo(HomepageLink);
