import React from 'react';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { MainLink, Link } from '@components';
import { Header1, Header2 } from '@styles';
import { VIDEO } from '@utils/vars';
import {
  ToolkitContentWrapper, ToolkitImages,
  Section, ContentWrapper, ToolkitImageWrapper,
} from '../homepage-sections.styles';

const ToolkitImage = ({
  description, fluid, externalLink, index,
}) => {
  const [trackingRef, isImgInView] = useInView({ threshold: 0.1, triggerOnce: true });

  return (
    <ToolkitImageWrapper
      ref={trackingRef}
      isImgInView={isImgInView}
      index={index}
    >
      <Link
        href={externalLink}
      >
        <Img
          className="toolkit-image"
          alt={description}
          fluid={fluid}
          imgStyle={{ objectFit: 'cover' }}
        />
      </Link>
    </ToolkitImageWrapper>

  );
};

const ToolkitSection = ({
  sectionTitle, sectionLink, sectionMedia, order, nextModuleType, externalLink,
}) => (
  <Section
    sectionTheme="white"
    nextVideo={nextModuleType === VIDEO}
    order={order}
  >
    <ContentWrapper tabletFlexDirection="row" maxWidth="wideMaxWidth">
      <ToolkitContentWrapper>
        {sectionTitle && (order !== 0 ? (
          <Header2 textTheme="primary" size="large">
            {sectionTitle}
          </Header2>
        ) : (
          <Header1 textTheme="primary">
            {sectionTitle}
          </Header1>
        ))}
        {externalLink && sectionLink && (
          <MainLink
            externalLink={externalLink}
            sectionLink={sectionLink}
            tablet
            desktop
          />
        )}
      </ToolkitContentWrapper>
      <ToolkitImages>
        {sectionMedia && sectionMedia.map((media, index) => (
          <ToolkitImage
            externalLink={externalLink}
            description={media.description}
            fluid={media.fluid}
            index={index}
            key={media.id}
          />
        ))}
      </ToolkitImages>
      {externalLink && sectionLink && (
        <MainLink
          externalLink={externalLink}
          sectionLink={sectionLink}
          mobile
        />
      )}
    </ContentWrapper>
  </Section>
);

export default ToolkitSection;
