import React from 'react';
import { Link } from '@components';
import LinkButton from './link-button.styles';

const PrimaryLinkButton = (props) => {
  const {
    children, href, size = 'medium', buttonTheme = 'highlight', buttonHoverTheme = 'highlight',
    textTheme = 'primary', textHoverTheme = 'secondaryHighlight', target,
  } = props;

  return (
    <LinkButton
      buttonTheme={buttonTheme}
      buttonHoverTheme={buttonHoverTheme}
      textTheme={textTheme}
      textHoverTheme={textHoverTheme}
      size={size}
    >
      <Link href={href} target={target}>
        {children}
      </Link>
    </LinkButton>
  );
};

export default PrimaryLinkButton;
