import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.lighterPrimary};
  padding: 0px 15px;
  margin-bottom: ${({ hasImg }) => (hasImg ? '140px' : '45px')};

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 20px;
    padding: 0px 32px ${({ hasImg }) => (hasImg ? '32px' : '0px')};
    margin-bottom: ${({ hasImg }) => (hasImg ? '60px' : '60px')};
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 0px 32px ${({ hasImg }) => (hasImg ? '80px' : '30px')};
  `)}
`;

export const Content = styled.div`
  max-width: ${({ theme, hasImg }) => (hasImg ? theme.layout.extraWideMaxWidth : theme.layout.contentMaxWidth)}px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  .hero-image {
    width: 100%;
    height: auto;
    top: 0px;
    margin-bottom: -90px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;

    .hero-image {
      width: 60%;
      height: auto;
      top: -60px;
      margin-bottom: -60px;
    }
  `)}
`;

export const TextContent = styled.div`
  width: 100%;
  padding: ${({ hasImg }) => (hasImg ? '40px 17px 0px' : '40px 17px')};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: ${({ hasImg }) => (hasImg ? '40%' : '65%')};
    margin-right: 60px;
    padding-top: 70px;
  `)}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ListTitle = styled.h2`
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.16em;
  color: ${({ theme }) => theme.palette.primary};
  margin-bottom: 22px;
  margin-top: 12px;
  text-transform: uppercase;
  font-weight: 700;
`;

export const Download = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;

  a {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.primaryHighlight};

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 45px;
    margin-bottom: 0px;
    width: fit-content;
    a {
      width: fit-content;
    }
  `)}
`;

export const DateContent = styled.p`
  font-size: 16px;
  line-height: 1.8;
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.14em;
`;

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 1.8;
  color: ${({ theme }) => theme.palette.primaryHighlight};
  margin-bottom: 6px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  a {
    font-weight: 700;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
      position: relative;
      bottom: -1.5px;
      transition: transform 0.3s;
      polygon {
        transition: all 0.3s;
      }
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary};

    a {
      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.primary};
          stroke: ${({ theme }) => theme.palette.primary};
        }
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 12px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 18px;
    svg {
      width: 18px;
      height: 16.2px;
      bottom: -1px;
    }
  `)}
`;
