import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Video } from '@components';
import { VIDEO } from '@utils/vars';
import {
  Section, ContentWrapper, VideoWrapper,
} from '../homepage-sections.styles';

const VideoSection = ({
  sectionTitle, contentReferences, nextModuleType, order, locale,
}) => {
  const [trackingRef, isInView] = useInView({ threshold: 0.2, triggerOnce: true });

  return (
    <Section
      sectionTheme="primary"
      paddingTop="0px"
      paddingBottom="60px"
      nextVideo={nextModuleType === VIDEO}
      order={order}
      sectionType="video"
    >
      <ContentWrapper
        flexDirection="column"
        maxWidth="extraWideMaxWidth"
        ref={trackingRef}
      >
        {contentReferences && contentReferences.map((video, index) => {
          const { __typename: contentType } = video;
          if (contentType === 'ContentfulSocialEmbed' && video.type === 'youtube' && index === 0) {
            return (
              <VideoWrapper isInView={isInView} key={`${video.contentfulid}-videoid`}>
                <Video
                  titleLevel={2}
                  title={sectionTitle}
                  caption={video.caption}
                  videoId={video.contentfulid}
                  customThumbnail={video.videoThumbnail}
                  locale={locale}
                />
              </VideoWrapper>
            );
          }
          return null;
        })}
      </ContentWrapper>
    </Section>
  );
};

export default VideoSection;
