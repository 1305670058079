import React, { useState, useEffect, useRef } from 'react';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { Link } from '@components';
import { Header1, Header2 } from '@styles';
import { Arrow } from '@styles/icons';
import { compileRichText, getKeyValue } from '@utils';
import { VIDEO } from '@utils/vars';
import {
  Section, ContentWrapper, BioWrapper, CollageWrapper, AllBioLink, CollageImage, Text, LastWord,
} from '../homepage-sections.styles';

const AnimatedCollageImage = ({
  description, fluid, index, maxHeight,
}) => {
  const [trackingRef, isImgInView] = useInView({ threshold: 0.3, triggerOnce: true });

  return (
    <CollageImage
      index={index}
      ref={trackingRef}
      isImgInView={isImgInView}
      aspectRatio={fluid.aspectRatio}
      maxHeight={maxHeight}
    >
      <Img
        alt={description}
        fluid={fluid}
        className="collage-image"
        style={{ position: 'absolute' }}
      />
    </CollageImage>
  );
};

const BioSection = ({
  sectionTitle, order, sectionMedia, internalSectionLink,
  sectionLink, nextModuleType, summaryOverride,
}) => {
  const imageRef = useRef();
  const [minWidth, setMinWidth] = useState(0);

  useEffect(() => {
    if (imageRef && imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const { width } = rect;
      setMinWidth(width);
    }
  }, [imageRef]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (imageRef && imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const { width } = rect;
        setMinWidth(width);
      }
    };
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [imageRef]);

  const aspectRatio = sectionMedia && sectionMedia[0] && sectionMedia[0].fluid.aspectRatio;
  const { intro } = internalSectionLink || {};
  const finalSummary = summaryOverride || intro;
  const parseText = sectionLink && sectionLink.trim();
  const partText = parseText && parseText.substring(0, parseText.lastIndexOf(' '));
  const lastWord = parseText && parseText.substring(parseText.lastIndexOf(' '), parseText.length);

  return (
    <Section
      sectionTheme="white"
      nextVideo={nextModuleType === VIDEO}
      order={order}
    >
      <ContentWrapper flexDirection="row" tabletFlexDirection="row" maxWidth="wideMaxWidth">
        {(internalSectionLink || finalSummary) && (
          <BioWrapper>
            {sectionTitle && (order !== 0 ? (
              <Header2 textTheme="primary" size="medium">
                {sectionTitle}
              </Header2>
            ) : (
              <Header1 textTheme="primary">
                {sectionTitle}
              </Header1>
            ))}
            {finalSummary && (
              compileRichText({ textTheme: 'primary', size: 'large' })(finalSummary)
            )}
            {internalSectionLink && internalSectionLink.page[0] && sectionLink && (
              <AllBioLink>
                <Link
                  href={internalSectionLink.page[0] && internalSectionLink.page[0].pageUrl}
                >
                  {partText && lastWord && (
                    <Text>
                      {partText}
                      {' '}
                    </Text>
                  )}
                  {(lastWord || sectionLink) && <LastWord>{lastWord || sectionLink}</LastWord>}
                  <Arrow strokeColor="primaryHighlight" height="28px" width="25.1px" />
                </Link>
              </AllBioLink>
            )}
          </BioWrapper>
        )}
        {sectionMedia && (
          <CollageWrapper aspectRatio={aspectRatio} minHeight={minWidth / aspectRatio}>
            {sectionMedia.map((media, index) => (
              <React.Fragment key={media.id}>
                {index === 0 ? (
                  <React.Fragment>
                    <CollageImage
                      index={index}
                      ref={imageRef}
                      isImgInView
                      aspectRatio={media.fluid.aspectRatio}
                    >
                      <Img
                        alt={media.description}
                        fluid={media.fluid}
                        className="collage-image"
                        style={{ position: 'absolute', opacity: '0' }}
                      />
                    </CollageImage>
                    <AnimatedCollageImage
                      index={1}
                      description={media.description}
                      fluid={media.fluid}
                    />
                  </React.Fragment>
                ) : (
                  <AnimatedCollageImage
                    index={index}
                    description={media.description}
                    fluid={media.fluid}
                  />
                )}
              </React.Fragment>
            ))}
          </CollageWrapper>
        )}
      </ContentWrapper>
    </Section>
  );
};

export default BioSection;
