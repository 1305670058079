import React from 'react';
import { Arrow } from '@styles/icons';
import {
  ECard, ECardLink, ECardTitle, ECardType, ECardInfo, ECardDesc,
} from './event-card.styles';

const EventCard = ({
  href, title, type, location, timeslots, virtual, description, iniStartDate,
}) => {
  const {
    venue, address_lines: addressLines, locality, region,
  } = location || {};
  const compIniDate = Math.round(iniStartDate.getTime() / 1000);
  const desc = description && description.length > 250 ? `${description.substring(0, 250)}...` : description;
  const address = (addressLines && addressLines.length) > 0 ? addressLines.join('') : '';
  const newTimeslots = timeslots.filter((el) => el.end_date > compIniDate);
  const timeLength = newTimeslots && newTimeslots.length;
  const startDate = timeLength > 0 && newTimeslots[0].start_date;

  return (
    <ECard href={`${href}?utm_source=website`}>
      <ECardTitle>
        {type && (
          <ECardType>{type.replace('_', ' ')}</ECardType>
        )}
        {title}
      </ECardTitle>
      <p>
        {venue && !virtual && (
          <ECardInfo>{venue}</ECardInfo>
        )}
        {address && !virtual && (
          <ECardInfo>{`${addressLines} ${locality} ${region}`}</ECardInfo>
        )}
        {virtual && (
          <ECardInfo>Virtual</ECardInfo>
        )}
        {startDate && (
          <ECardInfo>
            {(new Date(startDate * 1000)).toLocaleString()}
            {newTimeslots.length > 1 && (
              <React.Fragment>{` + ${newTimeslots.length - 1} more available time${newTimeslots.length - 1 > 1 ? 's' : ''}`}</React.Fragment>
            )}
          </ECardInfo>
        )}
      </p>
      {desc && (
        <ECardDesc>
          {desc}
        </ECardDesc>
      )}
      <ECardLink href={`${href}?utm_source=website`} target="_blank">
        See details
        <Arrow strokeColor="secondaryHighlight" height="13px" width="14.5px" />
      </ECardLink>
    </ECard>
  );
};

export default EventCard;
