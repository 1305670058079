import styled, { css } from 'styled-components';
import { DARK, SECONDARY } from '@utils/vars';

const Main = styled.main`
  z-index: -1;
  padding: 0px ${({ theme, fullWidth }) => (!fullWidth ? `${theme.layout.gutter}px` : '0px')};
  max-width: ${({ fullWidth, theme }) => (!fullWidth ? `${theme.layout.contentMaxWidth}px` : '100%')};
  margin: auto;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.white};

  ${({ mainTheme, theme }) => mainTheme === SECONDARY && css`
    background-color: ${theme.palette.secondary};
  `}

  ${({ mainTheme, theme }) => mainTheme === SECONDARY && css`
    background-color: ${theme.palette.secondary};
  `}

  ${({ mainTheme, theme }) => mainTheme === DARK && css`
    background-color: ${theme.palette.primary};
  `}

  ${({ isSticky, navHeight }) => (isSticky && css`
    padding-top: ${navHeight}px;
    transition: all 0.01s ease-in-out;
  `)};
`;

export default Main;
