import React, { useState, useEffect } from 'react';
import { LinkButton } from '@components';
import { Close } from '@styles/icons';
import { compileRichText, appendQueryString } from '@utils';
import { DISMISS_DONATE_BAR } from '@utils/vars';
import {
  Bar, Content, Wrapper, DonateButtonsWrapper, DonateButton,
  Header, Disclaimer, DonateInfo, CloseButton,
} from './donate-bar.styles';

const DonateBar = (props) => {
  const [closed, setClosed] = useState(false);
  const [closing, setClosing] = useState(false);
  const [render, setRender] = useState(false);
  const {
    title, intro, donationButtons,
    donationLink, donateBarDisclaimer,
    donationButtonsMobile, disclaimerOverride,
  } = props;

  const buttonCount = donationButtons ? donationButtons.length : 0;
  const mobButtonCount = donationButtonsMobile ? donationButtonsMobile.length : 0;

  const CloseBar = () => {
    if (!closed) {
      setClosing(true);
      setTimeout(() => {
        setClosed(true);
        sessionStorage.setItem(DISMISS_DONATE_BAR, 'true');
      }, 400);
      setTimeout(() => {
        setClosing(false);
      }, 500);
    }
  };

  useEffect(() => {
    setClosed(!!sessionStorage.getItem(DISMISS_DONATE_BAR));
  }, []);

  useEffect(() => {
    const callback = () => {
      if (window.scrollY >= 50 && !render && !closed) {
        setRender(true);
      } else if (window.scrollY < 50 && render) {
        setClosing(true);
        setTimeout(() => {
          setRender(false);
        }, 400);
        setTimeout(() => {
          setClosing(false);
        }, 500);
      }
    };
    window.addEventListener('scroll', callback);
    return () => window.removeEventListener('scroll', callback);
  }, [closed, render]);

  return (
    <React.Fragment>
      {!closed && render && donationLink ? (
        <Bar closing={closing}>
          <Wrapper>
            <Content>
              {title && (
              <Header>{title}</Header>
              )}
              {intro && (
                compileRichText({ textTheme: 'white', size: 'xsmall' })(intro)
              )}
              {donationButtonsMobile && (
                <DonateButtonsWrapper mobile>
                  {donationButtonsMobile.map((amount) => (
                    <DonateButton
                      count={buttonCount}
                      mobButtonCount={mobButtonCount}
                      key={amount.id}
                    >
                      <LinkButton
                        href={`${donationLink}?amount=${amount.value}`}
                        size="large"
                        buttonTheme="primary"
                        buttonHoverTheme="secondaryHighlight"
                        textTheme="white"
                        textHoverTheme="primary"
                        target="_blank"
                      >
                        {amount.display}
                      </LinkButton>
                    </DonateButton>
                  ))}
                </DonateButtonsWrapper>
              )}
              {(disclaimerOverride || donateBarDisclaimer) && (
              <Disclaimer>
                {disclaimerOverride || donateBarDisclaimer}
              </Disclaimer>
              )}
            </Content>
            <DonateInfo>
              {donationButtons && (
                <DonateButtonsWrapper>
                  {donationButtons.map((amount) => (
                    <DonateButton count={buttonCount} key={amount.id}>
                      <LinkButton
                        href={appendQueryString(donationLink, `amount=${amount.value}`)}
                        size="large"
                        buttonTheme="primary"
                        buttonHoverTheme="secondaryHighlight"
                        textTheme="white"
                        textHoverTheme="primary"
                      >
                        {amount.display}
                      </LinkButton>
                    </DonateButton>
                  ))}
                </DonateButtonsWrapper>
              )}
            </DonateInfo>
          </Wrapper>
          <CloseButton onClick={CloseBar}>
            <Close width="20px" height="20px" strokeWidth="4" strokeColor="lighterPrimary" />
          </CloseButton>
        </Bar>
      ) : null}
    </React.Fragment>
  );
};

export default DonateBar;
