import React, { useEffect, useState, useRef } from 'react';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { Form, LinkButton } from '@components';
import { Header1, Header2 } from '@styles';
import { useFade, getKeyValue } from '@utils';
import { VIDEO } from '@utils/vars';
import {
  KeyActionSection, ContentWrapper, KeyActionWrapper, KeyActionMedia, FadingWrapper,
  DonateButton, DonateInfo, DonateButtonsWrapper, Disclaimer, DonationSubtitle,
} from '../homepage-sections.styles';

const DonationAction = ({
  donateBarDisclaimer,
  donationButtons,
  donationButtonsMobile,
  donationLink,
  disclaimerOverride,
  title,
}) => {
  const buttonCountDesktop = donationButtons && donationButtons.length;
  const buttonCountMobile = donationButtonsMobile && donationButtonsMobile.length;
  return (
    <DonateInfo>
      {title && (
        <DonationSubtitle>{title}</DonationSubtitle>
      )}
      {donationButtons && (
        <DonateButtonsWrapper>
          {donationButtons.map((amount) => (
            <DonateButton count={buttonCountDesktop} key={amount.id}>
              <LinkButton
                href={`${donationLink}?amount=${amount.value}`}
                size="large"
                buttonTheme="primary"
                buttonHoverTheme="secondaryHighlight"
                textTheme="white"
                target="_blank"
              >
                {amount.display}
              </LinkButton>
            </DonateButton>
          ))}
        </DonateButtonsWrapper>
      )}
      {donationButtonsMobile && (
        <DonateButtonsWrapper mobile>
          {donationButtonsMobile.map((amount) => (
            <DonateButton count={buttonCountMobile} key={amount.id}>
              <LinkButton
                href={`${donationLink}?amount=${amount.value}`}
                size="large"
                buttonTheme="primary"
                buttonHoverTheme="secondaryHighlight"
                textTheme="white"
              >
                {amount.display}
              </LinkButton>
            </DonateButton>
          ))}
        </DonateButtonsWrapper>
      )}
      {(disclaimerOverride || donateBarDisclaimer) && (
        <Disclaimer>{disclaimerOverride || donateBarDisclaimer}</Disclaimer>
      )}
    </DonateInfo>
  );
};

const KeyActionsSection = ({
  sectionTitle, contentReferences, order, sectionMedia, nextModuleType,
  donateBarDisclaimer, locale, formLabelLanguageOverrides,
}) => {
  const [minHeight, setMinHeight] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isFading, hasFaded] = useFade(formSubmitted, 500);
  const [heroRef, isImgInView] = useInView({ threshold: 0.1, triggerOnce: true });
  const contentRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-multi-assign
    const nvtagCallbacks = window.nvtag_callbacks = window.nvtag_callbacks || {};
    nvtagCallbacks.alterFormDefinition = nvtagCallbacks.alterFormDefinition || [];
    nvtagCallbacks.preSegue = nvtagCallbacks.preSegue || [];

    const rearrangeHomepageHeroFields = (args) => {
      const zipFieldset = {
        name: 'ZipCode',
        title: 'ZipCode',
        type: 'fieldset',
        children: [],
      };
      const profileFieldset = {
        name: 'UpdateMyProfile',
        title: 'UpdateMyProfile',
        type: 'fieldset',
        children: [],
      };

      const formName = contentReferences.find((action) => action.ngpFormName);

      if (args.form_definition.name === (formName.ngpFormName || 'Web::Signup::Homepage::Hero')) {
        args.form_definition.form_elements.forEach((child, index) => {
          if (child.name === 'ContactInformation') {
            child.children.forEach((formel, key) => {
              if (formel.name === 'PostalCode') {
                zipFieldset.children.push(formel);
                // eslint-disable-next-line no-param-reassign
                args.form_definition.form_elements[index].children[key] = { type: 'hidden' };
              } else if (formel.name === 'UpdateMyProfile') {
                profileFieldset.children.push(formel);
                // eslint-disable-next-line no-param-reassign
                args.form_definition.form_elements[index].children[key] = { type: 'hidden' };
              }
            });
          }
        });

        args.form_definition.form_elements.push(profileFieldset);
        args.form_definition.form_elements.push(zipFieldset);
      }

      return args;
    };

    const updateFormState = (resp) => {
      const { formUrl: filledUrl } = getKeyValue(resp, 'formdef.options') || {};
      const actionTag = contentReferences.find((ref) => {
        const { __typename: type } = ref;
        return type === 'ContentfulForm';
      });
      const { formUrl } = actionTag;
      if (!formSubmitted && formUrl === filledUrl) {
        setFormSubmitted(true);
      }
    };

    if (nvtagCallbacks.alterFormDefinition) {
      nvtagCallbacks.alterFormDefinition.push(rearrangeHomepageHeroFields);
    }
    if (nvtagCallbacks.preSegue) {
      nvtagCallbacks.preSegue.push(updateFormState);
    }
  }, [contentReferences, formSubmitted, heroRef]);

  useEffect(() => {
    if (heroRef && heroRef.current) {
      const rect = heroRef.current.getBoundingClientRect();
      const { height } = rect;
      setMinHeight(height);
    }
  }, [heroRef]);

  useEffect(() => {
    if (isFading && contentRef && contentRef.current) {
      contentRef.current.scrollIntoView();
    }
  }, [contentRef, isFading]);

  return (
    <KeyActionSection
      sectionTheme="primary"
      paddingBottom={nextModuleType === VIDEO ? '150px' : '0px'}
      nextVideo={nextModuleType === VIDEO}
      paddingTop="0px"
      order={order}
      zIndex={1}
      minHeight={minHeight - 75}
      ref={contentRef}
    >
      <ContentWrapper flexDirection="row" maxWidth="wideMaxWidth" type="keyaction">
        {contentReferences && (
          <KeyActionWrapper isImgInView={isImgInView}>
            {sectionTitle && (order !== 0 ? (
              <Header2 textTheme="white" size="large">
                {sectionTitle}
              </Header2>
            ) : (
              <Header1 textTheme="white" size="medium">
                {sectionTitle}
              </Header1>
            ))}
            {contentReferences.map((action, i) => {
              const { __typename: type } = action;
              if (type === 'ContentfulForm' && i === 0 && !hasFaded) {
                return (
                  <FadingWrapper isFading={isFading} key={action.formUrl}>
                    <Form
                      {...action}
                      formTheme="Dark"
                      size="Large"
                      addClass={`homepage-hero ${locale}`}
                      formLabelLanguageOverrides={formLabelLanguageOverrides}
                      locale={locale}
                    />
                  </FadingWrapper>
                );
              }
              if (type === 'ContentfulDonation' && (contentReferences.length === 1 || hasFaded)) {
                return (
                  <DonationAction
                    {...action}
                    donateBarDisclaimer={donateBarDisclaimer}
                  />
                );
              }
              return null;
            })}
          </KeyActionWrapper>
        )}
      </ContentWrapper>
      {sectionMedia && sectionMedia.map((media) => (
        <KeyActionMedia
          ref={heroRef}
          isImgInView={isImgInView}
          key={media.id}
        >
          <Img
            alt={media.description}
            fluid={media.fluid}
            imgStyle={{ objectFit: 'cover' }}
            className="key-action-image"
          />
        </KeyActionMedia>
      ))}
    </KeyActionSection>
  );
};

export default KeyActionsSection;
