import styled, { css, keyframes } from 'styled-components';
import { Paragraph, createBreakpoint } from '@styles';

const shine = keyframes`
  to {
    background-position: 100% 0, 0 0;
  }
`;

export const TabWrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    width: 100%;
    margin-bottom: 7px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    li {
      width: auto;
      margin-bottom: 0px;
    }
  `)}
`;

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0.16em;
  border-radius: 20px;
  padding: 7px 10px 7px;
  position: relative;
  line-height: 1.2;
  width: 100%;
  height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.primary};
  border: 2px solid ${({ theme }) => theme.palette.primaryHighlight};

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
  `)}

  ${({ theme, selected }) => selected && css`
    background-color: ${theme.palette.primaryHighlight};
    color: ${theme.palette.white};
  `}

  &:hover {
    background-color: ${({ theme }) => theme.palette.primaryHighlight};
    color: ${({ theme }) => theme.palette.white};
  }
`;

export const SheetMapWrapper = styled.div`
  min-height: 500px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.palette.primaryHighlight};

  ${({ isLoading }) => isLoading && css`
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ), lightgray;
    background-repeat: repeat-y;
    background-size: 40% 100%;
    background-position: 0 0;
    animation: ${shine} 1s infinite;
  `}

  .mapboxgl-popup-content {
    border: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
    background-color: ${({ theme }) => theme.palette.white};

    h3 {
      color: ${({ theme }) => theme.palette.primary};
      font-family: ${({ theme }) => theme.font};
      text-transform: uppercase;
      font-size: 22px;
      margin-top: 0px;
      border-bottom: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      padding-bottom: 5px;
      margin-bottom: 15px;
      line-height: 0.9;
    }

    p {
      color: ${({ theme }) => theme.palette.primary};
      font-family: ${({ theme }) => theme.font};
      line-height: 1.2;
      font-size: 14px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    a {
      color: ${({ theme, textTheme }) => (textTheme === 'white' ? theme.palette.white : theme.palette.primaryHighlight)};
      font-weight: 700;
      position: relative;
      flex: 1 1 100%;
      align-self: center;
      text-decoration: none;
      background-image: ${({ theme, textTheme }) => (textTheme === 'white' ? (
    `linear-gradient(${theme.palette.highlight}, ${theme.palette.highlight})`
  ) : (
    `linear-gradient(${theme.palette.primaryHighlight}, ${theme.palette.primaryHighlight})`
  ))};
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 0.12em;
      transition: background-size .3s, background-position .3s, padding 0.3s, margin 0.3s;
      padding-bottom: 0.05em;
      margin-bottom: -0.05em;

      &:hover {
        font-weight: 700;
        background-size: 100% 0.15em;
        background-position: 0% 100%;
        padding-bottom: 0em;
        margin-bottom: 0em;
        background-image: ${({ theme, textTheme }) => (textTheme === 'white' ? (
    `linear-gradient(${theme.palette.secondaryHighlight}, ${theme.palette.secondaryHighlight})`
  ) : (
    `linear-gradient(${theme.palette.highlight}, ${theme.palette.highlight})`
  ))};
      }
    }
  }

  .mapboxgl-popup-tip {
    background: ${({ theme }) => theme.palette.white};
    position: absolute;
    height: 20px;
    width: 20px;
  }

  .mapboxgl-popup-anchor-left {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-left: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-bottom: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-right: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      left: 3px;
    }
    .mapboxgl-popup-content {
      position: relative;
      left: 12px;
    }
  }

  .mapboxgl-popup-anchor-right {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 0px solid ${({ theme }) => theme.palette.white};
      border-bottom: 0px solid ${({ theme }) => theme.palette.white};
      right: 3px;
    }
    .mapboxgl-popup-content {
      position: relative;
      right: 12px;
    }
  }

  .mapboxgl-popup-anchor-bottom {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 0px solid ${({ theme }) => theme.palette.white};
      border-bottom: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      bottom: 3px;
    }
    .mapboxgl-popup-content {
      position: relative;
      bottom: 12px;
    }
  }

  .mapboxgl-popup-anchor-bottom-left {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 0px solid ${({ theme }) => theme.palette.white};
      border-bottom: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      bottom: 3px;
      left: -9px;
    }
    .mapboxgl-popup-content {
      position: relative;
      bottom: 12px;
      left: -26px;
    }
  }

  .mapboxgl-popup-anchor-bottom-right {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 0px solid ${({ theme }) => theme.palette.white};
      border-bottom: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      bottom: 3px;
      right: -9px;
    }
    .mapboxgl-popup-content {
      position: relative;
      bottom: 12px;
      right: -26px;
    }
  }

  .mapboxgl-popup-anchor-top {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-bottom: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      top: 3px;
    }
    .mapboxgl-popup-content {
      position: relative;
      top: 12px;
    }
  }

  .mapboxgl-popup-anchor-top-right {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-bottom: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      top: 3px;
      right: -9px;
    }
    .mapboxgl-popup-content {
      position: relative;
      top: 12px;
      right: -26px;
    }
  }

  .mapboxgl-popup-anchor-top-left {
    .mapboxgl-popup-tip {
      transform: rotate(45deg);
      border-right: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-top: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-left: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
      border-bottom: 0px solid ${({ theme }) => theme.palette.primaryHighlight};
      top: 3px;
      left: -9px;
    }
    .mapboxgl-popup-content {
      position: relative;
      top: 12px;
      left: -26px;
    }
  }

  .mapboxgl-popup-close-button {
    color: ${({ theme }) => theme.palette.primaryHighlight};
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    .mapboxgl-popup-content {
      h3 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  `)}
`;

export const MapWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;

  ${Paragraph} {
    line-height: 1.1;
    display: inline;
    margin-bottom: 0px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-bottom: 32px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-bottom: 40px;
  `)}
`;

export const CaptionTitle = styled.h3`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0px 0px 0px;
  display: inline;

  span {
    font-weight: 400;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 22px;
  `)}
`;

export const Caption = styled.div`
  margin-top: 15px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 20px;
  `)}
`;
