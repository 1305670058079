import React, { useState, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import { Link, LinkButton } from '@components';
import { Arrow, Close } from '@styles/icons';
import NavItem from './nav-item';
import MobileNavItem from './mobile-nav-item';
import HomepageLink from './homepage-link';
import {
  ItemsList, NavigationWrapper, ListWrappers,
  LanguageItem, PrimaryActionItem, SecondaryActionItem, ActionItemsList,
  HomepageItemsList, LanguageItemsList, ContentWrapper, MenuButton,
  ModalBackground, SkipLink,
} from './navigation.styles';

const Navigation = ({
  navigation, languages, navRef, isSticky, navHeight, navTheme, mobNavTheme, locale,
}) => {
  const [mobOpen, setMobOpen] = useState(false);
  const [mobClosing, setMobClosing] = useState(false);
  const {
    items, homepage, primaryAction, secondaryAction, mobileButton,
  } = navigation;
  const { close, menu } = mobileButton || {};

  useEffect(() => {
    const page = document.getElementsByTagName('html');
    if (page && page[0]) {
      if (mobOpen) {
        page[0].classList.add('overlay-active');
      } else {
        page[0].classList.remove('overlay-active');
      }
    }
  }, [mobOpen]);

  return (
    <React.Fragment>
      <SkipLink
        href="#main-content"
        isSticky={isSticky}
        navTheme={navTheme}
        mobNavTheme={mobNavTheme}
      >
        Skip to main content
      </SkipLink>
      <FocusTrap active={mobOpen}>
        <NavigationWrapper
          id="navigation"
          role="navigation"
          ref={navRef}
          isSticky={isSticky}
          navHeight={navHeight}
          navTheme={navTheme}
          mobNavTheme={mobNavTheme}
          mobOpen={mobOpen}
        >
          <ModalBackground mobOpen={mobOpen} mobClosing={mobClosing} />
          <ContentWrapper
            mobOpen={mobOpen}
            mobClosing={mobClosing}
            isSticky={isSticky}
          >
            <ListWrappers mobOpen={mobOpen} isSticky={isSticky}>
              {primaryAction && items && (
                <MenuButton
                  onClick={() => {
                    if (mobOpen) {
                      setMobClosing(true);
                      setTimeout(() => {
                        setMobOpen(!mobOpen);
                        setMobClosing(false);
                      }, 650);
                    } else {
                      setMobOpen(!mobOpen);
                    }
                  }}
                  mobOpen={mobOpen}
                  aria-expanded={mobOpen}
                  navTheme={navTheme}
                  mobNavTheme={mobNavTheme}
                  isSticky={isSticky}
                >
                  {mobOpen && <Close width="10px" height="10px" strokeColor="white" />}
                  {mobOpen ? (close || 'close') : (menu || 'menu')}
                  {!mobOpen && <Arrow strokeColor="white" height="10px" width="10px" />}
                </MenuButton>
              )}
              {homepage && (
                <HomepageItemsList mobOpen={mobOpen} role="menu">
                  <HomepageLink
                    mobOpen={mobOpen}
                    isSticky={isSticky}
                    mobile
                    homepage={homepage}
                    navTheme={mobNavTheme}
                    locale={locale}
                  />
                  <HomepageLink
                    mobOpen={mobOpen}
                    isSticky={isSticky}
                    homepage={homepage}
                    navTheme={navTheme}
                    locale={locale}
                  />
                </HomepageItemsList>
              )}
              {items && (
                <ItemsList mobOpen={mobOpen} role="menu">
                  {items.map((item) => (
                    <NavItem
                      {...item}
                      role="menuitem"
                      navTheme={navTheme}
                      mobNavTheme={mobNavTheme}
                      isSticky={isSticky}
                      key={`${item.path}-dsk-i`}
                    />
                  ))}
                  {items.map((item) => (
                    <MobileNavItem
                      {...item}
                      mobOpen={mobOpen}
                      role="menuitem"
                      isSticky={isSticky}
                      key={`${item.path}-mbl-i`}
                    />
                  ))}
                </ItemsList>
              )}
              <ActionItemsList mobOpen={mobOpen} role="menu">
                {secondaryAction && (
                <SecondaryActionItem
                  isSticky={isSticky}
                  mobOpen={mobOpen}
                  role="menuitem"
                  navTheme={navTheme}
                  mobNavTheme={mobNavTheme}
                >
                  <LinkButton
                    href={secondaryAction.path}
                    textTheme="primary"
                    buttonTheme="highlight"
                    target="_blank"
                  >
                    {secondaryAction.title}
                  </LinkButton>
                </SecondaryActionItem>
                )}
                {primaryAction && (
                <PrimaryActionItem
                  isSticky={isSticky}
                  mobOpen={mobOpen}
                  role="menuitem"
                  navTheme={navTheme}
                  mobNavTheme={mobNavTheme}
                >
                  <LinkButton
                    href={primaryAction.path}
                    textTheme="primary"
                    buttonTheme="highlight"
                    target="_blank"
                  >
                    {primaryAction.title}
                  </LinkButton>
                </PrimaryActionItem>
                )}
              </ActionItemsList>
              {items && (
              <LanguageItemsList
                key="mobile-lang"
                mobile
                mobOpen={mobOpen}
                role="menu"
                navTheme={navTheme}
                mobNavTheme={mobNavTheme}
              >
                {languages && languages.map((language) => {
                  const forDisplay = navigation.languages.find((lang) => (
                    lang.code === language.language
                  ));
                  return (
                    <LanguageItem
                      role="menuitem"
                      navTheme={navTheme}
                      mobNavTheme={mobNavTheme}
                      mobOpen={mobOpen}
                      isSticky={isSticky}
                      key={`${language.pageUrl}-mobile`}
                    >
                      <Link href={language.pageUrl}>{forDisplay.display}</Link>
                    </LanguageItem>
                  );
                })}
              </LanguageItemsList>
              )}
            </ListWrappers>
            {items && (
            <LanguageItemsList
              key="desktop-lang"
              mobile={false}
              mobOpen={mobOpen}
              role="menu"
              navTheme={navTheme}
              mobNavTheme={mobNavTheme}
            >
              {languages && languages.map((language) => {
                const forDisplay = navigation.languages.find((lang) => (
                  lang.code === language.language
                ));
                return (
                  <LanguageItem
                    role="menuitem"
                    navTheme={navTheme}
                    mobNavTheme={mobNavTheme}
                    mobOpen={mobOpen}
                    isSticky={isSticky}
                    key={`${language.pageUrl}-desktop`}
                  >
                    <Link href={language.pageUrl}>{forDisplay.display}</Link>
                  </LanguageItem>
                );
              })}
            </LanguageItemsList>
            )}
          </ContentWrapper>
        </NavigationWrapper>
      </FocusTrap>
    </React.Fragment>
  );
};

export default Navigation;
