const dateToString = (date, dateLocal = 'en-us') => {
  if (date
      && date.getMonth() >= 4 && date.getMonth() <= 6
      && (!dateLocal || dateLocal === 'en-us')) {
    return (date.toLocaleDateString(dateLocal || 'en-us', { month: 'long', day: 'numeric', year: 'numeric' }));
  }
  return (date.toLocaleDateString(dateLocal || 'en-us', { month: 'short', day: 'numeric', year: 'numeric' }));
};

export default dateToString;
