import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { compileRichText } from '@utils';
import { LARGE } from '@utils/vars';
import {
  Hero, Title, HeroContentWrapper, HeroWrapper, ImageWrapper,
} from './bio-hero.styles';

const BioHero = ({ title, heroImage, intro }) => {
  const [trackingRef, isCardInView, entry] = useInView({ threshold: 0.1, triggerOnce: true });
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    if (entry) {
      setAnimate(isCardInView);
    }
  }, [isCardInView, entry]);

  return (
    <Hero>
      <HeroWrapper>
        <HeroContentWrapper>
          {title && (
            <Title ref={trackingRef}>
              {title}
            </Title>
          )}
          {intro && (
            compileRichText({ textTheme: 'white', size: LARGE })(intro)
          )}
        </HeroContentWrapper>
        {heroImage && heroImage.fluid && (
          <ImageWrapper isCardInView={animate}>
            <Img
              alt={heroImage.description}
              fluid={heroImage.fluid}
              className="hero-image"
            />
          </ImageWrapper>
        )}
      </HeroWrapper>
    </Hero>
  );
};

export default BioHero;
