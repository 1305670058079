import React, { useRef, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Container, Navigation, Footer, DonateBar, AlertBar, Splash,
} from '@components';
import { theme, GlobalStyles } from '@styles';
import Main from './layout.styles';
import wuiconsWoff from '../../styles/fonts/wuicons.woff';

const Template = ({
  children, navigation, languages, footerProps, donateBarProps, alertBarProps,
  enableAlertBar, enableDonateBar, metaDescription, metaUrl, metaTitle, metaImageUrl,
  fbAppID, twitter, enableSplash, splashProps, locale, fullWidth = false, navTheme = 'dark',
  mainTheme, doNotIndex,
}) => {
  const navRef = useRef();
  const alertRef = useRef();
  const [isSticky, setSticky] = useState(false);
  const [navHeight, setNavHeight] = useState(50);

  const handleScroll = () => {
    if (navRef && navRef.current) {
      const elRect = navRef.current.getBoundingClientRect();
      let newNavHeight = elRect.height;
      // eslint-disable-next-line
      window.pageYOffset > elRect.bottom
        ? setSticky(true)
        : setSticky(false);

      if (alertRef && alertRef.current) {
        const alRect = navRef.current.getBoundingClientRect().height;
        newNavHeight += alRect;
      }
      setNavHeight(newNavHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const finalMetaUrl = metaUrl.endsWith('/') ? metaUrl : `${metaUrl}/`;

  return (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        <link rel="preconnect" href={wuiconsWoff} as="font" type="font/woff" crossOrigin />
        <link rel="preconnect" href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" />

        {/* General tags */}
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {metaImageUrl && <meta name="image" content={`https:${metaImageUrl}`} />}
        {doNotIndex && <meta name="robots" content="noindex, nofollow" />}
        {doNotIndex && <meta name="googlebot" content="noindex, nofollow" />}

        {/* OpenGraph tags */}
        <meta property="og:url" content={finalMetaUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale ? locale.substring(0, 2) : 'en'} />
        {metaImageUrl && <meta property="og:image" content={`https:${metaImageUrl}`} />}
        <meta property="fb:app_id" content={fbAppID} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        {metaImageUrl && <meta name="twitter:image" content={`https:${metaImageUrl}`} />}
        <html lang={locale ? locale.substring(0, 2) : 'en'} />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "@id": "https://www.michelleforboston.com/#organization",
              "url": "https://www.michelleforboston.com/",
              "name": "Michelle for Boston",
              "logo": "https://images.ctfassets.net/1hf11j69ure4/1cUKrqixPyWGHs6eVKCaij/37c79fc5f9c55731e1c1e047e5009e4f/mwfm-light__1_.png",
              "sameAs": [
                "https://www.facebook.com/michelleforboston",
                "https://twitter.com/wutrain",
                "https://www.youtube.com/user/michelleforboston",
                "https://www.instagram.com/wutrain/"
              ]
            }
          `}
        </script>
      </Helmet>
      <ThemeProvider theme={{ ...theme, locale }}>
        <Container navTheme={navTheme}>
          {enableSplash && <Splash {...splashProps} />}
          {enableAlertBar && <AlertBar {...alertBarProps} alertRef={alertRef} />}
          <Navigation
            navigation={navigation}
            languages={languages}
            navRef={navRef}
            isSticky={isSticky}
            navHeight={navHeight}
            navTheme={navTheme}
            mobNavTheme={navigation.mobNavTheme || navTheme}
            locale={locale}
          />
          <Main
            navHeight={navHeight}
            isSticky={isSticky}
            fullWidth={fullWidth}
            id="main-content"
            navTheme={navTheme}
            mainTheme={mainTheme}
          >
            {children}
          </Main>
          {enableDonateBar && <DonateBar {...donateBarProps} />}
          <Footer {...footerProps} />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Template;
