const appendQueryString = (link, queryString) => {
  if (!link || !queryString) {
    return null;
  }

  if (link.indexOf('?') !== -1) {
    return `${link}&${queryString}`;
  }

  return `${link}?${queryString}`;
};

export default appendQueryString;
