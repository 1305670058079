import React, { useState } from 'react';
import { Link } from '@components';
import { Chevron } from '@styles/icons';
import { slugify } from '@utils';
import {
  Subitem, Item, SubitemsList, ItemButton,
} from './navigation.styles';

const NavItem = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    path, title, subitems, navTheme, isSticky, mobNavTheme,
  } = props;

  const expandSubNav = () => {
    if (!expanded) {
      setExpanded(true);
    }
  };

  const collapseSubNav = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  const controlSubNav = (e) => {
    if (e.key === 'Enter') {
      setExpanded(!expanded);
    }
  };

  return (
    <Item
      onMouseOver={expandSubNav}
      onMouseLeave={collapseSubNav}
      navTheme={navTheme}
      mobNavTheme={mobNavTheme}
      isSticky={isSticky}
      hasSubitems={subitems}
    >
      {path ? <Link href={path}>{title}</Link> : <span>{title}</span>}
      {subitems && (
        <React.Fragment>
          <ItemButton
            onKeyPress={(e) => controlSubNav(e)}
            aria-expanded={expanded}
            aria-controls={`subnav--${slugify(title)}`}
            navTheme={navTheme}
            mobNavTheme={mobNavTheme}
          >
            <span>show submenu</span>
            <Chevron />
          </ItemButton>
          <SubitemsList
            id={`subnav--${slugify(title)}`}
            expanded={expanded}
            role="menu"
            navTheme={navTheme}
            mobNavTheme={mobNavTheme}
          >
            {subitems.map((subitem) => (
              <Subitem role="menuitem" key={`${subitem.path}-dsk`} navTheme={navTheme}>
                <Link href={subitem.path}>{subitem.title}</Link>
              </Subitem>
            ))}
          </SubitemsList>
        </React.Fragment>
      )}
    </Item>
  );
};

export default React.memo(NavItem);
