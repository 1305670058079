import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { Arrow } from '@styles/icons';
import { compileRichText } from '@utils';
import { Card, Text, LastWord } from './action-card.styles';

const LinkCard = (props) => {
  const {
    text, href, image, textTheme, bgTheme, bdHighlight, description, target, hoverImage,
  } = props;
  const partText = text && text.substring(0, text.lastIndexOf(' '));
  const lastWord = text && text.substring(text.lastIndexOf(' '), text.length);
  return (
    <Card
      href={href}
      textTheme={textTheme}
      bgTheme={bgTheme}
      bdHighlight={bdHighlight}
      target={target}
    >
      {image && image.fluid && (
        <React.Fragment>
          <div className="card-image-wrapper">
            <Img
              alt={image.description}
              fluid={image.fluid}
              className="card-image"
              objectFit="contain"
              objectPosition="50% 50%"
            />
            {hoverImage && hoverImage.fluid && (
              <div className="hover-card-image-wrapper">
                <Img
                  alt={hoverImage.description}
                  fluid={hoverImage.fluid}
                  className="hover-card-image"
                  objectFit="contain"
                  objectPosition="50% 50%"
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      <span>
        {partText && lastWord && (
          <Text>
            {partText}
            {' '}
          </Text>
        )}
        {(lastWord || text) && <LastWord>{lastWord || text}</LastWord>}
        <Arrow strokeColor={bdHighlight} height="26px" width="29px" />
      </span>
      {description && (
        compileRichText({ textTheme, size: 'xsmall' })(description)
      )}
    </Card>
  );
};

export default LinkCard;
