import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Disclaimers = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  width: auto;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 0px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 14px;
    margin-top: 50px;
  `)}
`;

export const ContactDetails = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  width: auto;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 0px;
  line-height: 1.6;

  > span, > a {
    display: block;
  }

  a {
    cursor: pointer;
    &:hover {
      font-weight: 700;
      letter-spacing: 0em;

      svg {
        transform: translate3d(10%,-10%,4px);
        path {
          stroke-width: 1;
        }
      }
    }

    svg {
      margin-left: 6px;
      padding-bottom: 0px;
      transition: all 0.3s;
      path {
        stroke-width: 0;
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 50px;
  `)}
`;

export const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const SocialItem = styled.li`
  min-height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;

  a {
    font-family: "wuicons";
    color: ${({ theme }) => theme.palette.white};
    background: ${({ theme }) => theme.palette.primaryHighlight};
    border-radius: 50%;
    font-size: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
      background: ${({ theme }) => theme.palette.secondaryHighlight};
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 7px;
    a {
      font-size: 20px;
      height: 34px;
      width: 34px;
      margin-right: 20px;
    }
  `)}
`;

export const PrimaryLinks = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-bottom: 0px;
    padding-right: 40px;
  `)}
`;

export const PrimaryItem = styled.li`
  display: flex;
  padding: 5px 25px 5px 0px;
  padding: ${({ type }) => (type === 'primary' ? '5px 25px 5px 0px' : '5px 25px 20px 0px')};

  & > a, & > span a {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    ${({ type, theme }) => (type === 'primary' ? css`
      font-size: 20px;
      padding: 13px 35px 16px 35px;
      margin-top: 10px;
    ` : css`
      color: ${theme.palette.primary};
      background-image: linear-gradient(transparent, transparent);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 0em;
      transition: background-size .3s, background-position .3s, padding 0.3s, margin 0.3s;
      padding-bottom: 0.5rem;
      margin-bottom: -0.5rem;
    `)};

    ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
      ${({ type }) => (type === 'primary' && css`
        font-size: 16px;
        padding: 8px 20px 10px 20px;
        width: auto;
      `)};
    `)}
  }

  &:hover {
    font-weight: 700;

    ${({ type, theme }) => (type === 'primary' ? css`
      a {
        background-color: ${theme.palette.secondaryHighlight};
        color: ${theme.palette.primary};
      }
    ` : css`
      a {
        background-size: 100% 0.25em;
        background-position: 0% 100%;
        padding-bottom: 0.5em;
        margin-bottom: -0.5em;
        background-image: linear-gradient(${theme.palette.primaryHighlight}, ${theme.palette.primaryHighlight});
      }
    `)};
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-bottom: 0px;
    & > a {
      font-size: 16px;

      ${({ type }) => (type === 'primary' && css`
      padding: 4px 16px 8px;
    `)};
    }
  `)}
`;

export const SecondaryLinks = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const SecondaryItem = styled.li`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    border-right: 0px;
  }

  & > a {
    color: ${({ theme }) => theme.palette.primary};
    position: relative;
    font-size: 18px;
    line-height: 1.2;
  }

  &:hover {
    font-weight: 700;

    & > a {
      letter-spacing: -0.01em;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-bottom: 23px;
    & > a {
      font-size: 16px;
      margin-bottom: 0px
    }
  `)}
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  order: ${({ type }) => (type === 'form' ? 0 : 2)};
  min-width: 100%;

  svg {
    padding-bottom: 20px;
  }

  &:first-child {
    align-items: flex-start;
  }

  .at.ngp-form.footer-form {
    position: relative;
    margin: 0px 0px 60px;

    ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
      max-width: 415px;
    `)}

    header.at-title {
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 22px;
    }

    legend {
      opacity: 0;
      height: 0px;
      position: absolute;
      margin: 0px;
      padding: 0px;
      width: 0px;
    }

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    fieldset.at-fieldset {

      label {
        font-size: 14px;
      }

      input {
        border-radius: 0px;
        border-width: 2px;
      }

      &.Email {
        margin-top: 15px;
        width: calc(100% + 1.25rem);
      }

      &.ZipCode {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .FirstName, .PostalCode {
          width: 50%;
        }
      }

      .at-fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .at-row.at-row-full {

          .error {
            margin-bottom: 0px;
            padding-left: 0px;
            padding-bottom: 0px;
          }
        }
        input[type="text"], input[type="tel"], input[type="email"] {
          margin-bottom: 0px !important;
        }
      }
    }

    .at-form-submit {
      width: calc(100% - 1.25rem);
      align-self: baseline;
      display: flex;
      padding: 30px 0px 0px;

      input[type="submit"] {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 52px;
        top: 0px;
        margin: 0px;
        position: relative;
        letter-spacing: 0.16em;
        font-size: 16px;
        background-color: ${({ theme }) => theme.palette.primaryHighlight};
        color: ${({ theme }) => theme.palette.white};

        &:hover {
          color: ${({ theme }) => theme.palette.primary};
        }
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
    .at.ngp-form.footer-form {
      fieldset.at-fieldset {
        &.Email {
          min-width: 65%;
          width: 65%;
          margin-top: 15px;
        }
      }
      .at-form-submit {
        min-width: calc(35% + 0.625rem);
        width: calc(35% + 0.625rem);
        margin-left: -0.625rem;
        padding-top: 29px;
        input[type="submit"] {
          top: 7px;
        }
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.ngpFormRow, css`
    .at.ngp-form.footer-form {
      .at-form-submit {
        min-width: calc(35% - 0.625rem);
        width: calc(35% - 0.625rem);
        margin-left: -0.625rem;
        padding-top: 29px;
        input[type="submit"] {
          top: 7px;
        }
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    order: 0;
    min-height: 100%;
    justify-content: space-between;
    align-self: ${({ type }) => (type === 'form' ? 'flex-start' : 'unset')};
    min-width: auto;

    .at.ngp-form.footer-form {
      margin: 40px 0px 0px;
      min-width: 420px;
    }

    .at.ngp-form.footer-form.ngp-form--HideTitle {
      margin: 0px;
    }

    .at.ngp-form.footer-form {
      .at-form-submit {
        min-width: calc(35% + 1.25rem);
        width: calc(35% + 1.25rem);
        margin-left: -0.625rem;
        padding-top: 29px;
        input[type="submit"] {
          top: 7px;
        }
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    .at.ngp-form.footer-form {
      margin: 0px;
    }
  `)}

`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  order: 2;
  min-width: 100%;

  svg {
    padding-bottom: 20px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: ${({ type }) => (type === 'content' ? 'column' : 'row')};
    order: 0;
    min-width: auto;
    ${({ type }) => (type === 'nav' && css`
      width: 100%;
      min-height: 100%;
      align-items: unset;
    `)};
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;
    justify-content: space-between;
    ${({ type }) => (type === 'nav' && css` width: auto;`)};
  `)}
`;

export const FooterWrapper = styled.footer`
  padding: 60px ${({ theme }) => theme.layout.gutter}px 100px;
  background-color: ${({ theme }) => theme.palette.medPrimary};

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding: 75px ${theme.layout.gutter}px
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 100px ${theme.layout.gutter}px
  `)}
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ theme }) => theme.layout.wideMaxWidth}px;
  margin: 0px auto;
`;
