import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import FormWrapper from './form.styles';

const Form = (props) => {
  const {
    formTheme = 'Light',
    databag = 'everybody',
    fastactionNoLogin = 'true',
    formUrl = '',
    addClass = '',
    hideFormTitle = false,
    hideAdditionalInformationTitle = false,
    locale,
    formLabelLanguageOverrides,
    formLevelLabelLanguageOverrides,
  } = props;

  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!window.nvtag) {
      setRender(true);
    } else {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-multi-assign
    const nvtagCallbacks = window.nvtag_callbacks = window.nvtag_callbacks || {};
    nvtagCallbacks.alterFormDefinition = nvtagCallbacks.alterFormDefinition || [];
    nvtagCallbacks.onSubmit = nvtagCallbacks.onSubmit || [];

    const updateFormFieldLabels = (args) => {
      if (locale !== 'enUS' && (formLabelLanguageOverrides || formLevelLabelLanguageOverrides)) {
        if (args.form_definition.title
          && (formLabelLanguageOverrides || formLevelLabelLanguageOverrides)
        ) {
          const overrideTitle = formLevelLabelLanguageOverrides
            && formLevelLabelLanguageOverrides.title;
          // eslint-disable-next-line no-param-reassign
          args.form_definition.title = overrideTitle;
        }
        args.form_definition.form_elements.forEach((child) => {
          if (child.name === 'submitForm') {
            const buttonLabel = (
              formLevelLabelLanguageOverrides && formLevelLabelLanguageOverrides[child.name]
            ) || (
              formLabelLanguageOverrides && formLabelLanguageOverrides[child.name]
            );
            if (buttonLabel) {
              // eslint-disable-next-line no-param-reassign
              child.value = buttonLabel;
            }
          }
          if (child && child.children) {
            child.children.forEach((formel) => {
              const overrideLabel = (
                formLevelLabelLanguageOverrides && formLevelLabelLanguageOverrides[formel.name]
              ) || (
                formLabelLanguageOverrides && formLabelLanguageOverrides[formel.name]
              );
              if (overrideLabel) {
                // eslint-disable-next-line no-param-reassign
                formel.title = overrideLabel;
              }
              return formel;
            });
          }
        });
      }

      return args;
    };

    const postSubmit = (args) => {
      if (locale !== 'enUS' && formLevelLabelLanguageOverrides && formLevelLabelLanguageOverrides.thankYou) {
        if (formLevelLabelLanguageOverrides.thankYou) {
          setSubmitted(true);
        }
      }

      return args;
    };

    if (nvtagCallbacks.alterFormDefinition) {
      nvtagCallbacks.alterFormDefinition.push(updateFormFieldLabels);
    }
    if (nvtagCallbacks.onSubmit) {
      nvtagCallbacks.onSubmit.push(postSubmit);
    }
  }, [formLabelLanguageOverrides, formLevelLabelLanguageOverrides, locale]);

  useEffect(() => {
    // eslint-disable-next-line no-multi-assign
    const nvtagCallbacks = window.nvtag_callbacks = window.nvtag_callbacks || {};
    nvtagCallbacks.postRender = nvtagCallbacks.postRender || [];
    // This function can be called anything, we don't care
    const trackLoading = () => {
      if (loading) {
        setLoading(false);
      }
    };
    if (nvtagCallbacks.postRender) {
      nvtagCallbacks.postRender.push(trackLoading);
    }
  }, [loading]);

  const hideAdditionalTitleClass = hideAdditionalInformationTitle ? 'ngp-form--HideAddInfoTitle' : '';
  const overrideTitle = formLevelLabelLanguageOverrides && formLevelLabelLanguageOverrides.title;
  const hideTitleClass = (hideFormTitle || overrideTitle) ? 'ngp-form--HideTitle' : '';
  const size = 'Large';

  return (
    <FormWrapper>
      {render && (
        <Helmet>
          <script type="text/javascript" src="https://d3rse9xjbp8270.cloudfront.net/at.js" crossOrigin="anonymous" />
          <link rel="preload" href="https://d3rse9xjbp8270.cloudfront.net/at.js" as="script" crossOrigin="anonymous" />
        </Helmet>
      )}
      {overrideTitle && !submitted && (
        <div
          className={`at ngp-form ngp-form--${formTheme} ngp-form--${size} ${addClass} ${hideAdditionalTitleClass} title-only`}
        >
          <header className="at-title">
            {overrideTitle}
          </header>
        </div>
      )}
      {!submitted && (
        <div
          key={formUrl}
          className={`at ngp-form ngp-form--${formTheme} ngp-form--${size} ${addClass} ${hideTitleClass} ${hideAdditionalTitleClass}${submitted ? 'submitted' : ''}`}
          data-fastaction-endpoint="https://fastaction.ngpvan.com/"
          data-databag={databag}
          data-databag-endpoint={databag === 'everybody' && 'https://profile.ngpvan.com'}
          data-inline-errors
          data-fastaction-nologin={fastactionNoLogin}
          data-labels="above"
          data-mobile-autofocus={false}
          data-form-url={formUrl}
        />
      )}
      {submitted && (
        <div
          className={`at ngp-form ngp-form--${formTheme} ngp-form--${size} ${addClass} ${hideAdditionalTitleClass} title-only`}
        >
          <header className="at-title">
            {formLevelLabelLanguageOverrides.thankYou}
          </header>
        </div>
      )}
    </FormWrapper>
  );
};

export default Form;
