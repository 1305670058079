import React from 'react';
import theme from '../theme';

const NewWindow = ({
  width = '22px',
  height = '22px',
  fill = 'highlight',
}) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.56311 11.0245L11.0349 12.4963L18.9704 4.56074L18.9529 9.19872L21.03 9.19116L21.0594 1L12.8683 1.02944L12.8607 3.10647L17.4986 3.08895L9.56311 11.0245ZM10.9837 3.4355L10.9836 5.94458L3.49592 5.94471V18.4908H15.9755L15.9753 10.9629L18.4715 10.9631V21H1V3.4355H10.9837Z"
      fill={theme.palette[fill]}
      stroke={theme.palette[fill]}
    />
  </svg>
);

export default NewWindow;
