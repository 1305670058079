import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';
import { switchBasedOnScheme } from '@utils';
import {
  SMALL, MEDIUM, LARGE, XLARGE,
} from '@utils/vars';

const LinkButton = styled.span`
  display: flex;
  align-items: center;
  white-space: normal;
  width: 100%;

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0.16em;
    border-radius: ${({ theme }) => theme.button.radius};
    line-height: 1.2;
    width: 100%;
    text-align: center;
    color: ${({ theme, textTheme }) => theme.palette[textTheme] || theme.palette.white};
    background-color: ${({ theme, buttonTheme }) => theme.palette[buttonTheme] || theme.palette.highlight};

    &:hover {
      background-color: ${({ theme, buttonHoverTheme }) => theme.palette[buttonHoverTheme] || theme.palette.highlight};
      color: ${({ theme, textHoverTheme }) => theme.palette[textHoverTheme] || theme.palette.white};
    }
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      a {
        font-size: 14px;
        letter-spacing: 0.08em;
        padding: 4px 5px 6px 5px;
      }
    `,
    [MEDIUM]: css`
      a {
        font-size: 14px;
        padding: 5px 15px 7px 15px;
        letter-spacing: 0.16em;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        a {
          font-size: 16px;
          padding: 6.5px 24px 8.5px 24px;
        }
      `)}
    `,
    [LARGE]: css`
      a {
        font-size: 16px;
        padding: 8px 25px 9px 25px;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        a {
          font-size: 18px;
          padding: 11px 45px 12px 45px;
        }
    `)}
    `,
    [XLARGE]: css`
      a {
        font-size: 18px;
        padding: 13px 30px 16px 30px;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        a {
          font-size: 20px;
          padding: 13px 45px 16px 45px;
        }
    `)}
    `,
  })}
`;

export default LinkButton;
