import styled, { css, keyframes } from 'styled-components';
import {
  Header1, Header2, createBreakpoint, a11yOnly, fade,
} from '@styles';
import {
  VideoContainer, VideoCaption,
} from '../video/video.styles';

const fadeInSlide = (y) => keyframes`
  0% {
    transform: translate3d(0,${y || '100px'},0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

export const Download = styled.div`
  width: fit-content;
  margin-top: 50px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: ${({ sectionTheme, theme }) => theme.palette[sectionTheme]};
  padding-top: ${({ order }) => (order === 0 ? '0px' : '48px')};
  padding-right: ${({ theme }) => theme.layout.gutter}px;
  padding-left: ${({ theme }) => theme.layout.gutter}px;
  padding-bottom: ${({ sectionType, nextVideo }) => (sectionType === 'video' ? '30px' : (nextVideo ? '100px' : '60px'))};

  ${({ hiddenTitle }) => hiddenTitle && css`
    h1, h2 {
      ${a11yOnly};
    }
  `}
  position: relative;
  overflow-y: visible;
  z-index: ${({ zIndex }) => zIndex || 0};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-wrap: nowrap;
    flex-direction: row;
    padding-top: ${({ paddingTop }) => paddingTop || '140px'};
    padding-right: ${theme.layout.gutter}px;
    padding-left: ${theme.layout.gutter}px;
    padding-bottom: ${({ paddingBottom, nextVideo }) => (nextVideo ? '210px' : (paddingBottom || '140px'))};
    ${({ minHeight }) => minHeight && (css` min-height: ${minHeight}px;`)}
  `)}
`;

export const KeyActionSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: ${({ sectionTheme, theme }) => theme.palette[sectionTheme]};
  padding-top: ${({ order }) => (order === 0 ? '0px' : '60px')};
  padding-right: ${({ theme }) => theme.layout.gutter}px;
  padding-left: ${({ theme }) => theme.layout.gutter}px;
  padding-bottom: ${({ sectionType, nextVideo }) => (sectionType === 'video' ? '30px' : (nextVideo ? '100px' : '60px'))};

  ${({ hiddenTitle }) => hiddenTitle && css`
    h1, h2 {
      ${a11yOnly};
    }
  `}
  position: relative;
  overflow-y: visible;
  z-index: ${({ zIndex }) => zIndex || 0};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-wrap: nowrap;
    flex-direction: row;
    padding-top: ${({ paddingTop }) => paddingTop || '140px'};
    padding-right: ${theme.layout.gutter}px;
    padding-left: ${theme.layout.gutter}px;
    padding-bottom: ${({ paddingBottom, nextVideo }) => (nextVideo ? '210px' : (paddingBottom || '140px'))};
    ${({ minHeight }) => minHeight && (css` min-height: ${minHeight}px;`)}
    min-height: 725px;
  `)}

  .at.ngp-form.homepage-hero {
    margin-bottom: 15px;

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    header.at-title {
      font-size: 22px;
      font-weight: 400;
      line-height: 1.37;
      margin-bottom: 25px;
      width: calc(100% - 1.25rem);
    }

    .at-row-full {
      min-width: 100%;
      label {
        min-width: 100%;
      }
    }

    .at-form-submit {
      width: 100%;
      display: flex;
      padding: 0px;
      input[type="submit"].at-submit {
        width: calc(100% - 1.25rem);
        min-width: calc(100% - 1.25rem);
        max-width: calc(100% - 1.25rem);
        margin-top: 10px;
        margin-right: 0px;
        color: ${({ theme }) => theme.palette.primary};
        background-color: ${({ theme }) => theme.palette.highlight};

        &:hover {
          background-color: ${({ theme }) => theme.palette.secondaryHighlight};
        }
      }
    }



    ${({ theme }) => theme.locale === 'esMX' && css`
      &.esMX .at-form-submit {
        input[type="submit"].at-submit {
          padding: 13px 20px 16px;
          font-size: 16px;
        }
      }

      ${createBreakpoint(theme.breakpoints.desktopSmall, css`
        &.esMX .at-form-submit {
          input[type="submit"].at-submit {
            font-size: 14px;
          }
        }
      `)}

      ${createBreakpoint(theme.breakpoints.desktopMedium, css`
        &.esMX .at-form-submit {
          input[type="submit"].at-submit {
            font-size: 16px;
          }
        }
      `)}
    `};



    ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
      .at-fieldset.ZipCode {
        width: calc(40% - 0.625rem);
        min-width: calc(40% - 0.625rem);
        max-width: calc(40% - 0.625rem);
        margin-right: 0.625rem;
        label {
          min-width: 100%;
        }
      }
      .at-form-submit {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
        padding: 0px 0px 0px 0.625rem;
        margin-left: 0px;
        margin-right: 0px;
        display: flex;
        input[type="submit"].at-submit {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          margin-top: 24px;
        }

      }
      .at-markup.UpdateMyProfile {
        margin-bottom: 15px 0px;
      }
    `)}

    ${({ theme }) => createBreakpoint(theme.breakpoints.ngpFormRow, css`
      .at-form-submit {
        width: calc(60% - 1.25rem);
        min-width: calc(60% - 1.25rem);
        max-width: calc(60% - 1.25rem);
        padding: 0px 0px 0px 0.625rem;
      }
    `)}

    ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
      margin-bottom: 80px;

      .at-form-submit {
        width: 60%;
        min-width: 60%;
        max-width: 60%;
        padding: 0px 0px 0px 0.625rem;
      }

      header.at-title {
        margin-bottom: 45px;
      }
    `)}
  }
`;

export const ContentWrapper = styled.div`
  max-width: ${({ theme, maxWidth }) => theme.layout[maxWidth] || theme.layout.globalMaxWidth}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  order: 2;

  ${VideoContainer} {
    margin-top: -100px;

    h2 {
      line-height: 1.36;
      margin-bottom: 10px;
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection || 'column'};
    align-items: flex-start;

    ${VideoContainer} {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 22px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    order: 0;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    margin: 0px auto ${({ type }) => (type === 'keyaction' ? '-50px' : '0px')};

    ${VideoContainer} {
      margin-top: -150px;
    }
    ${VideoCaption} {
      padding: 45px 60px 0px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    order: 0;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    margin: 0px auto;

    ${VideoContainer} {
      margin-top: -150px;
    }
    ${VideoCaption} {
      padding: 45px 60px 0px;
    }
  `)}
`;

export const IssuesContentWrapper = styled.div`
  display: block;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: row;

    p {
      font-size: 24px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    p {
      font-size: 26px;
    }
  `)}
`;

export const EndorsementsContentWrapper = styled.div`
  display: block;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Header1}, ${Header2} {
    font-size: 50px;
    margin-bottom: 40px;
    text-align: center;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: calc(100% - 30px);
    margin-right: 30px;
    ${Header1}, ${Header2} {
      text-align: left;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: calc(30% - 45px);
    margin-right: 45px;
  `)}
`;

export const ToolkitContentWrapper = styled.div`
  display: block;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Header1}, ${Header2} {
    font-size: 50px;
    margin-bottom: 50px;
    text-align: center;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: calc(35% - 30px);
    margin-right: 30px;

    ${Header1}, ${Header2} {
      font-size: 48px;
      margin-bottom: 0px;
      text-align: left;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: calc(30% - 45px);
    margin-right: 45px;

    ${Header1}, ${Header2} {
      font-size: ${theme.locale === 'esMX' ? '42px' : '50px'};
      margin-bottom: 0px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    ${Header1}, ${Header2} {
      font-size: 50px;
    }
  `)}
`;

export const EndorsementImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > a {
    max-width: 175px;
    width: 100%;
    flex: 1 1 auto;
    margin-top: 30px;
    font-weight: 700;
    transition: all 0.3s;

    &:first-of-type {
      margin-top: 0px;
    }

    &:hover {
      transform: translateY(-5%);

      span {
        color: ${({ theme }) => theme.palette.secondary};
      }
    }

    span {
      display: block;
      font-size: 14px;
      line-height: 1.2;
      text-align: center;
      padding-top: 5px;
      color: ${({ theme }) => theme.palette.primary};
      transition: color 0.3s;
      letter-spacing: 0.3px;
    }
  }

  .endorsement-logo {
    height: 40%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    flex: 1 1 auto;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 100%;
    flex-direction: row;

    > a {
      max-width: calc(25% - 22.5px);
      margin-left: 30px;
      flex: 1 1 auto;
      margin-top: 0px;

      &:first-of-type {
        margin-left: 0px;
      }
    }

    .endorsement-logo {
      height: 40%;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      flex: 1 1 auto;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 70%;
    > a {
      max-width: calc(25% - 32.5px);
      margin-left: 40px;

      &:first-of-type {
        margin-left: 0px;
      }

      span {
        font-size: 14px;
        line-height: 1.2;
        letter-spacing: 0.1px
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    > a {
      span {
        letter-spacing: 0.3px
      }
    }
  `)}
`;

export const ToolkitImageWrapper = styled.div`
  opacity: 0;
  .toolkit-image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: all 0.6s;
  }

  ${({ isImgInView }) => isImgInView && css`
    animation: ${fadeInSlide('25px')};
    animation-duration: 0.5s;
    animation-delay: ${({ index }) => `${(index + 1) * 0.2}s`};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    .toolkit-image {
      opacity: 1;
    }
  `}
`;

export const VideoWrapper = styled.div`
  opacity: 0;
  width: 100%;
  ${({ isInView }) => isInView && css`
    animation: ${fadeInSlide('75px')};
    animation-duration: 0.6s;
    animation-delay: ${({ index }) => `${(index + 1) * 0.2}s`};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  `}
`;

export const ToolkitImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ToolkitImageWrapper} {
    max-width: 225px;
    width: 100%;
    flex: 1 1 auto;
    margin-top: 30px;

    :first-of-type {
      margin-top: 0px;
    }
  }

  .toolkit-image {
    height: 225px;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    flex: 1 1 auto;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-5%);
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 65%;
    flex-direction: row;
    margin-top: 10px;

   ${ToolkitImageWrapper} {
      max-width: calc(33% - 20px);
      margin-left: 30px;
      flex: 1 1 auto;
      margin-top: 0px;

      &:first-of-type {
        margin-left: 0px;
      }
    }

    .toolkit-image {
      height: 40%;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      flex: 1 1 auto;
      transition: all 0.3s;
      &:hover {
        transform: translateY(-5%);
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 70%;

    ${ToolkitImageWrapper} {
      max-width: calc(33% - 26.7px);
      margin-left: 40px;

      &:first-of-type {
        margin-left: 0px;
      }
    }
  `)}
`;

export const Text = styled.span`
  display: inline;
`;

export const LastWord = styled.span`
  display: inline-block;
  padding-right: 27px;
`;

export const AllBioLink = styled.p`
  font-size: 22px;
  color: ${({ theme }) => theme.palette.primaryHighlight};
  line-height: 1.1;
  margin-top: 30px;
  display: block;
  margin-bottom: 0px;

  a {
    font-weight: 700;
    display: block;
    transition: color 0.3s;
    text-align: left;
    justify-content: flex-start;

    svg {
      position: relative;
      top: 7px;
      margin-left: -25px;
      transition: transform 0.3s;
      polygon {
        transition: fill 0.3s;
      }
    }

    ${LastWord} {
      padding-right: 35px;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondary};

      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.secondary};
        }
      }
    }

    &:after {
      bottom: 5px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 25px;
    margin-bottom: 12px;

    a svg {
      top: 5px;
    }
  `)}
`;

export const AllIssuesLink = styled.p`
  display: block;
  margin-bottom: 0px;
  margin-top: 50px;
  width: 100%;
  display: ${({ mobile }) => (mobile ? 'block' : 'none')};

  a {
    transition: all 0.3s;
    width: 100%;
    padding: 13px 20px 16px 30px;
    letter-spacing: 0.14em;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: fit-content;
    margin-top: 40px;
    display: ${({ mobile }) => (!mobile ? 'block' : 'none')};

    a {
      padding: 13px 40px 16px 40px;
      letter-spacing: 0.16em;
    }
  `)}
`;

export const IssuesList = styled.ul`
  width: 100%;
  margin-top: 20px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    max-width: 40%;
    margin-left: 25px;
    margin-top: 10px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    max-width: 30%;
    margin-left: 25px;
  `)}
`;

export const IssueLink = styled.li`
  margin-bottom: 20px;
  line-height: 1.6;

  a {
    color: ${({ theme }) => theme.palette.primaryHighlight};
    font-weight: 700;
    line-height: 1.6;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: color 0.3s;
    font-size: 18px;

    svg {
      margin-left: 5px;
      min-width: 20px;
      transition: transform 0.3s;
      position: relative;
      top: 8px;
      polygon {
        transform: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primary};

      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.primary};
        }
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ActionCardWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;
  `)}
`;

export const KeyActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  padding-top: 0px;
  opacity: 0;
  ${({ isImgInView }) => isImgInView && css`
    animation: ${fadeInSlide('100px')};
    animation-duration: 0.7s;
    animation-delay: 0s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    .collage-image {
      opacity: 1;
    }
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding-top: 60px;
    padding-bottom: 20px;
    padding-right: 20px;
    width: 40%;
    min-width: 40%;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    padding-top: 100px;
    padding-bottom: 80px;
  `)}
`;

export const BioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Header2}, ${Header1} {
    font-size: 50px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: calc(50% - 40px);
    min-width: calc(50% - 40px);
    margin-right: 40px;

    p {
      font-size: 24px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 40%;
    min-width: 40%;
    margin-right: 0px;

    p {
      font-size: 26px;
    }
  `)}
`;

export const ActionCard = styled.li`
  width: 100%;
  margin-bottom: 50px;
  line-height: 1.3;

  &:last-of-type {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: calc(33% - 20px);
    margin-right: 30px;
    margin-bottom: 0px;

    &:last-of-type {
      margin-right: 0px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    width: calc(33% - 40px);
    margin-right: 60px;
    margin-bottom: 0px;

    &:last-of-type {
      margin-right: 0px;
    }
  `)}
`;

export const CollageWrapper = styled.div`
  width: calc(100% + 64px);
  margin-left: -32px;
  position: relative;
  margin-top: 60px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 50vw;
    max-width: 50vw;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: -20px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: -40px;
    width: calc(((100vw - 1200px) / 2) + 664px);
    max-width: calc(((100vw - 1200px) / 2) + 664px);
    ${({ minHeight }) => minHeight && (css` min-height: ${minHeight}px;`)}
    left: 4.5vw;
    right: 0px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    min-width: 840px;
    max-width: 840px;
    min-height: ${({ aspectRatio }) => `${840 / aspectRatio}px`};
    max-height: ${({ aspectRatio }) => `${840 / aspectRatio}px`};
    left: 5%;
  `)}
`;

export const CollageImage = styled.div`
  width: 100vw;
  height: ${({ aspectRatio }) => `calc(100vw / ${aspectRatio})`};
  display: block;
  position: ${({ index }) => (index === 0 ? 'relative' : 'absolute')};
  opacity: 0;
  top: 0px;

  .collage-image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: all 0.8s;
  }

  ${({ isImgInView }) => isImgInView && css`
    animation: ${fadeInSlide('50px')};
    animation-duration: 0.7s;
    animation-delay: ${({ index }) => `${(index + 1) * 0.15}s`};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    .collage-image {
      opacity: 1;
    }
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 50vw;
    height: ${({ aspectRatio }) => `calc(50vw / ${aspectRatio})`};
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 100%;
    height: 100%;
  `)}
`;

export const LeftContentWrapper = styled.div`
  display: block;
  display: flex;
  flex-direction: column;
  width: 100%;

  p:first-of-type {
    margin-bottom: 0px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 60%;
    max-width: 60%;
    margin-right: 25px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 70%;
    max-width: 70%;
    margin-right: 35px;
  `)}
`;

export const KeyActionMedia = styled.div`
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-bottom: 50px;

  .key-action-image {
    opacity: 0;
    transition: opacity: 0.6s;
    width: 100%;
    max-height: 350px;
    img {
      object-position: 50% 20% !important;
    }
  }

  ${({ isImgInView }) => isImgInView && css`
    animation: ${fadeInSlide('100px')};
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    .key-action-image {
      opacity: 1;
    }
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    .gatsby-image-wrapper {
      max-height: 500px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    display: flex;
    flex-direction: column;
    width: calc(((100vw - 1200px) / 2) + 664px);
    min-height: 800px;
    max-width: 800px;
    justify-content: flex-end;
    position: absolute;
    left: calc(((100vw - 1200px) / 2) + 572px);
    bottom: -50px;
    z-index: 1;
    margin-bottom: 0px;

    .gatsby-image-wrapper {
      width: 100%;
      max-height: 100%;
      min-height: 800px;
      object-position: 30% 20% !important;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    min-width: 840px;
    max-width: 840px;
    bottom: -75px;
  `)}
`;

export const DonateInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  opacity: 0;
  animation: ${fadeInSlide('100px')};
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const DonationSubtitle = styled.p`
  font-size: 22px;
  line-height: 1.36;
  color: ${({ theme }) => theme.palette.white};
  margin-bottom: 25px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-bottom: 50px;
  `)}
`;

export const DonateButtonsWrapper = styled.ul`
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    justify-content: space-between;
    margin-top: 0px;
    display: ${({ mobile }) => (!mobile ? 'flex' : 'none')};
  `)}
`;

export const DonateButton = styled.li`
  display: flex;
  width: calc(50% - 10px);
  max-width: calc(50% - 10px);
  margin-bottom: 20px;

  a {
    letter-spacing: 0.18em;
    font-size: 20px;
    background-color: ${({ theme }) => theme.palette.highlight};
    color: ${({ theme }) => theme.palette.primary};

    &:hover {
      color: ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
    }
  }
`;

export const Disclaimer = styled.p`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.palette.white};
  margin-bottom: 0px;
`;

export const FadingWrapper = styled.div`
  ${fade('0.5s')}
`;
