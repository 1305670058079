import React from 'react';
import theme from '../theme';

const Triangle = ({
  width = '25px',
  height = '48px',
  fillColor = 'primary',
}) => (
  <svg width={width} height={height} viewBox="0 0 25 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.1449 24.1612L0.15866 47.3397L0.026792 0.83504L24.1449 24.1612Z" fill={theme.palette[fillColor]} />
  </svg>
);

export default Triangle;
