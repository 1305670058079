import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import FormWrapper from './event-list.styles';

const EventList = (props) => {
  const {
    formTheme = 'Light',
    databag = 'nobody',
    formUrl = '',
    addClass = '',
    size = 'Large',
  } = props;

  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!window.nvtag) {
      setRender(true);
    } else {
      // window.location.reload();
    }
  }, []);

  return (
    <FormWrapper>
      {render && (
        <Helmet>
          <script type="text/javascript" src="https://assets.targetedaction.net/embed.js" />
          <script type="text/javascript" src="https://assets.targetedaction.net/embed.js" crossOrigin="anonymous" />
        </Helmet>
      )}
      <div
        key={formUrl}
        className={`oa-page-embed oa-page-embed--${formTheme} oa-page-embed--${size} ${addClass}`}
        data-formdef-endpoint="//formdefs.s3.amazonaws.com/api.myngp.com"
        data-databag={databag}
        data-inline-errors
        data-labels="above"
        data-mobile-autofocus={false}
        data-page-url={formUrl}
      />
    </FormWrapper>
  );
};

export default EventList;
