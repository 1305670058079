import styled from 'styled-components';
import { rgba } from 'polished';
import { a11yOnly } from '@styles';

const FormWrapper = styled.div`
  .at.ngp-form {
    font-family: ${({ theme }) => theme.font};
    max-width: calc(100% + 1.25rem);
    min-width: calc(100% + 1.25rem);
    width: calc(100% + 1.25rem);

    &.submitted {
      display: none !important;
    }

    fieldset.at-fieldset {
      padding: 0px 0.625rem;
      margin-left: -1.25rem;
      margin-right: 0px;
    }

    .HeaderHtml {
      padding: 0px;
      & p > span {
        letter-spacing: 0.16em;
      }
    }

    legend.at-legend {
      ${a11yOnly}
      font-family: ${({ theme }) => theme.font};
    }

    &.title-only {
      margin-bottom: 0px !important;
    }

    p.mobile-disclaimer {
      font-size: 12px;
      line-height: 1.3;
      opacity: 0.8;
    }

    @media (min-width: 768px) {
      p.mobile-disclaimer {
        font-size: 14px;
      }
    }

    .AdditionalInformation legend.at-legend {
      display: block;
      position: relative;
      width: 100%;
      clip: unset;
      height: fit-content;
      overflow: visible;
      padding: 32px 0px 16px;
      position: relative;
      width: 100%;
      margin: 0 0.625rem;
    }

    .ngp-spinner {
      opacity: 0;
    }

    .at-text,
    select,
    input,
    header.at-title {
      font-family: ${({ theme }) => theme.font};
    }

    label.at-check {
      margin-right: 5px;
    }

    label.at-text,
    label.at-select,
    label.at-area {
      font-weight: 700;
      line-height: 1.3;

      small {
        font-weight: 400;
      }
    };

    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="color"],
    input[type="file"],
    input[type="checkbox"],
    input[type="radio"],
    textarea,
    select {
      border-radius: 0px;
      border-width: 2px;
      margin-top: 5px;
      transition: background-color 0.3s;
    }

    input[type="tel"] {
      margin-top: 5px !important;
    }

    input[type="checkbox"]+span:before {
      border-radius: 0px;
      border-width: 2px;
    }

    .at-text.error {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"] {
        border-width: 4px;
      }
    }

    header.at-title {
      line-height: 1.37;
      font-weight: 700;
      padding: 0px;
      text-align: left;
    }

    footer, .at-banner {
      display: none;
    }

    .UpdateMyProfile label>span>span:after {
      display: none;
    }

    .at-row.UpdateMyProfile {
      margin-bottom: 15px;
      min-height: 27px;
    }

    input[type="submit"].at-submit {
      letter-spacing: 0.16em;
    }

    .at-checkbox-title {
      display: block;
      min-height: 25px;
      display: flex;
      align-items: center;
      line-height: 1.2;
    }

    &.ngp-form--HideTitle {
      header.at-title {
        clip: rect(0 0 0 0) !important;
        clip-path: inset(50%)!important;
        height: 1px !important;
        overflow: hidden !important;
        position: absolute !important;
        white-space: nowrap !important;
        width: 1px !important;
      }
    }

    &.ngp-form--HideAddInfoTitle {
      .AdditionalInformation legend.at-legend {
        clip: rect(0 0 0 0) !important;
        clip-path: inset(50%)!important;
        height: 1px !important;
        overflow: hidden !important;
        position: absolute !important;
        white-space: nowrap !important;
        width: 1px !important;
      }
    }

    &.ngp-form--Small {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select {
        height: 35px;
        font-size: 14px;
      }

      .content.thankYou {
        p {
          font-size: 20px;
        }
      }

      select {
        padding: 0px 20px 0px 8px;
        line-height: 2;
      }

      header {
        font-size: 22px;
        margin-bottom: 15px;
      }

      .at-checkbox-title {
        margin-left: 5px;
        display: block;
      }

      .at-legend {
        font-size: 18px;
      }
    }

    &.ngp-form--Large {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select {
        height: 50px;
        font-size: 22px;
      }

      .at-markup p {
        margin-bottom: 6px;
      }

      textarea {
        min-height: 150px;
      }

      label.at-text,
      label.at-select,
      label.at-area {
        font-size: 16px;
      }

      label.at-area {
        margin-bottom: 20px;
      }

      header.at-title {
        font-size: 32px;
        margin-bottom: 25px;
        font-weight: 700;
      }

      .content.thankYou {
        p {
          font-size: 24px;
        }
      }

      input[type="checkbox"]+span:before {
        height: 25px;
        width: 25px;
      }

      input[type="checkbox"]+span:after {
        font-size: 18px;
        left: 6px;
        top: 6px;
      }

      .updateMyProfileSection .text {
        font-size: 16px;
        margin-left: 7px;
        top: 2px;
        position: relative;
        display: block;
        line-height: 1;
      }

      .at-checkbox-title {
        margin-left: 10px;
        font-size: 16px;
      }

      .at-form-submit {
        padding-left: 0px;
        input[type="submit"].at-submit {
          height: 52px;
          margin-top: 0px;
          font-size: 18px;
          padding: 13px 25px 16px;
        }
      }

      .error small.error {
        font-size: 14px;
        margin-bottom: 6px;
        font-weight: 700;
        padding-left: 0px;
      }

      .at-legend {
        font-size: 22px;
      }
    }

    input[type="checkbox"]+span {
      font-family: ${({ theme }) => theme.font};
      color: ${({ theme }) => theme.palette.primary};
    }

    input[type="checkbox"]:checked+span:before {
      background-color: transparent;
    }

    input[type="checkbox"]:hover+span:before {
      border-color: ${({ theme }) => theme.palette.primaryHighlight};
    }

    &.ngp-form--Light {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select {
        border-color: ${({ theme }) => theme.palette.primaryHighlight};
        background-color: transparent;
        color: ${({ theme }) => theme.palette.primary};

        &:focus {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      .at-text.error {
        input[type="text"],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        input[type="color"],
        input[type="file"],
        input[type="checkbox"],
        input[type="radio"] {
          border-color: ${({ theme }) => theme.palette.primaryHighlight};
        }
        small.error {
          color: ${({ theme }) => theme.palette.primaryHighlight};
        }
      }

      input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.lightPrimary};
      }

      .error input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.primaryHighlight};
      }

      .at-checkbox-title-container::before {
        border-color: ${({ theme }) => theme.palette.primaryHighlight};
        background-color: transparent;
        color: ${({ theme }) => theme.palette.primary};

        &:focus {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      header {
        color: ${({ theme }) => theme.palette.primary};
      }

      .at-text,
      .at-select,
      input,
      .at-checkbox-title {
        color: ${({ theme }) => theme.palette.primary};

        & small {
          color: ${({ theme }) => theme.palette.primary};
        }
      }

      .checkbox-list-label {
        color: ${({ theme }) => theme.palette.primary};
        small {
          color: ${({ theme }) => theme.palette.primary};
        }
      }

      input:-internal-autofill-selected {
        background-color: ${({ theme }) => theme.palette.lightPrimary} !important;
      }

      input[type="checkbox"] {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.primaryHighlight};
          background-color: transparent;
        }

        &:hover+span:before,
        &:focus+span:before {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      input[type="checkbox"]:checked {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.primaryHighlight};
          background-color: transparent;
        }

        &:hover+span:before,
        &:focus+span:before  {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      input[type="checkbox"]:checked+span:after {
        color: ${({ theme }) => theme.palette.primaryHighlight};
      }

      input[type="checkbox"]:hover+span:before,
      input[type="checkbox"]:focus+span:before {
        border-color: ${({ theme }) => theme.palette.primaryHighlight};
      }

      p, legend, label {
        color: ${({ theme }) => theme.palette.primary};
        small {
          color: ${({ theme }) => theme.palette.primary};
        }
      }

      .HeaderHtml p > span {
        color: ${({ theme }) => theme.palette.primaryHighlight} !important;
      }

      .at-form-submit input[type="submit"] {
        background-color: ${({ theme }) => theme.palette.highlight};
        color: ${({ theme }) => theme.palette.primary};

        &:hover {
          background-color: ${({ theme }) => theme.palette.secondaryHighlight};
        }
      }
    }

    &.ngp-form--Dark {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select {
        border-color: ${({ theme }) => theme.palette.lightPrimary};
        background-color: ${({ theme }) => theme.palette.primary};
        border-width: 2px;
        color: ${({ theme }) => theme.palette.white};

        &:focus {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
          color: ${({ theme }) => theme.palette.primary};
        }
      }

      .at-text.error {
        input[type="text"],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        input[type="color"],
        input[type="file"],
        input[type="checkbox"],
        input[type="radio"] {
          border-color: ${({ theme }) => theme.palette.highlight};
        }
        small.error {
          color: ${({ theme }) => theme.palette.highlight};
        }
      }

      input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.lightPrimary};
      }

      .error input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.highlight};
      }

      header.at-title {
        color: ${({ theme }) => theme.palette.white};
      }

      .at-checkbox-title-container::before {
        border-color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.lighterPrimary};
      }

      .at-text,
      .at-select,
      input,
      .at-checkbox-title {
        color: ${({ theme }) => theme.palette.white};

        & small {
          color: ${({ theme }) => theme.palette.white};
        }
      }

      input:-internal-autofill-selected {
        background-color: ${({ theme }) => theme.palette.lightPrimary} !important;
      }

      .checkbox-list-label {
        color: ${({ theme }) => theme.palette.white};

        small {
          color: ${({ theme }) => theme.palette.white};
        }
      }

      input[type="checkbox"] {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.lightPrimary};
          background-color: ${({ theme }) => theme.palette.primary};
          border-width: 2px;
        }

        &:hover+span:before,
        &:focus+span:before {
          background-color: ${({ theme }) => rgba(theme.palette.primary, 0.7)};
        }
      }

      input[type="checkbox"]:checked {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.lightPrimary};
          background-color: ${({ theme }) => theme.palette.primary};
        }

        &:hover+span:before,
        &:focus+span:before  {
          background-color: ${({ theme }) => rgba(theme.palette.primary, 0.7)};
        }
      }

      input[type="checkbox"]:checked+span:after {
        color: ${({ theme }) => theme.palette.lightPrimary};
      }

      input[type="checkbox"]:hover+span:before,
      input[type="checkbox"]:focus+span:before {
        border-color: ${({ theme }) => theme.palette.lightPrimary};
      }

      .updateMyProfileSection .text {
        color: ${({ theme }) => theme.palette.white};
        display: block;
      }

      p, legend {
        color: ${({ theme }) => theme.palette.white};
        small {
          color: ${({ theme }) => theme.palette.white};
        }
      }

      .HeaderHtml p > span {
        color: ${({ theme }) => theme.palette.white} !important;
      }

      .at-form-submit input[type="submit"] {
        border-color: ${({ theme }) => theme.palette.highlight};
        background-color: ${({ theme }) => theme.palette.highlight};
        color: ${({ theme }) => theme.palette.primary};

        &:hover {
          border-color: ${({ theme }) => theme.palette.secondaryHighlight};
          background-color: ${({ theme }) => theme.palette.secondaryHighlight};
        }
      }
    }

    .at-inner {
      background-color: transparent;
    }

    .at-form-submit {
      float: left;

      input[type="submit"] {
        border-radius: ${({ theme }) => theme.button.radius};
        cursor: pointer;
        transition: all 0.3s;
        text-transform: uppercase;
        font-weight: 700;
        height: 36px;
        border-width: 0px;
        width: fit-content;
        padding: 0px 15px;
        font-size: 1.2rem;
        margin-left: 0px;
      }
    }
  }
`;

export default FormWrapper;
