import React from 'react';
import theme from '../theme';

const Arrow = ({
  width = '29px',
  height = '26px',
  strokeColor = 'primary',
  strokeWidth = 0,
}) => (
  <svg width={width} height={height} viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <polygon
      points="17.7,25.4 13.9,22.1 19.7,15.6 0,15.6 0,10.6 19.7,10.6 13.9,4.1 17.6,0.8 28.6,13.1 "
      stroke={theme.palette[strokeColor]}
      fill={theme.palette[strokeColor]}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Arrow;
