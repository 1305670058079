import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Text = styled.span`
  display: inline;
`;

export const LastWord = styled.span`
  display: inline-block;
  padding-right: 27px;
`;

export const MainSectionLink = styled.p`
  font-size: 22px;
  color: ${({ theme }) => theme.palette.primaryHighlight};
  line-height: 1.1;
  margin-top: 60px;
  margin-bottom: 0px;
  display: ${({ mobile }) => (mobile ? 'block' : 'none')};

  a {
    font-weight: 700;
    display: block;
    transition: color 0.3s;
    text-align: center;

    svg {
      position: relative;
      top: 2px;
      margin-left: -20px;
      transition: transform 0.3s;
      polygon {
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondary};

      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.secondary};
        }
      }
    }

    &:after {
      bottom: 5px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    display: ${({ tablet }) => (tablet ? 'block' : 'none')};
    margin-top: 25px;
    margin-bottom: 12px;

    a {
      text-align: left;
      justify-content: flex-start;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    display: ${({ desktop }) => (desktop ? 'block' : 'none')};
  `)}
`;
