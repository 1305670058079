import { createGlobalStyle } from 'styled-components';
import Font from './font';
import theme from './theme';
import wuiconsWoff from './fonts/wuicons.woff';
import wuiconsTtf from './fonts/wuicons.ttf';

const GlobalStyles = createGlobalStyle`
  ${Font}
  html {
    overflow-y: auto;
    scroll-behavior: smooth;

    &.overlay-active {
      overflow: hidden;
    }
  }

  body {
    margin: 0;
    min-height: 100vh;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-family: ${theme.font};
    font-feature-settings: "ss01", "dlig";
  }

  * {
    box-sizing: border-box;
  }

  p, ul, a, li, ol, span, h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }

  button {
    background-color: transparent         ;
    border: none;
    font-family: ${theme.font};
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0px 0px 22px;
  }

  @font-face {
    font-family: "wuicons";
    font-style: normal;
    font-weight: normal;
    src: local("wuicons"), local("wuicons"), url(${wuiconsTtf}) format("ttf"), url(${wuiconsWoff}) format("woff");
  }
`;

export default GlobalStyles;
