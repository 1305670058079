import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, LinkButton } from '@components';
import {
  Header1, Content, ContentGutter,
} from '@styles';
import { getKeyValue, compileRichText } from '@utils';
import {
  MEDIUM, LIGHT, LARGE,
} from '@utils/vars';
import {
  Wrapper, ErrorImage, TextWrapper, ActionItemWrapper,
} from './404.styles';

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          social {
            twitter
          }
        }
      }
      page: contentfulPage(pageUrl: { eq: "/404" }) {
        ...PageMetadata
        contentEntry {
          ... on ContentfulErrorPage {
            id
            title
            subtitle {
              raw
            }
            errorMedia {
              description
              id
              file {
                url
              }
            }
            primaryAction {
              title
              path
            }
            secondaryAction {
              title
              path
            }
          }
        }
      }
      global: contentfulGlobalSettings(language: {eq: "enUS"}) {
        navigation {
          items {
            title
            path
            subitems {
              title
              path
            }
          }
          homepage {
            title
            path
          }
          primaryAction {
            title
            path
          }
          secondaryAction {
            title
            path
          }
          languages {
            code
            display
          }
        }
        formLabelLanguageOverrides {
          FirstName
          LastName
          PostalCode
          EmailAddress
          submitForm
          thankYou
          title
        }
        footerDisclaimer
        footerCopyright
        footerSocialLinks {
          icon
          path
        }
        footerPrimaryLinks {
          title
          path
          type
        }
        footerSecondaryLinks {
          title
          path
        }
        footerForm {
          mobileAutofocus
          formUrl
          formTheme
          formSize
          fastactionNoLogin
          databag
          contentful_id
          internalFormTitle
          hideFormTitle
          formLevelLabelLanguageOverrides {
            FirstName
            LastName
            PostalCode
            EmailAddress
            submitForm
            thankYou
          }
        }
        footerAddress
        footerEmails
        footerPhoneNumber
        footerDonateByMail {
          title
          path
          type
        }
      }
    }
  `);

  const locale = 'enUs';
  const { title: siteTitle, social } = getKeyValue(data, 'site.siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash,
  } = getKeyValue(data, 'page') || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    title, subtitle, errorMedia, primaryAction, secondaryAction,
  } = contentEntry || {};

  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer, footerEmails,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = getKeyValue(data, 'global') || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  const navProps = {
    ...navigation,
    mobNavTheme: LIGHT,
  };

  return (
    <Layout
      navigation={navProps}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={LIGHT}
    >
      <div>
        <ContentGutter>
          <Content>
            <Wrapper>
              {title && <Header1 textTheme="primary" size={MEDIUM}>{title}</Header1>}
              <TextWrapper>
                <div>
                  {subtitle && (
                  <React.Fragment>
                    {compileRichText({ textTheme: 'primary', size: MEDIUM, locale })(subtitle)}
                  </React.Fragment>
                  )}
                </div>
                {errorMedia && errorMedia.file && (
                <ErrorImage
                  alt={errorMedia.description}
                  src={errorMedia.file.url}
                  className="desktop-image"
                />
                )}
                <ActionItemWrapper>
                  {primaryAction && (
                  <LinkButton
                    href={primaryAction.path}
                    size={LARGE}
                    buttonTheme="highlight"
                    buttonHoverTheme="secondaryHighlight"
                    textTheme="primary"
                    textHoverTheme="primary"
                  >
                    {primaryAction.title}
                  </LinkButton>
                  )}
                  {secondaryAction && (
                  <LinkButton
                    href={secondaryAction.path}
                    size={LARGE}
                    buttonTheme="primaryHighlight"
                    buttonHoverTheme="secondaryHighlight"
                    textTheme="white"
                    textHoverTheme="primary"
                  >
                    {secondaryAction.title}
                  </LinkButton>
                  )}
                </ActionItemWrapper>
              </TextWrapper>
            </Wrapper>
          </Content>
        </ContentGutter>
      </div>
    </Layout>
  );
};

export default NotFound;
