import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';
import {
  XSMALL, SMALL, MEDIUM, LARGE, XLARGE,
} from '@utils/vars';
import { switchBasedOnScheme } from '@utils';

export const Header1 = styled.h1`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 60px;
  letter-spacing: 0.3px;
  line-height: 1.08;
  margin: 0px 0px 24px;

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 40px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
    [MEDIUM]: css`
      font-size: 38px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
        font-size: 44px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
    [LARGE]: css`
      font-size: 72px;
    `,
    [XLARGE]: css`
      font-size: 38px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
        font-size: 44px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 60px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 80px;
        margin: 0px 0px 56px;
      `)}
    `,
  })}
`;

export const GeneralHeader1 = styled.h1`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 60px;
  letter-spacing: 0.3px;
  line-height: 1.08;
  margin: 48px 0px 24px;

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 40px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
    [MEDIUM]: css`
      font-size: 38px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
        font-size: 44px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
    [LARGE]: css`
      font-size: 72px;
    `,
    [XLARGE]: css`
      font-size: 38px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
        font-size: 44px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 60px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 80px;
        margin: 80px 0px 56px;
      `)}
    `,
  })}
`;

export const Header2 = styled.h2`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 42px;
  line-height: 1.1;
  margin: 0px 0px 20px;

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 36px;
    `,
    [MEDIUM]: css`
      font-size: 30px;
      margin: 0px 0px 20px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 42px;
        margin: 0px 0px 20px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
    [LARGE]: css`
      font-size: 52px;
      margin: 0px 0px 20px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 56px;
        margin: 0px 0px 22px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 60px;
        margin: 0px 0px 25px;
      `)}
    `,
    [XLARGE]: css`
      font-size: 30px;
      margin: 0px 0px 20px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 50px;
        margin: 0px 0px 22px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 80px;
        margin: 0px 0px 25px;
      `)}
    `,
  })}
`;

export const GeneralHeader2 = styled.h2`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 42px;
  line-height: 1.1;
  margin: 40px 0px 20px;

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 36px;
      margin: 50px 0px 20px;
    `,
    [MEDIUM]: css`
      font-size: 30px;
      margin: 40px 0px 20px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 42px;
        margin: 50px 0px 20px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
    [LARGE]: css`
      font-size: 52px;
      margin: 40px 0px 20px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 56px;
        margin: 44px 0px 22px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 60px;
        margin: 50px 0px 25px;
      `)}
    `,
    [XLARGE]: css`
      font-size: 30px;
      margin: 40px 0px 20px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 50px;
        margin: 44px 0px 22px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 80px;
        margin: 50px 0px 25px;
      `)}
    `,
  })}
`;

export const Header3 = styled.h3`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  margin: 0px 0px 18px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    line-height: 1.2;
  `)}

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 34px;
      `)}
    `,
    [XLARGE]: css`
      margin: 0px 0px 20px;
      font-size: 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 40px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
  })}
`;

export const GeneralHeader3 = styled.h3`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  margin: 36px 0px 18px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    line-height: 1.2;
  `)}

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 34px;
      `)}
    `,
    [XLARGE]: css`
      margin: 40px 0px 20px;
      font-size: 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 40px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 50px;
      `)}
    `,
  })}
`;

export const Header4 = styled.h4`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 24px;
  line-height: 1.2;
  margin: 0px 0px 14px;
`;

export const GeneralHeader4 = styled.h4`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 24px;
  line-height: 1.2;
  margin: 28px 0px 14px;
`;

export const Header5 = styled.h5`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.86px;
  line-height: 1.17;
  margin: 0px 0px 12px;
`;

export const GeneralHeader5 = styled.h5`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.86px;
  line-height: 1.17;
  margin: 24px 0px 12px;
`;

export const Header6 = styled.h6`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  margin: 0px 0px 10px;
  font-size: 18px;
`;

export const GeneralHeader6 = styled.h6`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  margin: 20px 0px 10px;
  font-size: 18px;
`;

export const Paragraph = styled.p`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 18px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-bottom: 22px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  `)}

  &:last-of-type {
    margin-bottom: 0px;
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
      font-size: 16px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 18px;
      `)}
    `,
    [SMALL]: css`
      font-size: 18px;
      line-height: 1.8;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        line-height: 1.8;
        font-size: 22px;
      `)}
    `,
    [MEDIUM]: css`
      font-size: 20px;
      line-height: 1.7;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 24px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 26px;
      `)}
    `,
    [LARGE]: css`
      font-size: 22px;
      line-height: 1.7;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 26px;
        line-height: 1.6;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 28px;
        line-height: 1.6;
      `)}
    `,
    [XLARGE]: css`
      font-size: 22px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 28px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 32px;
      `)}
    `,
  })}
`;

export const BlockQuote = styled.blockquote`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  font-size: 18px;
  line-height: 1.7;
  position: relative;
  z-index: 2;
  margin-right: 0px;

  letter-spacing: 0.02em;
  > svg {
    position: absolute;
    top: -10px;
    left: -60px;
    z-index: -1;
    width: 88.5px;
    height: 78.75px
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin: 90px 0px 60px 60px;
    > svg {
      position: absolute;
      top: -40px;
      left: -60px;
      z-index: -1;
      width: 110px;
      height: 105px
    }
  `)}

  ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
      font-size: 16px;
    `,
    [SMALL]: css`
      font-size: 18px;
      line-height: 1.8;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        line-height: 1.6;
        font-size: 22px;
      `)}
    `,
    [MEDIUM]: css`
      font-size: 24px;
      margin-bottom: 40px;
    `,
    [LARGE]: css`
      font-size: 22px;
      line-height: 1.7;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 28px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 30px;
      `)}
    `,
    [XLARGE]: css`
      font-size: 22px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 28px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 32px;
      `)}
    `,
  })}
`;

export const Cite = styled.cite`
  font-size: 22px;
  display: block;
  text-align: left;
  margin-top: 24px;
  width: 100%;
  font-weight: 700;

  > svg {
    display: block;
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
      font-size: 14px;
    `,
    [SMALL]: css`
      font-size: 20px;
    `,
    [MEDIUM]: css`
      font-size: 22px;
    `,
    [LARGE]: css`
      font-size: 24px;
    `,
    [XLARGE]: css`
      font-size: 24px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 26px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 28px;
      `)}
    `,
  })}
`;

export const ListItem = styled.li`
  margin: 0px 0px 12px;
  line-height: 1.4;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
     margin: 0px 0px 14px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
     margin: 0px 0px 16px;
  `)}

  p {
    display: inline-block;
    margin-bottom: 0px;
    line-height: 1.4;
  }

  p:first-of-type {
    display: inline;
  }
`;

export const UnorderedList = styled.ul`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  list-style: none inside none;
  margin-inline-start: 20px;
  margin: 20px 0px 20px 20px;

  ul {
    margin: 20px 0px 20px 20px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin: 20px 0px 40px 30px;

    ul {
      margin: 20px 0px 20px 30px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 30px 0px 40px 80px;
  `)}

  > ${ListItem} {
    display: list-item;
    list-style-type: none;
    position: relative;

    &:before {
      background-image: ${({ textTheme }) => (textTheme === 'white' ? 'url(\'/dotlight.svg\')' : 'url(\'/dot.svg\')')};
      background-repeat: no-repeat;
      background-position: left center;
      background-size: cover;
      content: "";
      position: absolute;
      top: 13px;
      width: 8px;
      height: 8.4px;
      line-height: 1.6;
      font-size: inherit;
      right: calc(100% + 15px);

    ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
      top: 13px;
    `,
    [SMALL]: css`
      top: 14px;
    `,
    [MEDIUM]: css`
      top: 14px;
      width: 10px;
      height: 10.5px;
    `,
    [LARGE]: css`
      top: 14px;
      width: 10px;
      height: 10.5px;
    `,
    [XLARGE]: css`
      top: 18px;
      width6 10px;
      height: 10.5px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        top: 20px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        top: 22px;
      `)}
    `,
  })}
    }
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
      font-size: 16px;

      ul {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    `,
    [SMALL]: css`
      font-size: 22px;

      ul {
        margin-top: 5px;
        margin-bottom: 15px;
      }
    `,
    [MEDIUM]: css`
      font-size: 24px;
    `,
    [LARGE]: css`
      font-size: 28px;
    `,
    [XLARGE]: css`
      font-size: 26px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 28px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 32px;
      `)}
    `,
  })}
`;

export const RecordList = styled.ul`
  list-style: none none none;
  font-size: 30px;
  line-height: 1.2;
`;

export const RecordItem = styled.li`
  padding: 30px 0px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.secondaryHighlight};

  p {
    line-height: 1.3;
  }

  &:last-of-type {
    border-bottom-width: 0px;
    padding-bottom: 0px;
  }

  &:first-of-type {
    padding-top: 0px
  }
`;

export const RecordLinkWrapper = styled.span`
  a {
    font-weight: 700;
    display: inline;
    transition: color 0.3s;

    span {
      padding-right: 28px;
      margin-right: 0px;
    }

    svg {
      left: 0px;
      margin-left: -22px;
      top: 2px;
      position: relative;
      transition: transform 0.3s;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondaryHighlight};

      svg {
        transform: translate3d(20%, -10%, 10px);
      }
    }
  }
`;

export const OrderedList = styled.ol`
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};
  counter-reset: my-counter;
  list-style-type: none;
  margin-inline-start: 20px;
  margin: 20px 0px 20px 40px;
  padding: 0px;

  ol {
    margin: 20px 0px 20px 20px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin: 30px 0px 30px 40px;

    ol {
      margin: 20px 0px 20px 40px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 40px 60px;

    ol {
      margin: 20px 0px 20px 60px;
    }
  `)}

  > ${ListItem} {
    counter-increment: my-counter;
    display: list-item;
    list-style-type: none;
    position: relative;
    margin-left: 0px;

    &:before {
      content: counters(my-counter, ".") ".";
      color:  ${({ theme, textTheme }) => theme.palette[textTheme]};
      font-weight: 700;
      position: absolute;
      right: calc(100% + 15px);
      line-height: 1.5;

    ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
        font-size: 16px;
      `,
    [SMALL]: css`
      font-size: 18px;
    `,
    [MEDIUM]: css`
      font-size: 20px;
    `,
    [LARGE]: css`
      font-size: 22px;
    `,
    [XLARGE]: css`
      font-size: 26px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 28px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 32px;
      `)}
    `,
  })}
    }
    }
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [XSMALL]: css`
      font-size: 16px;
    `,
    [SMALL]: css`
      font-size: 22px;
    `,
    [MEDIUM]: css`
      font-size: 24px;
    `,
    [LARGE]: css`
      font-size: 28px;
    `,
    [XLARGE]: css`
      font-size: 26px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 28px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 32px;
      `)}
    `,
  })}
`;

export const InlineLinkWrapper = styled.span`
  a {
    color: ${({ theme, textTheme }) => (textTheme === 'white' ? theme.palette.white : theme.palette.primaryHighlight)};
    font-weight: 700;
    position: relative;
    flex: 1 1 100%;
    align-self: center;
    text-decoration: none;
    background-image: ${({ theme, textTheme }) => (textTheme === 'white' ? (
    `linear-gradient(${theme.palette.highlight}, ${theme.palette.highlight})`
  ) : (
    `linear-gradient(${theme.palette.primaryHighlight}, ${theme.palette.primaryHighlight})`
  ))};
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0.12em;
    transition: background-size .3s, background-position .3s, padding 0.3s, margin 0.3s;
    padding-bottom: 0.05em;
    margin-bottom: -0.05em;

    &:hover {
      font-weight: 700;
      background-size: 100% 0.15em;
      background-position: 0% 100%;
      padding-bottom: 0em;
      margin-bottom: 0em;
      background-image: ${({ theme, textTheme }) => (textTheme === 'white' ? (
    `linear-gradient(${theme.palette.secondaryHighlight}, ${theme.palette.secondaryHighlight})`
  ) : (
    `linear-gradient(${theme.palette.highlight}, ${theme.palette.highlight})`
  ))};
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: row;
    margin: 25px 0px;
  `)}
`;

export const Column = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 25px 0px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    width: 50%;
    max-width: 50%;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `)}
`;

export const LeftColumn = styled(Column)`

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-right: 20px;
  `)}
`;

export const RightColumn = styled(Column)`

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-left: 20px;
  `)}
`;

export const YouTubeWrapper = styled.div`
  width: 100%;
`;

export const TwitterWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
`;

export const InlineImage = styled.img`
  width 100%;
  height: auto;
  object-fit: cover;
  background-size: cover;
  background-position: center;
`;

export const Bold = styled.strong`
  font-weight: 700;
  color: inherit;
`;

export const Italic = styled.em`
  font-style: italic;
  color: inherit;
`;

export const Underline = styled.span`
  text-decoration: underline;
  color: inherit;
`;

export const ContentGutter = styled.div`
  padding: 0px 32px;
`;

export const Content = styled.div`
  margin: 0px auto 120px;
  max-width: ${({ theme }) => theme.layout.contentMaxWidth}px;
`;

export const IntroWrapper = styled.div`
  margin: 80px auto 50px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin: 80px auto 60px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 80px auto;
  `)}
`;

export const ChildCareMap = styled.iframe`
  width: 100%;
  height: 400px;
  margin: 20px auto;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin: 25px auto;
    height: 500px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 30px auto;
  `)}
`;

export const Headers = [Header1, Header2, Header3, Header4, Header5, Header6];

export const GeneralHeaders = [
  GeneralHeader1,
  GeneralHeader2,
  GeneralHeader3,
  GeneralHeader4,
  GeneralHeader5,
  GeneralHeader6,
];
