import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import FocusTrap from 'focus-trap-react';
import { Form, LinkButton } from '@components';
import { Close } from '@styles/icons';
import { compileRichText, appendQueryString } from '@utils';
import { DISMISS_SPLASH } from '@utils/vars';
import {
  SplashWrapper, Content, Wrapper, ButtonsWrapper,
  Header, Disclaimer, DonateInfo, CloseButton,
} from './splash.styles';

const Splash = (props) => {
  const [closed, setClosed] = useState(true);
  const [closing, setClosing] = useState(false);
  const {
    title, intro, image, donationAsk, donateBarDisclaimer, formAsk, actionLinksAsk,
  } = props;

  const { donationLink, donationButtons, donationButtonsMobile } = donationAsk || {};

  const buttonCount = donationButtons ? donationButtons.length : 0;
  const actionCount = actionLinksAsk ? actionLinksAsk.length : 0;

  const imgFluid = image.fluid;

  const CloseBar = () => {
    if (!closed) {
      setClosing(true);
      setTimeout(() => {
        setClosed(true);
        sessionStorage.setItem(DISMISS_SPLASH, 'true');
      }, 400);
      setTimeout(() => {
        setClosing(false);
      }, 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const page = document.getElementsByTagName('html');
      if (page && page[0]) {
        if (closed) {
          page[0].classList.remove('overlay-active');
        } else {
          page[0].classList.add('overlay-active');
        }
      }
    }, 500);
  }, [closed]);

  useEffect(() => {
    setTimeout(() => {
      setClosed(!!sessionStorage.getItem(DISMISS_SPLASH));
    }, 200);
  }, []);

  return (
    <React.Fragment>
      {!closed && (donationAsk || formAsk || actionLinksAsk) ? (
        <FocusTrap>
          <SplashWrapper closing={closing}>
            {imgFluid && (
            <Img className="wu--modal-image" fluid={imgFluid} />
            )}
            <Wrapper>
              <Content>
                {title && (
                <Header>{title}</Header>
                )}
                {intro && (
                  compileRichText({ textTheme: 'white', size: 'small' })(intro)
                )}
              </Content>
              {actionLinksAsk && (
                <ButtonsWrapper type="cta">
                  {actionLinksAsk.map((action) => (
                    <li>
                      <LinkButton
                        count={actionCount}
                        href={action.path}
                        size="large"
                        textTheme="primary"
                        buttonTheme="highlight"
                        buttonHoverTheme="secondaryHighlight"
                        textHoverTheme="primary"
                      >
                        {action.text}
                      </LinkButton>
                    </li>
                  ))}
                </ButtonsWrapper>
              )}
              <DonateInfo>
                {donationButtons && (
                  <ButtonsWrapper type="donation">
                    {donationButtons.map((amount) => (
                      <li>
                        <LinkButton
                          count={buttonCount}
                          key={amount.value}
                          size="large"
                          textTheme="primary"
                          buttonTheme="highlight"
                          buttonHoverTheme="secondaryHighlight"
                          href={appendQueryString(donationLink, `amount=${amount.value}`)}
                          textHoverTheme="primary"
                        >
                          {amount.display}
                        </LinkButton>
                      </li>
                    ))}
                  </ButtonsWrapper>
                )}
                {donationButtonsMobile && (
                  <ButtonsWrapper type="donation" mobile>
                    {donationButtonsMobile.map((amount) => (
                      <li>
                        <LinkButton
                          count={buttonCount}
                          key={amount.value}
                          size="large"
                          textTheme="primary"
                          buttonTheme="highlight"
                          buttonHoverTheme="secondaryHighlight"
                          href={appendQueryString(donationLink, `amount=${amount.value}`)}
                          textHoverTheme="primary"
                        >
                          {amount.display}
                        </LinkButton>
                      </li>
                    ))}
                  </ButtonsWrapper>
                )}
                {donateBarDisclaimer && donationButtons && (
                <Disclaimer>
                  {donateBarDisclaimer}
                </Disclaimer>
                )}
              </DonateInfo>
              {formAsk && (
                <Form {...formAsk} formTheme="Dark" size="Large" />
              )}
              <CloseButton onClick={CloseBar} mobile>
                <Close width="12px" height="12px" strokeWidth="4" strokeColor="white" />
                <span>close</span>
              </CloseButton>
            </Wrapper>
            <CloseButton onClick={CloseBar}>
              <Close width="12px" height="12px" strokeWidth="4" strokeColor="white" />
              <span>close</span>
            </CloseButton>
          </SplashWrapper>
        </FocusTrap>
      ) : null}
    </React.Fragment>
  );
};

export default Splash;
