import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Link } from '@components';
import { Chevron } from '@styles/icons';
import { slugify } from '@utils';
import {
  Subitem, SubitemsList, ItemButton, MobileItem,
} from './navigation.styles';

const MobileNavItem = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    path, title, subitems, mobOpen,
  } = props;
  const controlSubNav = (e) => {
    if (e.key === 'Enter') {
      setExpanded(!expanded);
    }
  };
  return (
    <MobileItem mobOpen={mobOpen}>
      {subitems ? (
        <React.Fragment>
          <ItemButton
            aria-expanded={expanded}
            aria-controls={`subnav--${slugify(title)}`}
            onKeyPress={(e) => controlSubNav(e)}
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
          >
            <span>show submenu</span>
            <span>{title}</span>
            <Chevron strokeColor="white" />
          </ItemButton>
          <SubitemsList id={`mobsubnav--${slugify(title)}`} expanded={expanded} role="menu">
            <AnimateHeight
              duration={subitems.length * 100}
              height={expanded ? 'auto' : 0}
            >
              {subitems.map((subitem, index) => (
                <Subitem index={index} key={`${subitem.title}--${subitem.path}`} role="menuitem">
                  <Link href={subitem.path}>{subitem.title}</Link>
                </Subitem>
              ))}
              {path && (
              <Subitem index={subitems.length}>
                <Link href={path}>{title}</Link>
              </Subitem>
              )}
            </AnimateHeight>
          </SubitemsList>
        </React.Fragment>
      ) : (
        <Link href={path}>{title}</Link>
      )}
    </MobileItem>
  );
};

export default React.memo(MobileNavItem);
