import styled, { css } from 'styled-components';
import { BlockQuote, createBreakpoint } from '@styles';

export const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: ${({ featuredEndorsement }) => (featuredEndorsement ? 'flex-start' : 'center')};
  justify-content: ${({ featuredEndorsement }) => (featuredEndorsement ? 'flex-start' : 'center')};
  margin-top: ${({ featuredEndorsement }) => (featuredEndorsement ? '60px' : '30px')};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: ${({ featuredEndorsement }) => (featuredEndorsement ? 'row' : 'column')};
  `)}

  a {
    width: 100%;
  }

  &:first-of-type {
    margin-top: ${({ featuredEndorsement }) => (featuredEndorsement ? '0px' : '30px')};
  }

  .wu--endorsement-logo {
    width: ${({ featuredEndorsement }) => (featuredEndorsement ? '200px' : '100%')};
    min-width: ${({ featuredEndorsement }) => (featuredEndorsement ? '200px' : '100%')};
    height: auto;
    justify-self: center;
    align-self: ${({ featuredEndorsement }) => (featuredEndorsement ? 'flex-start' : 'center')};
    margin-right: ${({ featuredEndorsement }) => (featuredEndorsement ? '40px' : '0px')};
    ${({ type }) => type === 'headshot' && css`
      max-height: 200px;
      height: 200px;
    `}
  }

  ${BlockQuote} {
    margin: 0px 0px 0px 0px;
    text-align: left;

    svg {
      transform: rotate(180deg);
      right: -10px;
      left: unset;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    ${BlockQuote} {
      margin: 0px 60px 0px 0px;
      text-align: left;

      svg {
        transform: rotate(180deg);
        right: -40px;
        left: unset;
      }
    }
  `)}
`;

export const Header3 = styled.h3`
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 700;
  font-size: ${({ featuredEndorsement }) => (featuredEndorsement ? '24px' : '18px')};
  margin-top: ${({ featuredEndorsement }) => (featuredEndorsement ? '15px' : '10px')};
  margin-bottom: ${({ featuredEndorsement }) => (featuredEndorsement ? '15px' : '0px')};
  line-height: 1.2;
  text-align: left;
  width: 100%;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: ${({ featuredEndorsement }) => (featuredEndorsement ? '28px' : '20px')};
    margin-bottom: ${({ featuredEndorsement }) => (featuredEndorsement ? '20px' : '0px')};
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: ${({ featuredEndorsement }) => (featuredEndorsement ? '0px' : '10px')};
    font-size: ${({ featuredEndorsement }) => (featuredEndorsement ? '34px' : '20px')};
    margin-bottom: ${({ featuredEndorsement }) => (featuredEndorsement ? '32px' : '0px')};
  `)}
`;

export const FeatWrapperContent = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: column;

  .wu--endorsement-logo {
    margin-top: 0px;
  }

`;
