import React from 'react';
import { Card } from '@components';
import { Header1, Header2 } from '@styles';
import { VIDEO } from '@utils/vars';
import {
  Section, ContentWrapper, ActionCardWrapper, ActionCard,
} from '../homepage-sections.styles';

const SecondaryActionsSection = ({
  sectionTitle, contentReferences, order, nextModuleType,
}) => (
  <Section
    sectionTheme="secondary"
    hiddenTitle
    nextVideo={nextModuleType === VIDEO}
  >
    <ContentWrapper
      flexDirection="column"
      maxWidth="wideMaxWidth"
      paddingTop="80px"
      paddintBottom="80px"
    >
      {sectionTitle && (order !== 0 ? (
        <Header2 textTheme="primary" size="large">
          {sectionTitle}
        </Header2>
      ) : (
        <Header1 textTheme="primary">
          {sectionTitle}
        </Header1>
      ))}
      {contentReferences && (
        <ActionCardWrapper>
          {contentReferences.map((action) => {
            const { internalCallToActionLink, callToActionText, externalCallToActionLink } = action;
            const href = (internalCallToActionLink && internalCallToActionLink.pageUrl)
          || externalCallToActionLink;
            if (href && callToActionText) {
              return (
                <ActionCard key={action.id}>
                  <Card
                    href={href}
                    size="xlarge"
                    text={action.callToActionText}
                  />
                </ActionCard>
              );
            }
            return null;
          })}
        </ActionCardWrapper>
      )}
    </ContentWrapper>
  </Section>
);

export default SecondaryActionsSection;
