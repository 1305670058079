import React from 'react';
import Img from 'gatsby-image';
import { Link, LinkButton } from '@components';
import {
  Header1,
} from '@styles';
import { Arrow } from '@styles/icons';
import { compileRichText, dateToString } from '@utils';
import {
  Wrapper, ListWrapper, ListItem, Content, ListTitle, Download, TextContent, DateContent,
} from './plan-hero.styles';

const PlanHero = (props) => {
  const {
    title, relatedContent, heroImage, relatedContentTitle,
    document, documentDownloadText, intro, releaseDate, dateLabel,
  } = props;

  const hasImg = heroImage && heroImage.fluid;

  return (
    <Wrapper hasImg={hasImg}>
      <Content hasImg={hasImg}>
        <TextContent>
          <Header1 textTheme="primary" size="medium">{title}</Header1>
          {dateLabel && releaseDate && (
            <DateContent>
              <span>
                {dateLabel}
                {': '}
              </span>
              <span>{dateToString(new Date(releaseDate))}</span>
            </DateContent>
          )}
          {hasImg && intro && (
            compileRichText({ textTheme: 'primary', size: 'large' })(intro)
          )}
          {relatedContent && (
            <ListWrapper>
              {relatedContentTitle && <ListTitle>{relatedContentTitle}</ListTitle>}
              <ul>
                {relatedContent.map((issue) => (
                  <React.Fragment>
                    {issue && issue.page && issue.page[0] && (
                    <ListItem key={issue.id}>
                      <Link textTheme="primaryHighlight" href={issue.page[0] && issue.page[0].pageUrl}>
                        <span>{issue.title}</span>
                        <Arrow strokeColor="primaryHighlight" height="14.4px" width="16px" />
                      </Link>
                    </ListItem>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </ListWrapper>
          )}
          {document && documentDownloadText && document.file && document.file.url && (
            <Download>
              <LinkButton
                href={document.file.url}
                size="xlarge"
                textTheme="white"
                buttonTheme="primaryHighlight"
                buttonHoverTheme="secondaryHighlight"
                textHoverTheme="primary"
              >
                {documentDownloadText}
              </LinkButton>
            </Download>
          )}
        </TextContent>
        {heroImage && heroImage.fluid && (
          <Img
            alt={heroImage.description}
            fluid={heroImage.fluid}
            className="hero-image"
          />
        )}
      </Content>
    </Wrapper>
  );
};

export default PlanHero;
