import React from 'react';
import Img from 'gatsby-image';
import { MainLink } from '@components';
import { Header1, Header2 } from '@styles';
import { VIDEO } from '@utils/vars';
import {
  EndorsementsContentWrapper, EndorsementImages,
  Section, ContentWrapper,
} from '../homepage-sections.styles';

const EndorsementsSection = ({
  sectionTitle, sectionLink, contentReferences,
  order, nextModuleType, internalSectionLink,
}) => (
  <Section
    sectionTheme="white"
    nextVideo={nextModuleType === VIDEO}
    order={order}
  >
    <ContentWrapper tabletFlexDirection="column" flexDirection="row">
      <EndorsementsContentWrapper>
        {sectionTitle && (order !== 0 ? (
          <Header2 textTheme="primary" size="large">
            {sectionTitle}
          </Header2>
        ) : (
          <Header1 textTheme="primary">
            {sectionTitle}
          </Header1>
        ))}
        {internalSectionLink && internalSectionLink.page
        && internalSectionLink.page[0] && sectionLink && (
          <MainLink
            internalLink={internalSectionLink.page[0] && internalSectionLink.page
              && internalSectionLink.page[0].pageUrl}
            sectionLink={sectionLink}
            desktop
          />
        )}
      </EndorsementsContentWrapper>
      {contentReferences && (
        <EndorsementImages>
          {contentReferences.map((endorsement) => (
            <a href={endorsement.link} key={endorsement.logoheadshot.id}>
              {endorsement.logoheadshot && (
                <Img
                  alt={endorsement.name}
                  className="endorsement-logo"
                  imgStyle={{ objectFit: 'contain' }}
                  fluid={endorsement.logoheadshot.fluid}
                />
              )}
              {(
                endorsement.endorsementType === 'Elected Official'
                || endorsement.endorsementType === 'Community Leader'
              ) && (
                <span>{endorsement.name}</span>
              )}
            </a>
          ))}
        </EndorsementImages>
      )}
      {internalSectionLink && internalSectionLink.page
      && internalSectionLink.page[0] && sectionLink && (
        <MainLink
          internalLink={internalSectionLink.page && internalSectionLink.page[0]
            && internalSectionLink.page[0].pageUrl}
          sectionLink={sectionLink}
          mobile
          tablet
        />
      )}
    </ContentWrapper>
  </Section>
);

export default EndorsementsSection;
