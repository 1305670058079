import React from 'react';
import theme from '../theme';

const LargeArrow = ({
  width = '14.875',
  height = '31.375px',
  strokeColor = 'primary',
}) => (
  <svg width={width} height={height} viewBox="0 0 119 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44 251v-2h-2l-2-3v-1-2-2-1-1-3-2-11l-1-2 1-2v-1l-1-1v-1-1-1-2s-1-1 1-1v-1l-1-1v-3-1l-2 1-2 1v-1l-2 1-1-1-2 1h-2-3-1l-1 1-2-1c-1-1-1 1-1 1l-1-1h-1v1h-2v-1c0 1 0 2-1 1h-2l-3 1v-1H7l-1-1-1-2-1-1v-1-1-1-1-1l-1-1 1-1-1-1 1-4v-1-1l-1-5 1-1-1-2v-1-1-1-1-1-1-3-3c-1-1 1-1 1-1l-1-1v-1-1-2-4l-1-2h1l-1-1h2c-1-1-2-1-1-2l-1-1v-1-1-2-1-1-2-2-2-2-4-1l1-1-1-2v-4-1-2l-1-1 1-4 1-1-1-2v-2-1l-1-2 1-1v-2l-1-2v-2h1l-1-1v-1h1l-1-2 1-3v-1l-1-1v-3-1-3-1H0l2-1H1v-2-2-1-1-1-3-3-7l-1-2V45l1-2 1-1c0-2 2-1 2-2h2l2 1h1l2-1 1 1 1-1 4 1v-1l1 1 3-1 1 1h14l2-1-1-1 1-1h-1l1-1v-3-4-1l1-1h-1l1-2c-2 0-1-1-1-2l1 2v-3-1-1-2-5l1-1v-1h-1c2-1 0-1 0-2l1-1-1-1h1l-1-1 1-1 1-3V2l1-1h1c1-1 2-2 3 0 2 0 2 1 3 2l1 1 1 1 2 3 1 1v1l2 4v1l2 1v1l1 2h1v1l1 1h-1l2 2v2h1l1 2 1 2 1 1 2 4 1 2 2 2 1 1 2 4 1 2v1l1 2 2 3h1l-1 1h1l1 2 1 2 1 1v1l1 1 2 4 1 1v1l2 3v1l1 1v2l2 1v2h1v1l1 1 1 1v1l1 2c1 1 2 1 1 2 2 0 1 2 2 2v1l2 2v2h1v1l1 1 1 1 1 1v1l1 1 1 3 1 2 1 1v1h1v1l2 3 2 3v1l1 1 1 3 2 2 1 1-1 1h1v6h-1v2h-1l-1 1v1c0 1-2 1-1 2l-1 2-2 2-1 3v1l-2 2h1l-1 1-1 1v1c-1 0-2 1-1 2l-1 2v-1l-1-1v2l-1 1-1 2-1 2-1 1-1 2v1l-1 1-1 2-1 2-1 2-1 1s-1-1 0 0l-1 1-1 1v1l-1 1h1l-1 1v-1l-2 4v2l-2 1v1l-1 1v2l-1 1-2 2v1l-1 1-1 2-1 1-1 1v2h-1l1 1-1 1-1 1v1l-1 1v1l-2 3-1 2-1 1v2l-2 1v1l-1 1-1 3-1-1-1 1 1 1-2 3v-1 2h-1v1l-2 3-1 2-1 3-2 2-1 2-1 2-1-1 1 2h-1c0 2-2 2-1 4l-2 1h1l-2 2v1l-2 3v1l-2 3 1 1h-2c0 1 0 2-2 2v1z"
      fill={theme.palette[strokeColor]}
    />
  </svg>
);

export default LargeArrow;
