import { css, keyframes } from 'styled-components';

const fadeAnimation = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fade = (duration = '0.5s') => css`
  ${({ isFading }) => (isFading ? css`
    animation: ease-in forwards ${duration} ${fadeAnimation};
  ` : '')}
`;

export default fade;
