import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';
import { switchBasedOnScheme } from '@utils';
import {
  SMALL, MEDIUM, LARGE, XLARGE,
} from '@utils/vars';

export const Card = styled.a`
  display: flex;
  white-space: normal;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.white};
  font-weight: 700;
  border: 2px solid ${({ theme }) => theme.palette.secondaryHighlight};
  transition: all 0.3s;
  line-height: 1;

  > span {
    display: block;
    width: 100%;
  }

  svg {
    margin-left: -37px;
    left: 7px;
    position: relative;
    transition: transform 0.3s;
    polygon {
      transition: fill 0.3s;
    }
  }

  &:hover {
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, .2);
    color: ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => theme.palette.secondaryHighlight};

    svg {
      transform: translateX(40%);
      polygon {
        fill: ${({ theme }) => theme.palette.primary};
      }
    }
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      font-size: 14px;
    `,
    [MEDIUM]: css`
      font-size: 16px;
      padding: 50px 60px;
    `,
    [LARGE]: css`
      font-size: 18px;
      padding: 12px 30px 13px 30px;
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        font-size: 30px;
        padding: 40px 50px;
      `)}
    `,
    [XLARGE]: css`
      font-size: 34px;
      padding: 40px 30px;
      svg {
        top: 4px;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
        font-size: 44px;
        svg {
          top: 0px;
        }
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
        font-size: 40px;
        padding: 40px;
      `)}
      ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
        font-size: 50px;
        padding: 40px;
      `)}
    `,
  })}
`;

export const Text = styled.span`
  display: inline;
`;

export const LastWord = styled.span`
  display: inline-block;
  padding-right: 35px;
`;

export default Card;
