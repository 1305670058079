import React from 'react';
import { Link } from '@components';
import { Arrow } from '@styles/icons';
import {
  MainSectionLink, LastWord, Text,
} from './main-link.styles';

const MainLink = ({
  internalLink, externalLink, mobile, sectionLink, tablet, desktop,
}) => {
  const partText = sectionLink && sectionLink.substring(0, sectionLink.lastIndexOf(' '));
  const lastWord = sectionLink && sectionLink.substring(sectionLink.lastIndexOf(' '), sectionLink.length);
  return (
    <MainSectionLink mobile={mobile} tablet={tablet} desktop={desktop}>
      <Link
        href={internalLink || externalLink}
      >
        <Text>
          {partText}
          {' '}
        </Text>
        <LastWord>{lastWord}</LastWord>
        <Arrow strokeColor="primaryHighlight" height="18px" width="20px" />
      </Link>
    </MainSectionLink>
  );
};

export default React.memo(MainLink);
