import React, { useState, useRef, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Img from 'gatsby-image';
import { Triangle } from '@styles/icons';
import { compileRichText } from '@utils';
import {
  VideoContainer, VideoContainerInline, VideoCaption, VideoImageWrapper, VideoImage, VideoPlay,
} from './video.styles';

const Video = ({
  caption, title, videoId, customThumbnail, size = 'large', iframeSrc, titleLevel, locale,
}) => {
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const videoRef = useRef();

  let youtubeIframeAPI;

  const iframeAPIReady = () => new Promise((resolve) => {
    if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
      resolve(window.YT);
      setIsIframeLoading(true);
      return;
    }

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const previous = window.onYouTubeIframeAPIReady;

    window.onYouTubeIframeAPIReady = () => {
      setIsIframeLoading(true);
      if (previous) {
        previous();
      }
      resolve(window.YT);
    };
  });

  function onVideoClick() {
    youtubeIframeAPI = iframeAPIReady();
    youtubeIframeAPI.then((YT) => {
      // eslint-disable-next-line no-unused-vars
      const player = new YT.Player(videoId, {
        videoId,
        playerVars: {
          modestBranding: 1,
          rel: 0,
        },
        events: {
          onReady: (e) => {
            e.target.playVideo();
          },
          onStateChange: (e) => {
            if (e.data === 1 || e.data === 3) {
              setIsVideoPlaying(true);
            } else {
              setIsVideoPlaying(false);
            }
          },
        },
      });
      setVideoPlayer(player);
    });
  }

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    useScrollPosition(
      ({ currPos }) => {
        const newIsInViewport = currPos.y > -200 && window && currPos.y <= window.innerHeight;
        if (isInViewport !== newIsInViewport) {
          setIsInViewport(newIsInViewport);
        }
      }, [isInViewport], videoRef, false, 300,
    );
  }

  useEffect(() => {
    if (videoPlayer && isVideoPlaying && !isInViewport) {
      // videoPlayer.pauseVideo();
    }
  }, [videoPlayer, isVideoPlaying, isInViewport]);

  const thumbnailProps = {
    onClick: onVideoClick,
    src: `https://img.youtube.com/vi/${videoId}/0.jpg`,
  };

  const Header = titleLevel ? `h${titleLevel}` : 'h2';

  return (
    <VideoContainer ref={videoRef} size={size}>
      {(!isIframeLoading && !iframeSrc) && (
        <VideoImageWrapper onClick={onVideoClick}>
          {!customThumbnail ? (
            <VideoImage
              {...thumbnailProps}
              onClick={onVideoClick}
            />
          ) : (
            <Img
              fluid={customThumbnail.fluid}
              className="video-image"
            />
          )}
          <VideoPlay
            size={size}
            onClick={onVideoClick}
            aria-label={`play ${title}`}
          >
            <Triangle width="25px" height="48px" />
          </VideoPlay>
        </VideoImageWrapper>
      )}
      {(isIframeLoading || iframeSrc) && (
        <VideoContainerInline>
          {videoId && !iframeSrc && <span id={videoId} />}
          {iframeSrc && (
            <iframe
              title={title}
              src={iframeSrc}
              frameboder="0"
              allowFullScreen
            />
          )}
        </VideoContainerInline>
      )}
      {(caption || title) && (
        <React.Fragment>
          <VideoCaption aria-hidden size={size}>
            {title && <Header>{title}</Header>}
            {caption && compileRichText({ textTheme: 'white', size: 'medium', locale })(caption)}
          </VideoCaption>
        </React.Fragment>
      )}
    </VideoContainer>
  );
};

export default Video;
