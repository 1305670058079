import styled, { keyframes, css } from 'styled-components';
import { rgba } from 'polished';
import { createBreakpoint } from '@styles';

export const VideoImage = styled.img`
  object-fit: cover;
  display: block;
  line-height: 0;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  z-index: ${({ theme }) => theme.zIndices.videoThumb};
  transition: box-shadow 0.3s linear;
`;

export const VideoImageWrapper = styled.div`
  display: block;
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary};

  .video-image {
    object-fit: cover;
    display: block;
    line-height: 0;
    margin-bottom: 15px;
    width: 100%;
    height: auto;
    z-index: ${({ theme }) => theme.zIndices.videoThumb};
    transition: box-shadow 0.3s linear;
  }
`;

export const VideoPlay = styled.button`
  background-color: ${({ theme }) => rgba(theme.palette.secondaryHighlight, 0.9)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  display: flex;
  padding: 0px;

  svg {
    position: relative;
    top: calc(50% - 19px);
    left: calc(50% - 5px);
    width: 20px;
    height: 38px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 140px;
    height: 140px;
    left: calc(50% - 70px);
    top: calc(50% - 70px);

    svg {
      width: 25px;
      height: 48px;
      top: calc(50% - 24px);
      left: calc(50% - 7.5px);
    }
  `)}
`;

export const VideoContainer = styled.div`
  cursor: pointer;
  position: relative;
  margin: 0px auto;
  display: block;
  min-width: 100%;

  &:hover {
    ${VideoPlay} {
      background-color: ${({ theme }) => rgba(theme.palette.highlight, 0.9)};
      > svg > path {
        fill: ${({ theme }) => theme.palette.primary};
      }
    }

    ${VideoImage}, .video-image {
      box-shadow: 7px 7px transparent;
      opacity: 0.85;
    }
  }

`;

const FadeIn = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity 1;
  }
`;

export const VideoContainerInline = styled.span`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 15px;
  display: block;
  background-color: black;
  opacity: 0.05;
  animation: ${FadeIn} 0.5s linear;
  animation-fill-mode: forwards;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoInlineContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const VideoCaption = styled.div`
  padding-bottom: 15px;
  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.palette.white};
    font-weight: 700;
    font-size: 30px;
    line-height: 1.8;
    margin: 0px auto 5px;
  }
`;
