import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Link } from '@components';
import { DoubleQuotes, NewWindow } from '@styles/icons';
import {
  Paragraph, Bold, Italic, Header3, Header4, Header5, Header6, BlockQuote, Cite,
  Underline, RecordList, RecordItem, RecordLinkWrapper,
} from '@styles';
import { getKeyValue } from '@utils';

export const renderOptions = ({ textTheme = 'purple', size }) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: () => null,
    [BLOCKS.HEADING_1]: () => null,
    [BLOCKS.HEADING_2]: () => null,
    [BLOCKS.HEADING_3]: (node, children) => (
      <Header3 textTheme={textTheme}>{children}</Header3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Header4 textTheme={textTheme}>{children}</Header4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Header5 textTheme={textTheme}>{children}</Header5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Header6 textTheme={textTheme}>{children}</Header6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Paragraph textTheme={textTheme} size={size}>
        {children}
      </Paragraph>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <RecordList textTheme={textTheme} size={size}>
        {children}
      </RecordList>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <RecordList textTheme={textTheme} size={size}>
        {children}
      </RecordList>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <RecordItem textTheme={textTheme} size={size}>
        {children}
      </RecordItem>
    ),
    [BLOCKS.EMBEDDED_ASSET]: () => null,
    [BLOCKS.QUOTE]: (node, children) => (
      <BlockQuote textTheme={textTheme} size={size}>
        <DoubleQuotes strokeColor="highlight" size={size} />
        {children && children.map((child) => {
          if (child.props) {
            const quoteChild = child.props.children && child.props.children[0];
            const hasCitation = quoteChild && typeof quoteChild === 'string' && quoteChild.startsWith('- ');
            if (hasCitation) {
              return (
                <Cite key={child.key} size={size}>
                  {quoteChild.replace('- ', '')}
                  {child.props.children[1] && child.props.children.slice(1)}
                </Cite>
              );
            }
            return quoteChild;
          }
          return null;
        })}
      </BlockQuote>
    ),
    [BLOCKS.HR]: () => <hr />,
    [INLINES.HYPERLINK]: (node, children) => {
      const { data: { uri: href } } = node;

      const anchorProps = {
        href,
      };

      if (href && href.startsWith('http')) {
        anchorProps.target = '_blank';
      }

      return (
        <RecordLinkWrapper textTheme={textTheme} size={size}>
          <Link {...anchorProps}>
            <span>{children}</span>
            <NewWindow fill="secondaryHighlight" />
          </Link>
        </RecordLinkWrapper>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { file } = getKeyValue(node, 'data.target') || {};
      const { url: href } = file || {};
      const anchorProps = {
        href,
      };

      if (href && href.startsWith('http')) {
        anchorProps.target = '_blank';
      }

      return (
        <RecordLinkWrapper textTheme={textTheme} size={size}>
          <Link {...anchorProps}>
            <span>{children}</span>
            <NewWindow fill="secondaryHighlight" />
          </Link>
        </RecordLinkWrapper>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const { target } = getKeyValue(node, 'data') || {};
      const { type: entryType } = getKeyValue(target, 'internal') || {};
      if (entryType === 'ContentfulPage') {
        const { pageUrl } = target || {};
        const href = pageUrl;
        const anchorProps = {
          href,
        };
        if (href && href.startsWith('http')) {
          anchorProps.target = '_blank';
        }
        return (
          <RecordLinkWrapper textTheme={textTheme} size={size}>
            <Link {...anchorProps}>
              <span>{children}</span>
              <NewWindow fill="secondaryHighlight" />
            </Link>
          </RecordLinkWrapper>
        );
      }

      return null;
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Bold>
        {text}
      </Bold>
    ),
    [MARKS.ITALIC]: (text) => (
      <Italic>
        {text}
      </Italic>
    ),
    [MARKS.UNDERLINE]: (text) => (
      <Underline>
        {text}
      </Underline>
    ),
  },
});

export default function compileRichText({ textTheme, size = 'medium' }) {
  const options = renderOptions({ textTheme, size });

  function compile(content) {
    return renderRichText(content, options);
  }

  return compile;
}
