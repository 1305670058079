import styled, { css } from 'styled-components';
import { createBreakpoint, slideIn, fadeIn } from '@styles';

export const Hero = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  padding: 0px 16px;
  width: 100%;
  margin-top: 100px;
  animation: ${fadeIn};
  animation-duration: 0.5s;
  animation-timing-function: ease;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 0px;
    margin-top: 0px;
  `)}
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.white};
  line-height: 1;
  font-weight: 700;
  margin-bottom: 25px;
  margin-top: 0px;
  font-size: 44px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 60px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 80px;
  `)}
`;

export const HeroContentWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.layout.gutter}px 16px 50px;
  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 55%;
    padding: 60px 60px 60px ${theme.layout.gutter}px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    width: 45%;
    padding: 60px 60px 60px ${theme.layout.gutter}px;
  `)}
`;

export const ImageWrapper = styled.div`
  opacity: ${({ isCardInView }) => (isCardInView ? 1 : 0)};
  max-height: 400px;
  margin-top: -100px;

  ${({ isCardInView }) => isCardInView && css`
    animation: ${slideIn};
    animation-duration: 0.7s;
    animation-timing-function: ease;
  `}

  .hero-image {
    max-height: 400px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    max-height: 600px;

    .hero-image {
      max-height: 600px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 45%;
    margin-bottom: -60px;
    margin-top: 0px;
    max-height: 1000px;

    .hero-image {
      width: 100%;
      height: auto;
      max-height: 1000px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    width: 55%;
    margin-bottom: -60px;

    .hero-image {
      width: 100%;
      height: auto;
    }
  `)}
`;

export const HeroWrapper = styled.div`
  margin: 0px auto;
  max-width: ${({ theme }) => theme.layout.extraWideMaxWidth}px;
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    flex-direction: row;
  `)}
`;
