import styled, { keyframes } from 'styled-components';

export const slideIn = keyframes`
  0% {
    transform: translate3d(0,-100px,0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
  }

  &:hover {
    border-left: 4px solid ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => theme.palette.lighterPrimary};
  }

  background-color: ${({ expanded, theme }) => (expanded ? theme.palette.lighterPrimary : theme.palette.white)};
  border-left: ${({ expanded, theme }) => (expanded ? `4px solid ${theme.palette.primary}` : '0px solid transparent')};
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  padding: 0px;
  cursor: pointer;
  transition: padding 0.3s ease-in-out;
  padding-left: ${({ expanded }) => (expanded ? '5px' : '0px')};
  color: inherit;

  &:hover {
    padding-left: 5px;
  }

  svg {
    transition: transform 0.3s ease-in-out;
    transform: ${({ expanded }) => (expanded ? 'rotate(0deg)' : 'rotate(-90deg)')};
  }
`;

export const Content = styled.div`
  padding-left: 20px;
  color: ${({ theme, textTheme }) => theme.palette[textTheme]};

  a, p, li, ul, ol {
    color inerit;
  }
`;
