import React from 'react';
import { Link, LinkButton } from '@components';
import { Arrow } from '@styles/icons';
import { Header1, Header2 } from '@styles';
import { compileRichText } from '@utils';
import { VIDEO } from '@utils/vars';
import {
  Section, ContentWrapper, IssuesContentWrapper, LeftContentWrapper,
  AllIssuesLink, IssuesList, IssueLink,
} from '../homepage-sections.styles';

const IssuesSection = ({
  sectionTitle, sectionLink, contentReferences: allIssues,
  order, nextModuleType, summaryOverride, internalSectionLink,
}) => {
  const { intro } = internalSectionLink || {};
  const finalSummary = summaryOverride || intro;

  return (
    <Section
      sectionTheme="lighterPrimary"
      nextVideo={nextModuleType === VIDEO}
      order={order}
    >
      <ContentWrapper flexDirection="column" maxWidth="wideMaxWidth">
        <IssuesContentWrapper>
          <LeftContentWrapper>
            {sectionTitle && (order !== 0 ? (
              <Header2 textTheme="primary" size="medium">
                {sectionTitle}
              </Header2>
            ) : (
              <Header1 textTheme="primary">
                {sectionTitle}
              </Header1>
            ))}
            {finalSummary && (
              compileRichText({ textTheme: 'primary', size: 'large' })(finalSummary)
            )}
            {internalSectionLink && internalSectionLink.page[0] && sectionLink && (
              <AllIssuesLink>
                <LinkButton
                  href={internalSectionLink.page[0] && internalSectionLink.page[0].pageUrl}
                  size="xlarge"
                  buttonTheme="primaryHighlight"
                  buttonHoverTheme="secondaryHighlight"
                  textTheme="white"
                  textHoverTheme="primary"
                >
                  {sectionLink}
                </LinkButton>
              </AllIssuesLink>
            )}
          </LeftContentWrapper>
          {allIssues && (
          <IssuesList>
            {allIssues.map((issue) => {
              const {
                id, page, title, callToActionText, internalCallToActionLink,
              } = (issue) || {};
              const finalTitle = title || callToActionText;
              const finalHref = (page && page[0].pageUrl)
                || (internalCallToActionLink && internalCallToActionLink.pageUrl);
              return (
                <IssueLink key={`${id}--issue`}>
                  <Link href={finalHref}>
                    <span>{finalTitle}</span>
                    <Arrow strokeColor="primaryHighlight" height="15px" width="14px" />
                  </Link>
                </IssueLink>
              );
            })}
          </IssuesList>
          )}
          {internalSectionLink && internalSectionLink.page[0] && sectionLink && (
            <AllIssuesLink mobile>
              <LinkButton
                href={internalSectionLink.page[0] && internalSectionLink.page[0].pageUrl}
                size="xlarge"
                buttonTheme="primaryHighlight"
                buttonHoverTheme="secondaryHighlight"
                textTheme="white"
                textHoverTheme="primary"
              >
                {sectionLink}
              </LinkButton>
            </AllIssuesLink>
          )}
        </IssuesContentWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default IssuesSection;
