const getKeyValue = (source, path) => {
  if (!source) {
    return null;
  }

  let value = source;

  for (const key of path.split('.')) {
    if (!value[key]) {
      return null;
    }

    value = value[key];
  }

  return value;
};

export default getKeyValue;
