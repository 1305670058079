import React from 'react';
import Img from 'gatsby-image';
import { Link } from '@components';
import { compileRichText, slugify } from '@utils';
import { Wrapper, Header3, FeatWrapperContent } from './endorsement.styles';

const Endorsement = (props) => {
  const {
    name, link, logoheadshot, endorsementType, description, featuredEndorsement,
  } = props;

  const type = endorsementType === 'Organization' ? 'logo' : 'headshot';

  return (
    <Wrapper
      featuredEndorsement={featuredEndorsement}
      type={type}
    >
      {featuredEndorsement && (
        <React.Fragment>
          {link ? (
            <Link
              textTheme="primary"
              href={link}
            >
              {logoheadshot && logoheadshot.fluid && (
              <Img
                className={`wu--endorsement-logo wu--endorsement-logo-${slugify(endorsementType)}`}
                fluid={logoheadshot.fluid}
                title={name}
              />
              )}
            </Link>
          ) : (
            <Img
              className={`wu--endorsement-logo wu--endorsement-logo-${slugify(endorsementType)}`}
              fluid={logoheadshot.fluid}
              title={name}
            />
          )}
        </React.Fragment>
      )}
      {featuredEndorsement && (
        <FeatWrapperContent>
          <Header3 textTheme="primary" size="small" featuredEndorsement>
            {name}
          </Header3>
          {description && (
            compileRichText({ textTheme: 'primary', size: 'large' })(description)
          )}
        </FeatWrapperContent>
      )}
      {!featuredEndorsement && (
        <React.Fragment>
          {link ? (
            <Link
              textTheme="primary"
              href={link}
            >
              {logoheadshot && logoheadshot.fluid && (
              <Img
                className={`wu--endorsement-logo wu--endorsement-logo-${slugify(endorsementType)}`}
                fluid={logoheadshot.fluid}
                title={name}
              />
              )}
            </Link>
          ) : (
            <Img
              className={`wu--endorsement-logo wu--endorsement-logo-${slugify(endorsementType)}`}
              fluid={logoheadshot.fluid}
              title={name}
            />
          )}
          {(type === 'headshot' && (
          <Header3 textTheme="primary" size="small">
            {name}
          </Header3>
          ))}
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default Endorsement;
