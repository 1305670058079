import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Card = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  white-space: normal;
  width: 100%;
  height: 100%;
  color: ${({ textTheme, theme }) => theme.palette[textTheme]};
  background-color: ${({ bgTheme, theme }) => theme.palette[bgTheme]};
  font-weight: 700;
  border: 2px solid ${({ theme, bdHighlight }) => theme.palette[bdHighlight]};
  transition: all 0.3s;
  line-height: 1;
  position: relative;
  font-size: 28px;
  padding: 32px 30px;

  > span {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .card-image-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
  }

  .card-image {
    height: 100px;
    max-width: 100%;
    width: 100px;
    opacity: 1;
  }
  .hover-card-image {
    height: 100px;
    max-width: 100%;
    width: 100px;
    opacity: 0;
  }

  .hover-card-image-wrapper {
    position: absolute;
    right: calc(50% - 50px);
  }

  p {
    line-height: 1.2;
    margin-top: 20px;
    font-weight: 400;

    b, strong {
      font-weight: 700;
    }
  }

  svg {
    top: 4px;
    margin-left: -37px;
    left: 7px;
    position: relative;
    transition: transform 0.3s;
    polygon {
      transition: fill 0.3s;
    }
  }

  &:hover {
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, .2);
    color: ${({ theme, bdHighlight }) => (bdHighlight === 'primaryHighlight' ? theme.palette.white : theme.palette.primary)};
    background-color: ${({ theme, bdHighlight }) => theme.palette[bdHighlight]};

    .card-image {
      opacity: 0;
    }
    .hover-card-image {
      opacity: 1;
    }

    svg {
      transform: translateX(40%);
      polygon {
        fill: ${({ theme, bdHighlight }) => (bdHighlight === 'primaryHighlight' ? theme.palette.white : theme.palette.primary)};
      }
    }

    p {
      color: ${({ theme, bdHighlight }) => (bdHighlight === 'primaryHighlight' ? theme.palette.white : theme.palette.primary)};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
    font-size: 28px;
    svg {
      top: 0px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 32px;
    padding: 32px;
  `)}
`;

export const Text = styled.span`
  display: inline;
`;

export const LastWord = styled.span`
  display: inline-block;
  padding-right: 35px;
`;

export default Card;
