import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.lighterPrimary};
  padding: 0px 15px;
  margin-bottom: ${({ hasImg }) => (hasImg ? '140px' : '40px')};

  ${({ backgroundColor }) => backgroundColor && css`
    background-color: ${({ theme }) => theme.palette[backgroundColor]};
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding: 0px 32px ${({ hasImg }) => (hasImg ? '32px' : '0px')};
    margin-bottom: ${({ hasImg }) => (hasImg ? '100px' : '60px')};
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: ${({ hasImg }) => (hasImg ? '100px' : '20px')};
    padding: 0px 32px;
    margin-bottom: ${({ hasImg }) => (hasImg ? '80px' : '60px')};
  `)}
`;

export const Content = styled.div`
  max-width: ${({ theme }) => (theme.layout.contentMaxWidth)}px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  .hero-image-mobile {
    width: 100%;
    height: auto;
    top: 0px;
    margin-bottom: -90px;
  }

  .hero-image-desktop {
    display: none;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: column;

    .hero-image-desktop {
      display: block;
      width: 100%;
      height: auto;
      max-height: 460px;
      top: -80px;
      margin-bottom: -80px;
    }

    .hero-image-mobile {
      display: none;
    }
  `)}
`;

export const TextContent = styled.div`
  width: 100%;
  padding: ${({ hasImg }) => (hasImg ? '40px 17px 0px' : '40px 17px')};

  p {
    line-height: 1.3;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 100%;
    padding: 40px 0px 480x;

    p {
      line-height: 1.7;
    }

    h1 {
     margin-bottom: 8px;
    }
  `)}
`;

export const Download = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;

  a {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.primaryHighlight};

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 45px;
    margin-bottom: 0px;
    width: fit-content;
    a {
      width: fit-content;
    }
  `)}
`;

export const Downloads = styled.p`
  margin-top: 32px;
  margin-bottom: 7px;
  width: 100%;

  a {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.primaryHighlight};

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 45px;
    margin-bottom: 0px;
    width: 100%;

    a {
      width: fit-content;
      min-width: fit-content;
    }
  `)}
`;

export const Documents = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;

  > span {
    margin-top: 5px;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: row;
    justify-content: flex-start;

    > span {
      width: fit-content;
      margin-right: 7px;
      margin-top: 7px;
      margin-bottom: 0px;
    }
  `)}
`;

export const DateContent = styled.p`
  font-size: 16px;
  line-height: 1.8;
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.14em;
  margin-top: 16px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 20px;
  `)}
`;

export const ParentPage = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.8;
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.14em;
  transform: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.primaryHighlight};
  }
`;
