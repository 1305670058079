import styled, { css } from 'styled-components';
import { a11yOnly, createBreakpoint } from '@styles';

const FormWrapper = styled.div`
  width: 100%;
  min-height: 400px;
  .oa-page-embed {
    font-family: ${({ theme }) => theme.font};
    color: ${({ theme }) => theme.palette.primary};
    width: 100%;

    fieldset.at-fieldset {
      padding: 0px 0.625rem;
      margin-left: -1.25rem;
      margin-right: 0px;
    }

    h1 {
      display: none;
    }

    .at .btn-at, p, h2, h1, h3, div, b, strong, em, a, li, ul, ol, blockquote, input, select {
      font-family: ${({ theme }) => theme.font};
    }

    .oa-event-result-location-container {
      span {
        color: ${({ theme }) => theme.palette.primary};
        font-size: 16px;
      }
      .glyphicons-map-marker {
        font-size: 13px;
        margin-right: 2px;
      }
    }

    legend.at-legend {
      ${a11yOnly}
      font-family: ${({ theme }) => theme.font};
    }

    .oa-pagination-container {
      padding-top: 32px;
      border-top: 8px solid ${({ theme }) => theme.palette.lighterPrimary};

      .pagination-stats {
        font-size: 16px;
        margin-top: 22px;
        color: ${({ theme }) => theme.palette.primary};
      }

      .pagination {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        .PagedList-skipToFirst,
        .PagedList-skipToLast {
          display: none;
        }

        .oa-page-async-link {
          cursor: pointer;
          border-radius: ${({ theme }) => theme.button.radius};
          transition: all 0.3s;
          padding: 2px 0px;
          margin: 0px 5px;

          &:hover {
            background-color: ${({ theme }) => theme.palette.secondaryHighlight};
          }

          a {
            font-size: 18px;
            font-weight: 700;
            padding: 5px 10px 7px;
            line-height: 1;
            cursor: pointer;
            color: ${({ theme }) => theme.palette.primary};
          }

          &.disabled {
            a {
              color: ${({ theme }) => theme.palette.lightPrimary};
              cursor: not-allowed;
            }

            &:hover {
              background-color: transparent;
            }
          }

          &.active {
            background-color: ${({ theme }) => theme.palette.primaryHighlight};

            &:hover {
              background-color: ${({ theme }) => theme.palette.secondaryHighlight};

              a {
                color: ${({ theme }) => theme.palette.primary};
              }
            }

            a {
              color: ${({ theme }) => theme.palette.white};
            }
          }
        }

        ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
          display: block;

          .PagedList-skipToFirst,
          .PagedList-skipToLast {
            display: inline-block;
          }

          .oa-page-async-link {
            padding: 5px 0px;
          }
        `)}
      }
    }



    .AdditionalInformation legend.at-legend {
      display: block;
      position: relative;
      width: 100%;
      clip: unset;
      height: fit-content;
      overflow: visible;
      padding: 0px;
      position: relative;
      width: 100%;
      margin: 0 0.625rem;
    }

    .ngp-spinner {
      opacity: 0;
    }

    label.at-check {
      margin-right: 5px;
    }

    label.at-text,
    label.at-select,
    .at label {
      font-weight: 700;
      font-family: ${({ theme }) => theme.font};

      .oa-input-optional {
        font-weight: 500;
      }
    }


    a,
    div {
      font-family: ${({ theme }) => theme.font};
    }

    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="color"],
    input[type="file"],
    input[type="checkbox"],
    input[type="radio"],
    textarea,
    select,
    .select2-container--default .select2-selection--multiple {
      border-radius: 0px;
      border-width: 2px;
      margin-top: 5px;
      font-family: ${({ theme }) => theme.font};
    }

    input[type="tel"] {
      margin-top: 5px !important;
    }

    input[type="checkbox"]+span:before {
      border-radius: 0px;
      border-width: 2px;
    }

    .at-text.error {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"] {
        border-width: 4px;
      }
    }

    header.at-title {
      line-height: 1.37;
      font-weight: 700;
      padding: 0px;
      text-align: left;
    }

    footer, .at-banner {
      display: none;
    }

    .UpdateMyProfile label>span>span:after {
      display: none;
    }

    .at-row.UpdateMyProfile {
      margin-bottom: 15px;
      min-height: 27px;
    }

    input[type="submit"].at-submit {
      letter-spacing: 0.16em;
    }

    .at-checkbox-title {
      display: block;
      line-height: 1.2;
    }

    .oa-input-container > div {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;

      .select2-container {
        max-width: 100%;
      }
      .oa-input-unit {
        width: calc(50% - 5px);
      }
    }

    .oa-requires-js label {
      max-width: 100%;
      width: 100%;
    }

    &.oa-page-embed--Medium {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select {
        height: 35px;
        font-size: 14px;
      }

      .select2-container--default .select2-selection--multiple {
        min-height: 35px;
        font-size: 14px;
      }

      border-radius: 0px;
      border-width: 2px;
      margin-top: 5px;

      .oa-input-label {
        font-size: 14px;
      }

      .content.thankYou {
        p {
          font-size: 20px;
        }
      }

      select {
        padding: 0px 20px 0px 8px;
        line-height: 2;
      }

      header {
        font-size: 22px;
        margin-bottom: 15px;
      }

      .at-checkbox-title {
        margin-left: 5px;
        display: block;
      }

      .at-legend {
        font-size: 16px;
      }
    }

    &.oa-page-embed--Large {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select {
        height: 50px;
        font-size: 22px;
      }

      p {
        font-size: 22px;
        text-align: left;
      }

      .oa-event-list-results-container {
        h3 {
          font-size: 50px;
          margin-bottom: 32px;
        }

        .oa-event-result-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 2px solid ${({ theme }) => theme.palette.lighterPrimary};
          padding-top: 32px;
          padding-bottom: 32px;

          &:last-of-type {
            border-bottom: 2px solid ${({ theme }) => theme.palette.lighterPrimary};
          }

          .oa-event-result-type {
            color: ${({ theme }) => theme.palette.primary};
            text-transform: uppercase;
            letter-spacing: 0.16em;
            font-size: 18px;
            order: 0;

            ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
              font-size: 20px;
            `)}
          }

          .oa-event-result-datetime,
          .oa-event-result-name-link,
          .oa-event-result-description,
          .oa-event-result-signup-panel {
            order: 1;
          }

          .oa-event-result-datetime,
          .oa-event-result-description {
            font-size: 16px;
            color: ${({ theme }) => theme.palette.primary};
            line-height: 1.2;

            ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
              font-size: 18px;
            `)}
          }

          .oa-event-result-signup-panel {
            margin-top: 10px;
            margin-bottom: 0px;
            padding-bottom: 0px;
            width: 100%;

            a {
              font-size: 18px;
              font-weight: 700;
              color: ${({ theme }) => theme.palette.primaryHighlight};
              position: relative;
              transition: all 0.3s;

              &:hover {
                color: ${({ theme }) => theme.palette.secondary};
                &:after {
                  background-image: url('/arrow.svg');
                  right: -30px;
                }
              }

              &:after {
                background-image: url('/arrow-primary-highlight.svg');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: cover;
                content: "";
                position: absolute;
                top: 6px;
                right: -26px;
                width: 17.5px;
                height: 18px;
                line-height: 1.6;
                font-size: inherit;
                transition: all 0.3s;
              }

              ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
                font-size: 20px;

                &:after {
                  top: 7px;
                }
              `)}
            }
          }

          .oa-event-result-name-link {
            font-size: 32px;
            line-height: 1;
            margin-bottom: 10px;
            color: ${({ theme }) => theme.palette.primaryHighlight};
            transition: color 0.3s;

            &:hover {
              color: ${({ theme }) => theme.palette.secondary};
            }

            ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
              font-size: 40px;
            `)}
          }
        }
      }

      .at label {
        font-size: 16px;
      }

      .select2-container--default .select2-selection--multiple {
        min-height: 50px;
        font-size: 22px;
      }

      .select2-search__field {
        margin-top: 5px;
        padding: 5px;
      }



      header.at-title {
        font-size: 32px;
        margin-bottom: 25px;
      }

      .content.thankYou {
        p {
          font-size: 24px;
        }
      }

      input[type="checkbox"]+span:before {
        height: 25px;
        width: 25px;
      }

      input[type="checkbox"]+span:after {
        font-size: 18px;
        left: 6px;
        top: 6px;
      }

      .updateMyProfileSection .text {
        font-size: 16px;
        margin-left: 7px;
        top: 2px;
        position: relative;
        display: block;
        line-height: 1;
      }

      .at-checkbox-title {
        margin-left: 10px;
        font-size: 16px;
      }

      .btn-at.btn-at-primary {
        height: 52px;
        margin-top: 32px;
        font-size: 18px;
        padding: 13px 25px 16px;
      }

      .error small.error {
        font-size: 14px;
        margin-bottom: 6px;
        font-weight: 700;
        padding-left: 0px;
      }

      .at-legend {
        font-size: 18px;
      }
    }

    input[type="checkbox"]+span {
      font-family: ${({ theme }) => theme.font};
      color: ${({ theme }) => theme.palette.primary};
    }

    input[type="checkbox"]:checked+span:before {
      background-color: transparent;
    }

    input[type="checkbox"]:hover+span:before {
      border-color: ${({ theme }) => theme.palette.primaryHighlight};
    }

    &.oa-page-embed--Light {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select,
      .select2-container--default .select2-selection--multiple {
        border-color: ${({ theme }) => theme.palette.primaryHighlight};
        background-color: transparent;
        color: ${({ theme }) => theme.palette.primary};

        &:focus {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      .at {
        .select2-selection__choice {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
          border-width: 0px;
          border-radius: 0px;
          display: flex;
          align-items: center;

          .select2-selection__choice__remove {
            font-size: 30px;
            font-weight: 400;
            line-height: 1;
            color: ${({ theme }) => theme.palette.primaryHighlight};
          }
        }
      }

      .at-text.error {
        input[type="text"],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        input[type="color"],
        input[type="file"],
        input[type="checkbox"],
        input[type="radio"] {
          border-color: ${({ theme }) => theme.palette.primaryHighlight};
        }
        small.error {
          color: ${({ theme }) => theme.palette.primaryHighlight};
        }
      }

      input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.lightPrimary};
      }

      .error input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.primaryHighlight};
      }

      .at-checkbox-title-container::before {
        border-color: ${({ theme }) => theme.palette.primaryHighlight};
        background-color: transparent;
        color: ${({ theme }) => theme.palette.primary};

        &:focus {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      header {
        color: ${({ theme }) => theme.palette.primary};
      }

      .at-text,
      .at-select,
      input,
      .at label,
      .at-checkbox-title {
        color: ${({ theme }) => theme.palette.primary};

        & small {
          color: ${({ theme }) => theme.palette.primary};
        }
      }

      .checkbox-list-label {
        color: ${({ theme }) => theme.palette.primary};
        small {
          color: ${({ theme }) => theme.palette.lightPurple};
        }
      }

      input:-internal-autofill-selected {
        background-color: ${({ theme }) => theme.palette.lightPrimary} !important;
      }

      input[type="checkbox"] {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.primaryHighlight};
          background-color: transparent;
        }

        &:hover+span:before,
        &:focus+span:before {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      input[type="checkbox"]:checked {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.primaryHighlight};
          background-color: transparent;
        }

        &:hover+span:before,
        &:focus+span:before  {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      input[type="checkbox"]:checked+span:after {
        color: ${({ theme }) => theme.palette.primaryHighlight};
      }

      input[type="checkbox"]:hover+span:before,
      input[type="checkbox"]:focus+span:before {
        border-color: ${({ theme }) => theme.palette.primaryHighlight};
      }

      p, legend, h3 {
        color: ${({ theme }) => theme.palette.primary};
      }

      .btn-at.btn-at-primary {
        background-color: ${({ theme }) => theme.palette.highlight};
        color: ${({ theme }) => theme.palette.primary};

        &:hover {
          background-color: ${({ theme }) => theme.palette.secondaryHighlight};
        }
      }
    }

    &.oa-page-embed--Dark {
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="email"],
      input[type="number"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="color"],
      input[type="file"],
      input[type="checkbox"],
      input[type="radio"],
      textarea,
      select,
      .select2-container--default .select2-selection--multiple {
        border-color: ${({ theme }) => theme.palette.lightPrimary};
        background-color: ${({ theme }) => theme.palette.primary};
        border-width: 2px;
        color: ${({ theme }) => theme.palette.white};

        &:focus {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
          color: ${({ theme }) => theme.palette.primary};
        }
      }

      .at-text.error {
        input[type="text"],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        input[type="color"],
        input[type="file"],
        input[type="checkbox"],
        input[type="radio"] {
          border-color: ${({ theme }) => theme.palette.highlight};
        }
        small.error {
          color: ${({ theme }) => theme.palette.highlight};
        }
      }

      input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.lightPrimary};
      }

      .error input::placeholder, textarea::placeholder {
        color: ${({ theme }) => theme.palette.highlight};
      }

      header.at-title {
        color: ${({ theme }) => theme.palette.white};
      }

      .at-checkbox-title-container::before {
        border-color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.lighterPrimary};
      }

      .at-text,
      .at-select,
      input,
      .at label,
      .at-checkbox-title {
        color: ${({ theme }) => theme.palette.white};

        & small {
          color: ${({ theme }) => theme.palette.white};
        }
      }

      input:-internal-autofill-selected {
        background-color: ${({ theme }) => theme.palette.lightPrimary} !important;
      }

      input[type="checkbox"] {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.lightPrimary};
          background-color: transparent;
        }

        &:hover+span:before,
        &:focus+span:before {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      input[type="checkbox"]:checked {
        &+span:before {
          border-color: ${({ theme }) => theme.palette.lightPrimary};
          background-color: transparent;
        }

        &:hover+span:before,
        &:focus+span:before  {
          background-color: ${({ theme }) => theme.palette.lighterPrimary};
        }
      }

      input[type="checkbox"]:checked+span:after {
        color: ${({ theme }) => theme.palette.lightPrimary};
      }

      input[type="checkbox"]:hover+span:before,
      input[type="checkbox"]:focus+span:before {
        border-color: ${({ theme }) => theme.palette.lightPrimart};
      }

      .updateMyProfileSection .text {
        color: ${({ theme }) => theme.palette.white};
        display: block;
      }

      p, legend {
        color: ${({ theme }) => theme.palette.white};
      }

      .btn-at.btn-at-primary {
        background-color: ${({ theme }) => theme.palette.primaryHighlight};
        color: ${({ theme }) => theme.palette.primary};

        &:hover {
          border-color: ${({ theme }) => theme.palette.secondaryHighlight};
          background-color: ${({ theme }) => theme.palette.secondaryHighlight};
        }
      }
    }

    .at-inner {
      background-color: transparent;
    }

    .btn-at.btn-at-primary {
      border-radius: ${({ theme }) => theme.button.radius};
      cursor: pointer;
      transition: all 0.3s;
      text-transform: uppercase;
      font-weight: 700;
      height: 36px;
      border-width: 0px;
      width: fit-content;
      padding: 13px 25px 16px;
      font-size: 1.2rem;
      margin-left: 0px;
      letter-spacing: 0.16em;
      height: 50px;
    }
  }
`;

export default FormWrapper;
