import React, { useEffect } from 'react';
import { Link, LinkButton, Form } from '@components';
import { NewWindow } from '@styles/icons';
import {
  Disclaimers, SocialItem, SocialList, PrimaryItem, PrimaryLinks, ContactDetails,
  SecondaryItem, SecondaryLinks, FooterColumn, FooterWrapper, FooterRow, FooterContentWrapper,
} from './footer.styles';

const Footer = ({
  footerDisclaimer, footerSocialLinks, footerPrimaryLinks, footerSecondaryLinks,
  footerCopyright, footerForm, footerAddress, footerEmails, footerPhoneNumber,
  ngpFormName, footerDonateByMail,
}) => {
  useEffect(() => {
    // eslint-disable-next-line no-multi-assign
    const nvtagCallbacks = window.nvtag_callbacks = window.nvtag_callbacks || {};
    nvtagCallbacks.alterFormDefinition = nvtagCallbacks.alterFormDefinition || [];

    function rearrangeFooterFields(args) {
      const zipFieldset = {
        name: 'ZipCode',
        title: 'ZipCode',
        type: 'fieldset',
        children: [],
      };
      const emailFieldset = {
        name: 'Email',
        title: 'Email',
        type: 'fieldset',
        children: [],
      };
      const profileFieldset = {
        name: 'UpdateMyProfile',
        title: 'UpdateMyProfile',
        type: 'fieldset',
        children: [],
      };

      if (args.form_definition.name === (ngpFormName || 'Web::Signup::Footer')) {
        args.form_definition.form_elements.forEach((child, index) => {
          if (child.name === 'ContactInformation') {
            child.children.forEach((formel, key) => {
              if (formel.name === 'EmailAddress') {
                emailFieldset.children.push(formel);
                // eslint-disable-next-line no-param-reassign
                args.form_definition.form_elements[index].children[key] = { type: 'hidden' };
              } else if (formel.name === 'UpdateMyProfile') {
                profileFieldset.children.push(formel);
                // eslint-disable-next-line no-param-reassign
                args.form_definition.form_elements[index].children[key] = { type: 'hidden' };
              } else {
                zipFieldset.children.push(formel);
                // eslint-disable-next-line no-param-reassign
                args.form_definition.form_elements[index].children[key] = { type: 'hidden' };
              }
            });
          }
        });

        args.form_definition.form_elements.push(profileFieldset);
        args.form_definition.form_elements.push(zipFieldset);
        args.form_definition.form_elements.push(emailFieldset);
      }

      return args;
    }
    if (nvtagCallbacks.alterFormDefinition) {
      nvtagCallbacks.alterFormDefinition.push(rearrangeFooterFields);
    }
  }, [ngpFormName]);

  const emailre = /[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+/img;
  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <FooterRow type="content">
          <FooterRow type="nav">
            <FooterColumn>
              {footerPrimaryLinks && (
              <PrimaryLinks>
                {footerPrimaryLinks.map((link) => (
                  <PrimaryItem type={link.type} key={`${link.title}--${link.path}`}>
                    {(link.type === 'primary' || link.type === 'secondary') ? (
                      <LinkButton
                        href={link.path}
                        buttonTheme="primary"
                        textTheme="white"
                        buttonHoverTheme="primaryHighlight"
                        textHoverTheme="white"
                        size="medium"
                        target="_blank"
                      >
                        {link.title}
                      </LinkButton>
                    ) : (
                      <Link href={link.path}>{link.title}</Link>
                    )}
                  </PrimaryItem>

                ))}
              </PrimaryLinks>
              )}
            </FooterColumn>
            <FooterColumn>
              {footerSecondaryLinks && (
                <SecondaryLinks>
                  {footerSecondaryLinks.map((link) => (
                    <SecondaryItem key={`${link.title}--${link.path}`}>
                      <Link href={link.path}>{link.title}</Link>
                    </SecondaryItem>
                  ))}
                </SecondaryLinks>
              )}
              {footerSocialLinks && (
              <SocialList>
                {footerSocialLinks.map((social) => (
                  <SocialItem key={`${social.icon}--${social.path}`}>
                    <Link href={social.path}>{social.icon}</Link>
                  </SocialItem>
                ))}
              </SocialList>
              )}
            </FooterColumn>
          </FooterRow>
          <FooterColumn type="form">
            {footerForm && (
              <Form {...footerForm} addClass="footer-form" size="Large" />
            )}
          </FooterColumn>
        </FooterRow>
        <FooterColumn>
          <ContactDetails>
            {footerDonateByMail && footerDonateByMail.title && footerDonateByMail.path && (
              <Link href={footerDonateByMail.path}>
                {footerDonateByMail.title}
                {footerDonateByMail.type === 'attachment' && (
                  <NewWindow fill="primary" width="14px" height="14px" title="downloads file" />
                )}
              </Link>
            )}
            {footerAddress && (
            <span>{footerAddress}</span>
            )}
            {footerEmails && footerEmails.map((email) => {
              const emailLabel = email.split(emailre);
              const emailInfo = email.match(emailre);
              if (emailLabel && emailLabel[0] && emailInfo && emailInfo[0]) {
                return (
                  <span key={emailLabel[0]}>
                    {emailLabel[0]}
                    <Link href={`mailto:${emailInfo[0]}`}>{emailInfo[0]}</Link>
                  </span>
                );
              }
              return null;
            })}
            {footerPhoneNumber && (
            <Link href={`tel:${footerPhoneNumber}`}>{footerPhoneNumber}</Link>
            )}
          </ContactDetails>
          {footerCopyright && (
          <Disclaimers>{`${footerCopyright} ${footerDisclaimer}`}</Disclaimers>
          )}
        </FooterColumn>
      </FooterContentWrapper>
    </FooterWrapper>
  );
};

export default Footer;
