import React from 'react';
import { Arrow } from '@styles/icons';
import { Card, Text, LastWord } from './card.styles';

const LinkCard = (props) => {
  const {
    text, href, size = 'medium',
  } = props;
  const partText = text && text.substring(0, text.lastIndexOf(' '));
  const lastWord = text && text.substring(text.lastIndexOf(' '), text.length);
  return (
    <Card size={size} href={href}>
      <span>
        {partText && lastWord && (
          <Text>
            {partText}
            {' '}
          </Text>
        )}
        {(lastWord || text) && <LastWord>{lastWord || text}</LastWord>}
        <Arrow strokeColor="secondaryHighlight" height="26px" width="29px" />
      </span>
    </Card>
  );
};

export default LinkCard;
