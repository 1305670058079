export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';
export const KEY_ACTION = 'Key Action';
export const SECONDARY_ACTIONS = 'Secondary Actions';
export const ISSUES = 'Issues';
export const ENDORSEMENTS = 'Endorsements';
export const TOOLKIT = 'Toolkit';
export const BIO = 'Bio';
export const VIDEO = 'Video';
export const LIGHT = 'white';
export const DARK = 'primary';
export const SECONDARY = 'secondary';
export const EVENT_LIST = 'event list';
export const FORM = 'form';
export const DISMISS_DONATE_BAR = 'dismiss_donate_bar';
export const DISMISS_ALERT_BAR = 'dimiss_alert_bar';
export const DISMISS_SPLASH = 'dimiss_splash_bar';
export const EVENT_TYPES = {
  AUTOMATED_PHONE_BANK: 'Automated phone banks',
  BARNSTORM: 'Barnstorm',
  CANVASS: 'Canvass',
  CARPOOL: 'Carpool',
  COMMUNITY: 'Community Events',
  COMMUNITY_CANVASS: 'Community Canvass',
  DEBATE_WATCH_PARTY: 'Debate Watch Party',
  FRIEND_TO_FRIEND_OUTREACH: 'Friend-to-friend outreaches',
  FUNDRAISER: 'Fundraisers',
  HOUSE_PARTY: 'House Party',
  LETTER_WRITING: 'Letter Writing',
  LITERATURE_DROP_OFF: 'Literature drop-offs',
  MEET_GREET: 'Meet-and-greets',
  MEETING: 'Meeting',
  OFFICE_OPENING: 'Office Opening',
  OTHER: 'Other',
  PETITION: 'Petition',
  PHONE_BANK: 'Phone Bank',
  RALLY: 'Rally',
  SIGNATURE_GATHERING: 'Signature Gathering',
  SOLIDARY_EVENT: 'Solidarity Event',
  TEXT_BANK: 'Text Bank',
  TOWN_HALL: 'Town Hall',
  TRAINING: 'Trainings',
  VISIBILITY_EVENT: 'Visibility events',
  VOTER_REG: 'Voter Registrations',
  WORKSHOP: 'Workshops',
};
export const ZIP_REGEXP = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);
