import React from 'react';
import Img from 'gatsby-image';
import { LinkButton, Link } from '@components';
import { Header1 } from '@styles';
import { compileRichText } from '@utils';
import {
  Wrapper, Content, TextContent, DateContent, ParentPage, Downloads, Documents,
} from './general-hero.styles';
import {
  Download,
} from '../plan-hero/plan-hero.styles';

const GeneralHero = (props) => {
  const {
    title, heroImage, intro, releaseDate, dateLabel, documentFileDownload: document,
    documentDownloadText, parentPage, parentPageTitle, documentFileDownloadAnchor: anchor,
    backgroundColor, documentDownloadsText, documentDownloads,
  } = props;

  const hasImg = heroImage && heroImage.fluid;

  return (
    <Wrapper hasImg={hasImg} backgroundColor={backgroundColor}>
      <Content hasImg={hasImg}>
        {heroImage && heroImage.fluid && (
          <Img
            alt={heroImage.description}
            fluid={heroImage.fluid}
            className="hero-image-desktop"
          />
        )}
        <TextContent>
          <Header1 textTheme="primary" size="medium">
            {parentPage && parentPageTitle && parentPage[0] && (
            <ParentPage>
              <Link href={parentPage[0].pageUrl}>{parentPageTitle}</Link>
            </ParentPage>
            )}
            {title}
          </Header1>
          {dateLabel && releaseDate && (
            <DateContent>
              <span>
                {dateLabel}
                {': '}
              </span>
              <span>{releaseDate}</span>
            </DateContent>
          )}
          {hasImg && intro && (
            compileRichText({ textTheme: 'primary', size: 'large' })(intro)
          )}
          {document && documentDownloadText && document.file && document.file.url && (
            <Download>
              <LinkButton
                href={`${document.file.url}${anchor || ''}`}
                size="xlarge"
                textTheme="white"
                buttonTheme="primaryHighlight"
                buttonHoverTheme="secondaryHighlight"
                textHoverTheme="primary"
              >
                {documentDownloadText}
              </LinkButton>
            </Download>
          )}
          {documentDownloads && documentDownloadsText && (
          <Downloads>
            <ParentPage>{documentDownloadsText}</ParentPage>
            <Documents>
              {documentDownloads.map((download) => (
                <LinkButton
                  href={`${download.file.url}${anchor || ''}`}
                  size="medium"
                  textTheme="white"
                  buttonTheme="primaryHighlight"
                  buttonHoverTheme="secondaryHighlight"
                  textHoverTheme="primary"
                >
                  {download.title}
                </LinkButton>
              ))}
            </Documents>
          </Downloads>
          )}
        </TextContent>
        {heroImage && heroImage.fluid && (
          <Img
            alt={heroImage.description}
            fluid={heroImage.fluid}
            className="hero-image-mobile"
          />
        )}
      </Content>
    </Wrapper>
  );
};

export default GeneralHero;
