import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import YouTube from 'react-youtube';
import Img from 'gatsby-image';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
  Form, EventList, Link, SheetMap,
} from '@components';
import {
  Paragraph, Bold, Italic, GeneralHeader1, GeneralHeader2, GeneralHeader3,
  GeneralHeader4, GeneralHeader5, GeneralHeader6, BlockQuote, Cite,
  OrderedList, UnorderedList, ListItem, Underline, InlineLinkWrapper, RightColumn,
  LeftColumn, ColumnWrapper, YouTubeWrapper, TwitterWrapper, ChildCareMap,
} from '@styles';
import { DoubleQuotes, Line } from '@styles/icons';
import { getKeyValue, slugify } from '@utils';
import { FORM, EVENT_LIST } from '@utils/vars';

export const renderOptions = ({
  textTheme = 'primary', size, locale = 'enUS',
}) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { target } = getKeyValue(node, 'data') || {};
      const { type: embedType } = getKeyValue(target, 'internal') || {};
      if (embedType === 'ContentfulForm') {
        const {
          formTheme,
          formSize,
          databag,
          fastactionNoLogin,
          formUrl,
          mobileAutofocus,
          formType,
          hideFormTitle,
          hideAdditionalInformationTitle,
          formLevelLabelLanguageOverrides,
        } = target || {};

        const embedFormType = formType || '';

        if (!embedFormType || embedFormType === FORM) {
          return (
            <Form
              formTheme={formTheme || 'Light'}
              databag={databag || 'everyone'}
              fastactionNoLogin={fastactionNoLogin || 'true'}
              formUrl={formUrl || ''}
              mobileAutofocus={mobileAutofocus || 'false'}
              size={formSize || 'Large'}
              hideFormTitle={hideFormTitle || false}
              hideAdditionalInformationTitle={hideAdditionalInformationTitle || false}
              formLevelLabelLanguageOverrides={formLevelLabelLanguageOverrides || false}
              locale={locale}
            />
          );
        }
        if (embedFormType === EVENT_LIST) {
          return (
            <EventList
              formTheme={formTheme || 'Light'}
              databag={databag || 'everyone'}
              fastactionNoLogin={fastactionNoLogin || 'true'}
              formUrl={formUrl || ''}
              mobileAutofocus={mobileAutofocus || 'false'}
              size={formSize || 'Large'}
            />
          );
        }

        return null;
      }
      if (embedType === 'ContentfulSocialEmbed') {
        const { contentfulid: id, type } = target || {};
        const socialEmbedType = type || '';
        if (socialEmbedType === 'youtube') {
          const opts = {
            width: '100%',
            playerVars: {
              autoplay: 0,
              controls: 0,
              cc_load_policy: 0,
              fs: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              rel: 0,
              showinfo: 0,
            },
          };
          return (
            <YouTubeWrapper>
              <YouTube className="video--youtube" videoId={id || ''} opts={opts} />
            </YouTubeWrapper>
          );
        } if (socialEmbedType === 'twitter') {
          return (
            <TwitterWrapper>
              <TwitterTweetEmbed
                tweetId={id || ''}
              />
            </TwitterWrapper>
          );
        } if (socialEmbedType === 'childcaremap') {
          return (
            <ChildCareMap src={id || ''} title="child care map" />
          );
        }
      }
      if (embedType === 'ContentfulTwoColumns') {
        const {
          leftColumn, rightColumn, leftMedia, rightMedia,
        } = target || {};
        if ((rightColumn || rightMedia) && (leftColumn || leftMedia)) {
          return (
            <ColumnWrapper>
              <LeftColumn>
                {leftColumn && compileRichText({ textTheme, size })(leftColumn)}
                {leftMedia && leftMedia.fluid && (
                  <Img fluid={leftMedia.fluid} alt={leftMedia.description} />
                )}
              </LeftColumn>
              <RightColumn>
                {rightColumn && compileRichText({ textTheme, size })(rightColumn)}
                {rightMedia && rightMedia.fluid && (
                  <Img fluid={rightMedia.fluid} alt={rightMedia.description} />
                )}
              </RightColumn>
            </ColumnWrapper>
          );
        }
      }
      if (embedType === 'ContentfulMapEmbed') {
        const {
          googleSheet, popupTitleField, id,
        } = target || {};
        if (googleSheet && popupTitleField && id) {
          return (
            <SheetMap {...target} textTheme={textTheme} size={size} />
          );
        }
      }
      return null;
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <GeneralHeader1 textTheme={textTheme} size={size}>{children}</GeneralHeader1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <GeneralHeader2 textTheme={textTheme} size={size}>{children}</GeneralHeader2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <GeneralHeader3 textTheme={textTheme} size={size}>{children}</GeneralHeader3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <GeneralHeader4 textTheme={textTheme} size={size}>{children}</GeneralHeader4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <GeneralHeader5 textTheme={textTheme} size={size}>{children}</GeneralHeader5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <GeneralHeader6 textTheme={textTheme} size={size}>{children}</GeneralHeader6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Paragraph textTheme={textTheme} size={size}>
        {children}
      </Paragraph>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <OrderedList textTheme={textTheme} size={size}>
        {children}
      </OrderedList>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList textTheme={textTheme} size={size}>
        {children}
      </UnorderedList>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem textTheme={textTheme} size={size}>
        {children}
      </ListItem>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { fluid, description } = getKeyValue(node, 'data.target') || {};
      const altText = description || '';
      return (
        <Img fluid={fluid} alt={altText} />
      );
    },
    [BLOCKS.QUOTE]: (node, children) => (
      <BlockQuote textTheme={textTheme} size={size}>
        <DoubleQuotes strokeColor="highlight" size={size} />
        {children && children.map((child) => {
          if (child.props) {
            const quoteChild = child.props.children
              && child.props.children[0]
              && child.props.children[0][1];
            const hasCitation = quoteChild && typeof quoteChild === 'string' && quoteChild.startsWith('- ');
            if (hasCitation) {
              return (
                <Cite key={child.key} size={size}>
                  {quoteChild.replace('- ', '')}
                  {child.props.children[1] && child.props.children.slice(1)}
                  <Line strokeColor="highlight" />
                </Cite>
              );
            }
            return child;
          }
          return null;
        })}
      </BlockQuote>
    ),
    [BLOCKS.HR]: () => <hr />,
    [INLINES.HYPERLINK]: (node, children) => {
      const { data: { uri: href } } = node;

      const anchorProps = {
        href,
      };

      if (href.startsWith('http')) {
        anchorProps.target = '_blank';
      }

      if (href.startsWith('#')) {
        return (
          <InlineLinkWrapper textTheme={textTheme} size={size}>
            <AnchorLink {...anchorProps} to={href}>
              {children}
            </AnchorLink>
          </InlineLinkWrapper>
        );
      }

      return (
        <InlineLinkWrapper textTheme={textTheme} size={size}>
          <Link {...anchorProps}>
            {children}
          </Link>
        </InlineLinkWrapper>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const { target } = getKeyValue(node, 'data') || {};
      const { type: entryType } = getKeyValue(target, 'internal') || {};
      if (entryType === 'ContentfulPage') {
        const { pageUrl } = target || {};
        const href = pageUrl;
        const anchorProps = {
          href,
        };
        if (href && href.startsWith('http')) {
          anchorProps.target = '_blank';
        }
        return (
          <InlineLinkWrapper textTheme={textTheme} size={size}>
            <Link {...anchorProps}>
              {children}
            </Link>
          </InlineLinkWrapper>
        );
      }
      if (entryType === 'ContentfulActionCenterSection') {
        const { sectionTitle } = target || {};
        const titleId = sectionTitle && slugify(sectionTitle);
        const href = `#${titleId}`;
        return (
          <InlineLinkWrapper textTheme={textTheme} size={size}>
            <AnchorLink to={href}>
              {children}
            </AnchorLink>
          </InlineLinkWrapper>
        );
      }

      return null;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { file } = getKeyValue(node, 'data.target') || {};
      const { url: href } = file || {};
      const anchorProps = {
        href,
      };

      if (href && href.startsWith('http')) {
        anchorProps.target = '_blank';
      }

      return (
        <InlineLinkWrapper textTheme={textTheme} size={size}>
          <Link {...anchorProps}>
            {children}
          </Link>
        </InlineLinkWrapper>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Bold>
        {text}
      </Bold>
    ),
    [MARKS.ITALIC]: (text) => (
      <Italic>
        {text}
      </Italic>
    ),
    [MARKS.UNDERLINE]: (text) => (
      <Underline>
        {text}
      </Underline>
    ),
  },
  renderText: (text) => (
    text.split('\n').reduce((children, textSegment, index) => [...children, index > 0 && <br key={`${textSegment}-${index}`} />, textSegment], [])
  ),
});

export default function compileRichText({
  textTheme, size = 'medium', locale = 'enUS',
}) {
  const options = renderOptions({
    textTheme, size, locale,
  });

  function compile(content) {
    return renderRichText(content, options);
  }

  return compile;
}
