import styled, { css, keyframes } from 'styled-components';
import { createBreakpoint } from '@styles';

const slideIn = keyframes`
  0% {
    transform: translate3d(0,-100px,0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0,-100px,0);
    opacity: 0;
  }
`;

export const Bar = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.secondaryHighlight};
  padding: 8px 32px 8px 16px;
  display: flex;
  flex-direction: row;
  animation: ${({ closing }) => (closing ? slideOut : slideIn)};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  position: relative;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 8px ${theme.layout.gutter}px;
  `)}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.palette.primary};
    transition: transform 0.7s ease-in-out;
    z-index: 0;
    transform: translate3d(-100%, 0, 0);
  }

  &:hover {
    p {
      transition: font-weight 0.3s ease-in-out;
      font-weight: 700;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${({ theme }) => theme.layout.wideMaxWidth}px;
  margin: auto;
  align-items: center;
  justify-content: flex-start;

  p {
    margin: 0px 5px 0px 0px;
    line-height: 1.3;
    font-size: 16px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    p {
      margin: 0px 10px 0px 0px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    p {
      margin: 0px 15px 0px 0px;
    }
  `)}


  a {
    display: block;
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
`;

export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 0px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    top: 8px;
    right: 17px;
  `)}
`;
