import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto 0px;
  max-width: 800px;

  h1, p {
    margin-bottom: 32px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    h1, p {
      margin-bottom: 40px;
      text-align: center;
    }
  `)}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  max-width: 740px;
  p > span > a {
    font-weight: 400;
    background-size: 100% 0.09em;
    color: ${({ theme }) => theme.palette.primary} !important;
    background-image: linear-gradient(${({ theme }) => theme.palette.primary},${({ theme }) => theme.palette.primary});

    &:hover {
      font-weight: 700;
    }
  }
`;

export const ErrorImage = styled.img`
  width: 100%;
  max-width: 540px;
  height: auto;
  max-height: 320px;
  margin-top: 20px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 32px;
  `)}
`;

export const ActionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
  justify-content: space-between;
  margin-top: 10px;

  span {
    margin-top: 10px;
    width: 100%;
    a {
      width: 100%;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
    margin-top: 20px;
    flex-direction: row;

    span {
      margin-top: 0px;
      width: calc(50% - 10px);
      a {
        width: 100%;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 32px;
  `)}
`;
