import { css } from 'styled-components';
import FellixBoldEot from './fonts/Fellix-Bold.eot';
import FellixBoldWoff from './fonts/Fellix-Bold.woff';
import FellixBoldWoff2 from './fonts/Fellix-Bold.woff2';
import FellixRegularEot from './fonts/Fellix-Regular.eot';
import FellixRegularWoff from './fonts/Fellix-Regular.woff';
import FellixRegularWoff2 from './fonts/Fellix-Regular.woff2';

const weights = {
  700: [FellixBoldEot, FellixBoldWoff, FellixBoldWoff2],
  400: [FellixRegularEot, FellixRegularWoff, FellixRegularWoff2],
};

const createFontFaces = () => {
  let styles = '';

  for (const [weight, formats] of Object.entries(weights)) {
    const [eot, woff, woff2] = formats;
    styles += `
      @font-face {
        font-family: 'Fellix';
        src: url(${eot});
        src: url(${woff2}) format('woff2'),
            url(${woff}) format('woff');
        font-weight: ${weight};
        font-style: normal;
        font-display: swap;
      }
    `;
  }

  return styles;
};

const fontFaces = createFontFaces();
const Font = css`
  ${fontFaces}
`;

export default Font;
