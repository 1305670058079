import { darken, lighten } from 'polished';

const breakpoints = {
  mobileSmall: '320px',
  mobileMedium: '375px',
  mobileLarge: '410px',
  ngpFormRow: '600px',
  tablet: '768px',
  desktopSmall: '1024px',
  desktopMedium: '1440px',
  desktopLarge: '2560px',
};

const colors = {
  purple: '#3E2C70',
  medPurple: '#D6C9F9',
  brightPurple: '#6B49DC',
  lightPurple: '#A78BF2',
  lighterPurple: '#EBE4FC',
  black: '#000000',
  coral: '#FF9B7B',
  lightCoral: '#FFD0C1',
  white: '#FFFFFF',
  emerald: '#139675',
  lightEmerald: '#1aca9e',
  darkEmerald: '#0c624c',
  mint: '#2EFFF0',
  lightGrey: '#cacaca',
  medGrey: '#717171',
  liberty: '#B7E4CF',
};

const palette = {
  primary: colors.purple,
  medPrimary: colors.medPurple,
  lightPrimary: colors.lightPurple,
  lighterPrimary: colors.lighterPurple,
  highlight: colors.coral,
  lightHighlight: colors.lightCoral,
  secondary: colors.emerald,
  secondaryLight: colors.lightEmerald,
  secondaryDark: colors.darkEmerald,
  primaryHighlight: colors.brightPurple,
  secondaryHighlight: colors.mint,
  secondaryLightHighlight: lighten(0.3, colors.mint),
  secondaryDarkHighlight: darken(0.22, colors.mint),
  focus: colors.liberty,
  lightGrey: colors.lightGrey,
  medGrey: colors.medGrey,
  white: colors.white,
};

const font = 'Fellix, Poppins, Noto Sans TC, sans-serif';

const zIndices = [
  'stickyNav',
  'navSubItems',
  'donateBar',
  'modal',
  'takeover',
  'tabOnlyA11y',
  'mobileNav',
].reduce((acc, name, index) => ({ ...acc, [name]: index + 10 }), {});

const layout = {
  gutter: '32',
  contentMaxWidth: '974',
  globalMaxWidth: '1076',
  wideMaxWidth: '1200',
  extraWideMaxWidth: '1320',
};

const button = {
  radius: '72.6px',
};

const theme = {
  colors,
  palette,
  breakpoints,
  font,
  zIndices,
  layout,
  button,
};

export default theme;
