import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import { createBreakpoint } from '@styles';

const loadModal = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const closeModal = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
`;

export const SplashWrapper = styled.div`
  transition: background 0.3s ease-in-out;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: ${({ theme }) => theme.palette.primary};
  z-index: ${({ theme }) => theme.zIndices.modal};
  display: flex;
  flex-direction: column;
  animation-name: ${({ closing }) => (closing ? closeModal : loadModal)};
  animation-duration: ${({ closing }) => (closing ? '0.2s' : '1.2s')};
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.465, 0.183, 0.153, 0.946);
  max-width: ${({ theme }) => theme.layout.extraWideMaxWidth}px;

  .wu--modal-image {
    min-height: 40vh;
    max-height: 80vh;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    .wu--modal-image {
      min-height: 60vw;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;
    justify-content: flex-start;
    padding: 60px;
    background: ${rgba(theme.palette.primaryHighlight, 0.6)};
    overflow: hidden;

    .wu--modal-image {
      position: sticky !important;
      top: 0;
      left: 0;
      bottom: 0;
      width: calc(50vw - 60px);
      min-width: calc(50vw - 60px);
      height: calc(100vh - 120px);
      min-height: calc(100vh - 120px);
      max-height: calc(100vh - 120px);
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    .wu--modal-image {
      width: calc(40vw - 60px);
      min-width: calc(40vw - 60px);
    }
  `)}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 100%;
  padding: ${({ theme }) => `${theme.layout.gutter}px ${theme.layout.gutter}px 120px ${theme.layout.gutter}px`};
  background: ${({ theme }) => theme.palette.primary};
  position: relative;

  .at.ngp-form.ngp-form--Large {
    .at-form-submit {
      margin-top: 16px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: calc(50vw - 60px);
    padding: 60px;
    min-height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    position: unset;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    width: calc(60vw - 60px);
    padding: 80px;
  `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 22px;
  flex-shrink: 0;

  p {
    line-height: 1.37;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 100%;
    margin-bottom: 44px;
  `)}
`;

export const DonateInfo = styled.div`
  display: block;
  width: 100%;
  flex-shrink: 0;
`;

export const ButtonsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;

  ${({ type }) => type === 'donation' && css`
    > li {
      width: calc(50% - 6px);
      margin-bottom: 12px;
    }

    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};

    ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
      > li {
        width: calc(33% - 7px);
        margin-bottom: 18px;
      }

      display: ${({ mobile }) => (!mobile ? 'flex' : 'none')};
    `)}

    ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
      > li {
        width: calc(50% - 11px);
        margin-bottom: 22px;
      }
    `)}
  `}

  ${({ type }) => type === 'cta' && css`
    > li {
      width: 100%;
      margin-bottom: 12px;
    }

    ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
      > li {
        min-width: 60%;
        margin-bottom: 18px;
      }
    `)}

    ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
      > li {
        margin-bottom: 20px;
      }
    `)}
  `}
`;

export const Header = styled.h2`
  color: ${({ theme }) => theme.palette.white};
  font-size: 32px;
  margin: 12px 0px;
  line-height: 1.08;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 50px;
    margin: 0px 0px 22px;
  `)}
`;

export const Disclaimer = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white};
  margin-bottom: 0px;
  margin-top: 10px;
  line-height: 1.43;
  flex-shrink: 0;
`;

export const CloseButton = styled.button`
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 0px;

  span {
    letter-spacing: 0.08em;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.white};
    margin-left: 6.5px;
  }

  svg {
    position: relative;
    top: -1px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    top: 70px;
    right: 85px;
    width: fit-content;
    display: ${({ mobile }) => (!mobile ? 'flex' : 'none')};
  `)}
`;
